import { useTranslate } from "react-admin";
import { ReactNode } from "react";
import { Box, Stack, Typography } from "@helo/ui";
import { DimensionUnits, Dims } from "@swyft/types";

const Dimensions = ({ data, divider = "x" }: DimensionsProps) => {
  const translate = useTranslate();
  const unit = DimensionUnits.INCH;
  const hasWidth = data?.width;
  const hasHeight = data?.height;
  const hasLength = data?.length;

  if (!data) {
    return (
      <Typography variant="body2">
        {translate("shared.content.not_available")}
      </Typography>
    );
  }

  return (
    <Typography variant="body1" component="div">
      <Stack
        direction="row"
        spacing={0.5}
        divider={
          typeof divider === "string" ? (
            <Typography
              variant="caption"
              component="div"
              sx={({ palette }) => ({
                color: palette.neutral.dark,
                lineHeight: "inherit",
              })}
            >
              {divider}
            </Typography>
          ) : (
            divider
          )
        }
        sx={{
          display: "inline-flex",
          alignItems: "baseline",
        }}
      >
        {hasWidth && (
          <Typography variant="inherit">
            <Typography variant="inherit" component="span">
              {data.width}
            </Typography>
          </Typography>
        )}
        {hasHeight && (
          <Typography variant="inherit">
            <Typography variant="inherit" component="span">
              {data.height}
            </Typography>
          </Typography>
        )}
        {hasLength && (
          <Typography variant="inherit">
            <Typography variant="inherit" component="span">
              {data.length}
            </Typography>
          </Typography>
        )}
      </Stack>
      <Typography
        variant="caption"
        component="span"
        sx={({ palette }) => ({
          fontStyle: "italic",
          color: palette.neutralScale[500],
          ml: 0.75,
        })}
      >
        {translate(`shared.label.unit.${unit}.long`, {
          smart_count: data.width || data.width || data.height,
        })}
      </Typography>
    </Typography>
  );
};

interface DimensionsProps {
  data?: Dims;
  divider?: string | ReactNode;
}

export default Dimensions;
