"use strict";
exports.__esModule = true;
exports.DspApiType = exports.DspRateServiceType = exports.CarrierRateCardType = void 0;
var CarrierRateCardType;
(function (CarrierRateCardType) {
    CarrierRateCardType["FLAT"] = "FLAT";
    CarrierRateCardType["WEIGHT"] = "WEIGHT";
})(CarrierRateCardType = exports.CarrierRateCardType || (exports.CarrierRateCardType = {}));
var DspRateServiceType;
(function (DspRateServiceType) {
    DspRateServiceType["API"] = "API";
    DspRateServiceType["ZIP_ZONE"] = "ZIP_ZONE";
})(DspRateServiceType = exports.DspRateServiceType || (exports.DspRateServiceType = {}));
var DspApiType;
(function (DspApiType) {
    DspApiType["EASYPOST"] = "EASYPOST";
})(DspApiType = exports.DspApiType || (exports.DspApiType = {}));
