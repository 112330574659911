import { styled } from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import AppViewLayout from "~/layouts/app/AppViewLayout";

const DensityMap = () => {
  const { merchant } = useAuthenticatedContext();

  return (
    <AppViewLayout title="pages.density_map.title">
      {merchant?.id && (
        <DensityMapIframe
          src={`https://datastudio.google.com/embed/u/0/reporting/a730ea88-e72b-4ae1-838d-9fdc09a44188/page/p_jgq7ux91yc?params={"df14":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${merchant.id}"}`}
          frameBorder="0"
          allowFullScreen
          id={`density-map-${merchant.name}-embed`}
        />
      )}
    </AppViewLayout>
  );
};

export default DensityMap;

const DensityMapIframe = styled("iframe")(({ theme }) => ({
  height: "900px",
  width: "100%",
  margin: "0 auto",
  maxWidth: theme.breakpoints.values.xl,
  alignSelf: "center",
}));
