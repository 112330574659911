import { ChipField, useRecordContext, useTranslate } from "react-admin";
import { styled } from "@helo/ui";
import { User, UserStatus } from "@swyft/types";

const ChipContainer = styled(ChipField)({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  height: "25px",
  textTransform: "lowercase",
  fontSize: "13px",
});

export const StatusChip = () => {
  const record = useRecordContext<User>();
  const translate = useTranslate();

  if (!record) {
    return null;
  }

  return (
    <ChipContainer
      sx={({ palette }) => ({
        backgroundColor:
          UserStatus.ACTIVE === record.status
            ? palette.success.main
            : palette.neutral.light,
        color:
          UserStatus.ACTIVE === record.status
            ? palette.success.contrastText
            : palette.neutral.contrastText,
        px: 2,
      })}
      source="status"
      emptyText={translate("shared.content.not_available")}
    />
  );
};
