import { combineDataProviders } from "react-admin";

import firebaseConfig from "~/config/firebase";
import { AppResource } from "~/config/resources";
import getUsersDataProvider from "./user/userDataProvider";
import getApiDataProvider from "./api/apiDataProvider";
import getIntegrationsDataProvider from "./integrations/integrationsDataProvider";
import getWebhookDataProvider from "./webhook/webhookDataProvider";
import getMerchantsDataProvider from "./merchant/merchantDataProvider";
import getLocationsDataProvider from "./location/locationDataProvider";
import getZonesDataProvider from "./zone/zoneDataProvider";
import getMarketsDataProvider from "./markets/marketsDataProvider";
import getLabelsDataProvider from "./shipment/label/labelDataProvider";
import getLabelsActivityLogDataProvider from "./shipment/label/activityLogDataProvider";
import getInvoicesDataProvider from "./invoice/invoiceDataProvider";
import getRatesDataProvider from "./rates/rateDataProvider";

const userDataProvider = getUsersDataProvider(firebaseConfig);
const merchantDataProvider = getMerchantsDataProvider(firebaseConfig);
const locationDataProvider = getLocationsDataProvider(firebaseConfig);
const zoneDataProvider = getZonesDataProvider(firebaseConfig);
const marketsDataProvider = getMarketsDataProvider(firebaseConfig);
const apiDataProvider = getApiDataProvider(firebaseConfig);
const integrationsDataProvider = getIntegrationsDataProvider(firebaseConfig);
const webhookDataProvider = getWebhookDataProvider(firebaseConfig);
const shipmentDataProvider = {
  label: {
    general: getLabelsDataProvider(firebaseConfig),
    activityLog: getLabelsActivityLogDataProvider(firebaseConfig),
  },
};
const invoiceDataProvider = getInvoicesDataProvider(firebaseConfig);
const rateDataProvider = getRatesDataProvider(firebaseConfig);

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case AppResource.User:
      return userDataProvider;
    case AppResource.Zone:
      return zoneDataProvider;
    case AppResource.Merchant:
      return merchantDataProvider;
    case AppResource.Location:
      return locationDataProvider;
    case AppResource.Api:
      return apiDataProvider;
    case AppResource.Integrations:
      return integrationsDataProvider;
    case AppResource.Webhook:
      return webhookDataProvider;
    case AppResource.Shipment:
      return shipmentDataProvider.label.general;
    case AppResource.ShipmentActivityLog:
      return shipmentDataProvider.label.activityLog;
    case AppResource.Markets:
      return marketsDataProvider;
    case AppResource.Invoices:
      return invoiceDataProvider;
    case AppResource.Rate:
      return rateDataProvider;
    default:
      throw new Error(
        `No data provider available for unknown resource: ${resource}`,
      );
  }
});

export default dataProvider;
