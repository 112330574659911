//WIP: radio group for rate results/selection
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  FormControl,
  Box,
  styled,
  Grid,
  CircularProgress,
} from "@helo/ui";
import { Control, Controller, useFormContext } from "react-hook-form";
import { useLocaleState, useGetList, useTranslate } from "react-admin";
import {
  PickupLocation,
  Rate,
  GetRatesRequest,
  CountryISO2,
} from "@swyft/types";
import { useEffect } from "react";

import { currencyFormatter } from "~/common/helpers";
import { CreateSingleLabelFormValues } from "./CreateSingleLabelForm";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { AppResource } from "~/config/resources";
import GracefulFallback from "~/components/feedback/GracefulFallback";

const RatesRadioGroup = ({ originList, name, control }: FormElementTypes) => {
  const translate = useTranslate();
  const { getValues, resetField } = useFormContext();
  const { merchant } = useAuthenticatedContext();
  const partialFormValues = getValues();
  const origin: PickupLocation | undefined = originList.find(
    (loc) => loc.slug === partialFormValues.pickupLocation,
  );
  const ratesFilter: GetRatesRequest | null = !!origin
    ? {
        merchant: {
          id: merchant?.id ?? "",
        },
        origin: {
          line1:
            `${origin?.contact.validatedAddressV2?.buildingNumber} ${origin?.contact.validatedAddressV2?.street}` ??
            "",
          line2: origin?.contact.validatedAddressV2?.line2 ?? "",
          city: origin?.contact.validatedAddressV2?.city ?? "",
          province: origin?.contact.validatedAddressV2?.province ?? "",
          postalCode: origin?.contact.validatedAddressV2?.postalCode ?? "",
          country: origin?.contact.validatedAddressV2?.country as CountryISO2,
        },
        destination: {
          line1: partialFormValues.addressLine1,
          line2: partialFormValues.addressLine2,
          city: partialFormValues.city,
          province: partialFormValues.province,
          postalCode: partialFormValues.postalCode,
          country: partialFormValues.country,
        },
        dimensions: {
          heightInches: Number(partialFormValues.height),
          widthInches: Number(partialFormValues.width),
          lengthInches: Number(partialFormValues.length),
          weight: {
            unit: "pounds",
            value: Number(partialFormValues.weight),
          },
        },
      }
    : null;

  //Fetch Rates
  const { data, isLoading, error, remove } = useGetList(
    AppResource.Rate,
    { filter: ratesFilter },
    {
      enabled: !!ratesFilter,
    },
  );

  useEffect(() => {
    return () => {
      //When component will unmount check if rate field has value, if so, reset field
      const currentFormValues = getValues();
      if (!!currentFormValues.rate) {
        resetField("rate");
      }

      //When component will unmount remove query as to not return any cached rates on next mount
      remove();
    };
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  //TODO: map to more user friendly error messages
  if (error) {
    return <GracefulFallback type="error" details={error?.message} />;
  }

  return data ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <RadioGroup
            {...field}
            onChange={(event, value) => field.onChange(value)}
            value={field.value}
          >
            {data?.map((rate) => {
              return (
                <FormControlLabel
                  sx={({ palette }) => ({
                    m: "16px 0",
                    backgroundColor: palette.primaryScale[900],
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                  })}
                  key={`rate-${rate.id}`}
                  value={rate.id}
                  label={<RateOptionCard rate={rate} />}
                  control={<Radio />}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      )}
    />
  ) : (
    <EmptyRatesContainer>
      <Typography>
        {translate("shipments.content.create.form.rate.empty")}
      </Typography>
    </EmptyRatesContainer>
  );
};

const RateOptionCard = ({ rate }: { rate: Rate }) => {
  const [locale] = useLocaleState();
  const translate = useTranslate();
  return (
    <RatesContainer>
      <Typography variant="body1">{rate.carrier}</Typography>
      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "right" }}>
        {currencyFormatter(locale, rate.rate, rate.currency)}
      </Typography>
      <Typography
        variant="body2"
        sx={({ palette }) => ({
          color: palette.neutral.dark,
          lineHeight: "inherit",
        })}
      >
        {rate.service}
      </Typography>
      {rate.estimatedDeliveryDays && (
        <Typography
          variant="caption"
          sx={({ palette }) => ({
            color: palette.neutral.dark,
            lineHeight: "inherit",
            textAlign: "right",
          })}
        >
          {translate("shipments.content.create.form.rate.estimatedDelivery", {
            smart_count: rate.estimatedDeliveryDays,
          })}
        </Typography>
      )}
    </RatesContainer>
  );
};

interface FormElementTypes {
  name: any;
  originList: PickupLocation[];
  control: Control<CreateSingleLabelFormValues, any>;
}

const EmptyRatesContainer = styled(Grid)(({ theme }) => ({
  margin: "16px 0",
  padding: "16px",
  backgroundColor: theme.palette.primaryScale[900],
}));

const RatesContainer = styled(Grid)({
  padding: "16px 16px 16px 0",
  display: "grid",
  columnGap: 2,
  gridTemplateColumns: "1fr auto",
  gridTemplateRows: "1fr 1fr",
  alignItems: "center",
});

export default RatesRadioGroup;
