"use strict";
exports.__esModule = true;
exports.createPickupLocationRequestSchema = void 0;
/* eslint-disable import/prefer-default-export */
var zod_1 = require("zod");
var common_1 = require("../common");
exports.createPickupLocationRequestSchema = zod_1.z.object({
    merchant: zod_1.z.object({
        id: zod_1.z.string()
    }),
    name: zod_1.z.string().optional(),
    notes: zod_1.z.string().optional(),
    contact: zod_1.z.object({
        address: common_1.addressSchema,
        firstName: zod_1.z.string(),
        lastName: zod_1.z.string().optional(),
        email: zod_1.z.string().email(),
        phone: zod_1.z.string()
    }),
    active: zod_1.z.boolean().optional()["default"](false)
});
