import { DataProvider, GetListResult } from "react-admin";
import { FirebaseOptions } from "firebase/app";
import { ref, getBlob } from "firebase/storage";
import { MarketsController } from "@swyft/domain";

import { Market } from "../types";
import { firebasePublicStorage } from "~/services/firebase";

/**
 * Data Provider for the "markets" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): MarketsDataProvider => {
  const marketsController = new MarketsController(firebaseConfig);

  return {
    getList: async (resource, params): Promise<GetListResult> => {
      let { data, total } = await marketsController.getList(params);
      let mapZones = data as Market[];
      const bucket = process.env.REACT_APP_FIREBASE_PUBLIC_BUCKET;

      if (params.meta?.fetchGeoJSON) {
        const polygons = await Promise.allSettled(
          mapZones.map(async (zone) => {
            if (zone.polygon) {
              const path = `${bucket}/${zone.polygon}`;
              const fileRef = ref(firebasePublicStorage, path);
              const blob = await getBlob(fileRef);
              const blobContent = await blob.text();
              return JSON.parse(blobContent);
            }
          }),
        );
        mapZones.forEach((zone, idx) => {
          if (polygons[idx].status === "fulfilled") {
            //@ts-ignore
            mapZones[idx].polygon = polygons[idx].value;
          }
          if (polygons[idx].status === "rejected") {
            //if a url is provided, but the file has been deleted reset property.
            mapZones[idx].polygon = null;
          }
        });
      }
      return { data: mapZones, total };
    },
    getOne: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    update: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    deleteMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
  };
};

export interface MarketsDataProvider extends DataProvider {}
