"use strict";
exports.__esModule = true;
exports.RateCardType = exports.MonthlyVolume = exports.MerchantType = void 0;
var MerchantType;
(function (MerchantType) {
    MerchantType["ENTERPRISE"] = "ENTERPRISE";
    MerchantType["SMB"] = "SMB";
    /** The customers that pick and pack items for merchants */
    MerchantType["THIRD_PARTY_LOGISTICS"] = "THIRD_PARTY_LOGISTICS";
    /** The customers of the 3PL */
    MerchantType["THIRD_PARTY_LOGISTICS_MERCHANT"] = "THIRD_PARTY_LOGISTICS_MERCHANT";
})(MerchantType = exports.MerchantType || (exports.MerchantType = {}));
var MonthlyVolume;
(function (MonthlyVolume) {
    MonthlyVolume["VERY_SMALL"] = "VERY_SMALL";
    MonthlyVolume["SMALL"] = "SMALL";
    MonthlyVolume["MEDIUM"] = "MEDIUM";
    MonthlyVolume["LARGE"] = "LARGE";
    MonthlyVolume["EXTRA_LARGE"] = "EXTRA_LARGE";
})(MonthlyVolume = exports.MonthlyVolume || (exports.MonthlyVolume = {}));
var RateCardType;
(function (RateCardType) {
    RateCardType["rateCard"] = "rateCard";
    RateCardType["rateCardV2"] = "rateCardV2";
})(RateCardType = exports.RateCardType || (exports.RateCardType = {}));
