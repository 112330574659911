import { Button } from "@helo/ui";
import { useLogout, useTranslate } from "react-admin";

const LogoutButton = () => {
  const logout = useLogout();
  const translate = useTranslate();

  return (
    <Button color="error" variant="contained" onClick={logout}>
      {translate("ra.auth.logout")}
    </Button>
  );
};

export default LogoutButton;
