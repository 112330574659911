import { createContext, PropsWithChildren, useContext } from "react";
import { useGetOne, UserIdentity as RaUserIdentity } from "react-admin";
import { Merchant, User } from "@swyft/types";

import { useGetIdentity } from "../services/auth/hooks";
import { UserIdentity } from "~/services/auth/authProvider";
import { AppResource } from "~/config/resources";
import Loading from "~/components/feedback/Loading";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import LogoutButton from "~/components/auth/Logout";
import { UseQueryResult } from "react-query";

interface AuthenticatedContextType {
  identity?: UserIdentity;
  user?: User;
  merchant?: Merchant;
  refetchIdentity?: () => Promise<UseQueryResult<UserIdentity, Error>>;
}

const AuthenticatedContext = createContext<AuthenticatedContextType | null>({});

export const useAuthenticatedContext = () => {
  const context = useContext(AuthenticatedContext);

  if (context === null) {
    throw new Error("Must be called within AuthenticatedContextProvider.");
  }

  return context;
};

export function AuthenticatedContextProvider({
  children,
}: PropsWithChildren<{}>) {
  // fetch authenticated user identity
  const {
    identity,
    isLoading: loadingIdentity,
    error: errorIdentity,
    refetch: refetchIdentity,
  } = useGetIdentity();

  // fetch user
  const {
    data: user,
    isLoading: loadingUser,
    error: errorUser,
  } = useGetOne<User>(
    AppResource.User,
    { id: identity?.id ?? "" },
    { enabled: !!identity?.id },
  );

  // fetch merchant
  const {
    data: merchant,
    isLoading: loadingMerchant,
    error: errorMerchant,
  } = useGetOne<Merchant>(
    AppResource.Merchant,
    { id: identity?.organizationId ?? user?.organizationId ?? "" },
    {
      enabled: !!identity?.organizationId || !!user?.organizationId,
    },
  );

  if (loadingIdentity || loadingUser || loadingMerchant) {
    return <Loading loadingPrimary="shared.message.loading.account" />;
  }

  if (errorIdentity || errorUser || errorMerchant) {
    console.error(errorIdentity || errorUser || errorMerchant);

    return (
      <GracefulFallback
        type="error"
        details="shared.message.error.account"
        action={<LogoutButton />}
        sx={{
          height: "100vh",
        }}
      />
    );
  }

  return (
    <AuthenticatedContext.Provider
      value={{
        identity,
        user,
        merchant,
        refetchIdentity,
      }}
    >
      {children}
    </AuthenticatedContext.Provider>
  );
}
