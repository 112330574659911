import { Typography, styled, Stack, Box, InboxIcon } from "@helo/ui";
import { useShowContext, useTranslate } from "react-admin";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";

const InvoiceEmbed = () => {
  const { record } = useShowContext();
  const { merchant } = useAuthenticatedContext();
  const translate = useTranslate();

  return (
    <Stack>
      <Typography variant="h6" component="h3" sx={{ fontWeight: 600, mt: 2 }}>
        {translate("invoices.content.details.iframe.title")}
      </Typography>
      {merchant?.id && record.label ? (
        <InvoiceIframe
          src={`https://datastudio.google.com/embed/u/1/reporting/713e269a-3876-4b0f-9c8a-dd75031b1a36/page/p_stqfnutfsc?params=%7B%22df34%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${merchant.id}%22,%22df30%22:%22include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${record.label}%22%7D`}
          frameBorder="0"
          width={1600}
          allowFullScreen
          id={`invoice-${record.label}-embed`}
        />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            color: theme.palette.neutral.dark,
          })}
        >
          <InboxIcon sx={{ width: "50px", height: "50px", mr: 2 }} />
          <Typography variant="body1">
            {translate("invoices.content.details.iframe.empty")}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default InvoiceEmbed;

const InvoiceIframe = styled("iframe")(({ theme }) => ({
  height: "900px",
  width: "100%",
  margin: "0 auto",
  maxWidth: theme.breakpoints.values.xl,
  alignSelf: "center",
}));
