import { useTranslate, useLocaleState } from "react-admin";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@helo/ui";
import { currencyFormatter } from "~/common/helpers";
import { DisplayAddOns } from "~/services/data/types";
import { RateCardAddOns, ServiceAddOns } from "@swyft/types";

interface AddOnRatesProps {
  addOns: RateCardAddOns;
  currency: string;
}

const AddOnRates = ({ addOns, currency }: AddOnRatesProps) => {
  const translate = useTranslate();
  const [locale] = useLocaleState();

  const addOnsDisplay: DisplayAddOns[] = Object.keys(addOns)
    .sort((a, b) => a.localeCompare(b))
    .reduce(function (filtered: DisplayAddOns[], item) {
      if (addOns[item as ServiceAddOns] > 0) {
        filtered.push({
          addOn: item,
          rate: addOns[item as ServiceAddOns],
        });
      }
      return filtered;
    }, []);

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        {translate("rates.add_ons.title")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate("rates.add_ons.table.head.type")}
              </TableCell>
              <TableCell>
                {translate("rates.add_ons.table.head.description")}
              </TableCell>
              <TableCell>{translate("rates.add_ons.table.head.fee")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addOnsDisplay
              ? addOnsDisplay.map((item, idx) => (
                  <TableRow key={`${idx}-${item.addOn}-details`}>
                    <TableCell>
                      {translate(`rates.add_ons.table.body.${item.addOn}.name`)}
                    </TableCell>
                    <TableCell>
                      {translate(
                        `rates.add_ons.table.body.${item.addOn}.description`,
                      )}
                    </TableCell>
                    <TableCell>
                      {currencyFormatter(locale, item.rate, currency)}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AddOnRates;
