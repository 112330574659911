import { useTranslate, useShowContext } from "react-admin";

import { Typography, Box, InboxIcon } from "@helo/ui";

const InvoiceCard = () => {
  const translate = useTranslate();
  const { record } = useShowContext();

  return (
    <Box>
      <Typography variant="h6" component="h3" sx={{ fontWeight: 600, mb: 2 }}>
        {translate("invoices.content.details.summary.title")}
      </Typography>
      {!!record.exportFileLocation.url ? (
        <object
          style={{ width: "100%", height: "1200px" }}
          data={record.exportFileLocation.url}
          type="application/pdf"
        >
          <iframe
            width="100%"
            height="1200px"
            src={record.exportFileLocation.url}
          ></iframe>
        </object>
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            color: theme.palette.neutral.dark,
          })}
        >
          <InboxIcon sx={{ width: "50px", height: "50px", mr: 2 }} />
          <Typography variant="body1">
            {translate("invoices.content.details.summary.empty")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default InvoiceCard;
