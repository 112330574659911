import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LoadingButton,
  styled,
  TextField,
  Typography,
} from "@helo/ui";
import { useTranslate } from "react-admin";
import { useState } from "react";
import { Recipient } from "@swyft/types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAddressEditSchema } from "~/common/validators/shipment/AddressEditSchema";

const Address = ({
  data,
  enableEdit = false,
  editDefaultValues,
  onEditSubmit,
  editTitle,
  editDescription,
  showValidationSource,
  showContact,
}: AddressProps) => {
  const translate = useTranslate();
  const [editOpen, setEditOpen] = useState(false);
  const defaultValues: EditAddressForm = Object.assign(
    {
      line1: `${
        data.validatedAddressV2?.buildingNumber ||
        data.validatedAddress?.buildingNumber
      } ${
        data.validatedAddressV2?.street ||
        data.validatedAddressV2?.street ||
        data.address.line1
      }`,
      line2:
        data.validatedAddressV2?.line2 ||
        data.validatedAddress?.line2 ||
        data.address.line2,
      city:
        data.validatedAddressV2?.city ||
        data.validatedAddress?.city ||
        data.address.city,
      stateProvince:
        data.validatedAddressV2?.province ||
        data.validatedAddress?.province ||
        data.address.province,
      country:
        data.validatedAddressV2?.country ||
        data.validatedAddress?.country ||
        data.address.country,
      zipPostal:
        data.validatedAddressV2?.postalCode ||
        data.validatedAddress?.postalCode ||
        data.address.postalCode,
      phone: data.phone,
    },
    editDefaultValues || {},
  );
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues,
    resolver: yupResolver(getAddressEditSchema(translate)),
    mode: "onChange",
  });
  let fullAddress;

  const handleEditTriggerClick = () => setEditOpen(true);
  const handleEditClose = () => {
    reset(defaultValues);
    setEditOpen(false);
  };
  const handleEditSubmit = async (formValues: EditAddressForm) => {
    if (onEditSubmit) {
      await onEditSubmit(formValues);
    }
    setEditOpen(false);
  };

  if (data.validatedAddressV2) {
    fullAddress = (
      <>
        <Typography variant="inherit">
          {data.firstName} {data.lastName}
        </Typography>
        <Typography variant="inherit">
          {data.validatedAddressV2.buildingNumber}{" "}
          {data.validatedAddressV2.street}
        </Typography>
        <Typography variant="inherit">
          {data.validatedAddressV2.line2}
        </Typography>
        <Typography variant="inherit">
          {data.validatedAddressV2.city}
        </Typography>
        <Typography variant="inherit">
          {data.validatedAddressV2.province}, {data.validatedAddressV2.country}
        </Typography>
        <Typography variant="inherit">
          {data.validatedAddressV2.postalCode}
        </Typography>
        {showValidationSource && data.validatedAddressV2.validationSource && (
          <Typography
            variant="caption"
            sx={({ palette }) => ({
              fontStyle: "italic",
              color: palette.neutralScale[600],
            })}
          >
            ({translate("shared.label.validated_by")}{" "}
            {data.validatedAddressV2.validationSource})
          </Typography>
        )}
        {showContact && data.email && (
          <Typography
            variant="inherit"
            sx={({ palette }) => ({
              color: palette.neutralScale[500],
              mt: 0.5,
            })}
          >
            {data.email}
          </Typography>
        )}
        {showContact && data.phone && (
          <Typography
            variant="inherit"
            sx={({ palette }) => ({ color: palette.neutralScale[500] })}
          >
            {data.phone}
          </Typography>
        )}
      </>
    );
  } else if (data.validatedAddress) {
    fullAddress = (
      <>
        <Typography variant="inherit">
          {data.validatedAddress.buildingNumber} {data.validatedAddress.street}
        </Typography>
        <Typography variant="inherit">{data.validatedAddress.line2}</Typography>
        <Typography variant="inherit">{data.validatedAddress.city}</Typography>
        <Typography variant="inherit">
          {data.validatedAddress.province}, {data.validatedAddress.country}
        </Typography>
        <Typography variant="inherit">
          {data.validatedAddress.postalCode}
        </Typography>
        {showValidationSource && data.validatedAddress.validationSource && (
          <Typography variant="caption" sx={{ fontStyle: "italic" }}>
            ({translate("shared.label.validated_by")}{" "}
            {data.validatedAddress.validationSource})
          </Typography>
        )}
        {showContact && data.email && (
          <Typography
            variant="inherit"
            sx={({ palette }) => ({
              color: palette.neutralScale[500],
              mt: 0.5,
            })}
          >
            {data.email}
          </Typography>
        )}
        {showContact && data.phone && (
          <Typography
            variant="inherit"
            sx={({ palette }) => ({ color: palette.neutralScale[500] })}
          >
            {data.phone}
          </Typography>
        )}
      </>
    );
  } else if (data.address) {
    fullAddress = (
      <>
        <Typography variant="inherit">{data.address.line1}</Typography>
        <Typography variant="inherit">{data.address.line2}</Typography>
        <Typography variant="inherit">{data.address.city}</Typography>
        <Typography variant="inherit">
          {data.address.province}, {data.address.country}
        </Typography>
        <Typography variant="inherit">{data.address.postalCode}</Typography>
        {showContact && data.email && (
          <Typography
            variant="inherit"
            sx={({ palette }) => ({
              color: palette.neutralScale[500],
              mt: 0.5,
            })}
          >
            {data.email}
          </Typography>
        )}
        {showContact && data.phone && (
          <Typography
            variant="inherit"
            sx={({ palette }) => ({ color: palette.neutralScale[500] })}
          >
            {data.phone}
          </Typography>
        )}
      </>
    );
  } else {
    fullAddress = translate("shared.content.not_available");
  }

  return (
    <>
      <Typography
        variant="body2"
        component="address"
        sx={{ fontStyle: "normal" }}
      >
        {fullAddress}
      </Typography>
      {enableEdit && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleEditTriggerClick}
          sx={{ mt: 1 }}
        >
          {translate("ra.action.edit")}
        </Button>
      )}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>
          {editTitle ??
            translate("shared.content.edit.title", {
              name: translate("shared.label.address.all"),
            })}
        </DialogTitle>
        <DialogContent>
          {editDescription && (
            <DialogContentText>{editDescription}</DialogContentText>
          )}
          <Form noValidate sx={{ mt: 2 }}>
            <TextField
              error={Boolean(formState?.errors?.line1)}
              helperText={formState?.errors?.line1?.message}
              label={translate("shared.label.address.line1")}
              {...register("line1")}
              name="line1"
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
            <TextField
              error={Boolean(formState?.errors?.line2)}
              helperText={formState?.errors?.line2?.message}
              label={translate("shared.label.address.line2")}
              {...register("line2")}
              name="line2"
            />
            <BoxDualColumn>
              <TextField
                error={Boolean(formState?.errors?.city)}
                helperText={formState?.errors?.city?.message}
                label={translate("shared.label.address.city")}
                {...register("city")}
                name="city"
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
              <TextField
                error={Boolean(formState?.errors?.stateProvince)}
                helperText={formState?.errors?.stateProvince?.message}
                label={translate("shared.label.address.state_province")}
                {...register("stateProvince")}
                name="stateProvince"
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </BoxDualColumn>
            <BoxDualColumn>
              <TextField
                error={Boolean(formState?.errors?.country)}
                helperText={formState?.errors?.country?.message}
                label={translate("shared.label.address.country")}
                {...register("country")}
                name="country"
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
              <TextField
                error={Boolean(formState?.errors?.zipPostal)}
                helperText={formState?.errors?.zipPostal?.message}
                label={translate("shared.label.address.zip_postal")}
                {...register("zipPostal")}
                name="zipPostal"
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </BoxDualColumn>
            <TextField
              multiline
              rows={4}
              error={Boolean(formState?.errors?.notes)}
              helperText={formState?.errors?.notes?.message}
              label={translate("shared.label.address.notes")}
              {...register("notes")}
            />
            <TextField
              error={Boolean(formState?.errors?.phone)}
              helperText={formState?.errors?.phone?.message}
              label={translate("shared.label.phone")}
              type="tel"
              {...register("phone")}
            />
          </Form>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="neutral" onClick={handleEditClose}>
            {translate("ra.action.cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleEditSubmit)}
            loading={formState.isSubmitting}
          >
            {translate("ra.action.save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Form = styled("form")({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: 20,
  placeContent: "center",
});

const BoxDualColumn = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: 10,
});

interface AddressProps {
  data: Recipient;
  enableEdit?: boolean;
  editDefaultValues?: Partial<EditAddressForm>;
  onEditSubmit?: (values: EditAddressForm) => Promise<void>;
  editTitle?: string;
  editDescription?: string;
  showValidationSource?: boolean;
  showContact?: boolean;
}

export interface EditAddressForm {
  line1: string;
  line2?: string;
  city: string;
  stateProvince: string;
  zipPostal: string;
  country: string;
  notes?: string;
  phone?: string;
}

export default Address;
