import {
  useTranslate,
  useShowContext,
  Show,
  useLocaleState,
} from "react-admin";
import { Breadcrumbs, Link, Stack, Skeleton, Box, Chip } from "@helo/ui";
import { Link as RouterLink } from "react-router-dom";

import { AppViewLayout } from "~/layouts/app";
import InvoiceCard from "./components/InvoiceCard";
import InvoiceActions from "./components/InvoiceActions";
import { Routes } from "~/config/Routes";
import { InvoiceStatus } from "~/services/data/types";
import InvoiceEmbed from "./components/InvoiceEmbed";

export const InvoiceShow = () => {
  return (
    <Show
      queryOptions={{ meta: { getInvoiceUrl: true } }}
      component="div"
      sx={{
        height: "100%",
        ["& .RaShow-card"]: {
          height: "100%",
        },
        ["& .RaShow-noActions"]: {
          height: "100%",
          margin: 0,
        },
      }}
    >
      <InvoiceDetailsLayout />
    </Show>
  );
};

const InvoiceDetailsLayout = () => {
  const translate = useTranslate();
  const { record, isLoading } = useShowContext();
  const [locale] = useLocaleState();

  if (isLoading) {
    return (
      <Stack
        spacing={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={"30%"}
          height={50}
          sx={{ alignSelf: "flex-start", ml: "10%", mt: 5 }}
        />
        {[...Array(2)].map((...[, idx]) => (
          <Skeleton
            variant="rectangular"
            width={"80%"}
            height={500}
            key={`${idx}-skeleton`}
          />
        ))}
      </Stack>
    );
  }
  return (
    <AppViewLayout
      title={"invoices.content.details.title"}
      titleFlair={<InvoiceActions />}
      breadcrumbs={
        <Breadcrumbs>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={Routes.Invoices}
          >
            {translate("menu.invoices")}
          </Link>
          {record && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link underline="none" color="text.primary">
                {record.label}
              </Link>
              <Chip
                label={translate(`invoices.content.status.${record.status}`)}
                variant="filled"
                size="medium"
                color="neutral"
                sx={({ palette }) => {
                  const bgColor =
                    record.status === InvoiceStatus.INVOICED
                      ? `${palette.primary.main}`
                      : record.status === InvoiceStatus.PAID
                      ? `${palette.success.main}`
                      : `${palette.error.main}`;

                  const textColor =
                    record.status === InvoiceStatus.INVOICED
                      ? `${palette.primary.contrastText}`
                      : record.status === InvoiceStatus.PAID
                      ? `${palette.success.darker}`
                      : `${palette.error.contrastText}`;

                  return {
                    backgroundColor: bgColor,
                    color: textColor,
                    ml: 1,
                  };
                }}
              />
            </Box>
          )}
        </Breadcrumbs>
      }
    >
      <Stack>
        <InvoiceCard />
        <InvoiceEmbed />
      </Stack>
    </AppViewLayout>
  );
};
