"use strict";
exports.__esModule = true;
exports.TrackingEventState = exports.DeliveryErrorCode = exports.TrackingEventSourceType = exports.determineIfTrackingEvent = void 0;
function determineIfTrackingEvent(event) {
    return !!((event === null || event === void 0 ? void 0 : event.timestamp) && (event === null || event === void 0 ? void 0 : event.state));
}
exports.determineIfTrackingEvent = determineIfTrackingEvent;
var TrackingEventSourceType;
(function (TrackingEventSourceType) {
    TrackingEventSourceType["DASHBOARD"] = "DASHBOARD";
    TrackingEventSourceType["SWYFT"] = "SWYFT";
    TrackingEventSourceType["ONFLEET"] = "ONFLEET";
})(TrackingEventSourceType = exports.TrackingEventSourceType || (exports.TrackingEventSourceType = {}));
var DeliveryErrorCode;
(function (DeliveryErrorCode) {
    DeliveryErrorCode["NO_ACCESS_COMMERCIAL_ADDRESS"] = "NO_ACCESS_COMMERCIAL_ADDRESS";
    DeliveryErrorCode["NO_ACCESS_RESIDENTIAL_ADDRESS"] = "NO_ACCESS_RESIDENTIAL_ADDRESS";
    DeliveryErrorCode["UNABLE_TO_COLLECT_SIGNATURE"] = "UNABLE_TO_COLLECT_SIGNATURE";
    DeliveryErrorCode["CUSTOMER_REFUSED_DELIVERY"] = "CUSTOMER_REFUSED_DELIVERY";
    DeliveryErrorCode["INCORRECT_CUSTOMER_ADDRESS"] = "INCORRECT_CUSTOMER_ADDRESS";
    DeliveryErrorCode["MISSING_PACKAGE"] = "MISSING_PACKAGE";
    DeliveryErrorCode["DRIVER_ACCIDENT"] = "DRIVER_ACCIDENT";
    DeliveryErrorCode["ITEM_DAMAGED"] = "ITEM_DAMAGED";
    DeliveryErrorCode["PAST_DELIVERY_WINDOW"] = "PAST_DELIVERY_WINDOW";
    DeliveryErrorCode["NO_SPACE_IN_CAR"] = "NO_SPACE_IN_CAR";
    DeliveryErrorCode["OTHER"] = "OTHER";
})(DeliveryErrorCode = exports.DeliveryErrorCode || (exports.DeliveryErrorCode = {}));
var TrackingEventState;
(function (TrackingEventState) {
    /** Swyft created label */
    TrackingEventState["PENDING"] = "PENDING";
    /** OnFleet task created (updates label via webhook) */
    TrackingEventState["CONFIRMED"] = "CONFIRMED";
    /** OnFleet task assigned to driver (updates label via webhook) */
    TrackingEventState["ASSIGNED"] = "ASSIGNED";
    /** Swyft scans the label at the warehouse */
    TrackingEventState["RECEIVED"] = "RECEIVED";
    /** OnFleet task started (initiated by the driver) */
    TrackingEventState["IN_TRANSIT"] = "IN_TRANSIT";
    /** OnFleet geolocation trigger (driver is near destination) */
    TrackingEventState["ARRIVING"] = "ARRIVING";
    /** OnFleet completes task (driver completes the task) */
    TrackingEventState["DELIVERED"] = "DELIVERED";
    /** OnFleet fails task (driver fails delivery) */
    TrackingEventState["FAILED"] = "FAILED";
    /** Swyft deletes the label */
    TrackingEventState["DELETED"] = "DELETED";
    /** If the label.redeliveryAttempts is less than 3, it will be put into this temporary state */
    TrackingEventState["SCHEDULED_FOR_REDELIVERY"] = "SCHEDULED_FOR_REDELIVERY";
    /** If the label.redeliveryAttempts is greater than 3, it will be put into this temporary state */
    TrackingEventState["RETURNING_TO_SENDER"] = "RETURNING_TO_SENDER";
    /** The package has been returned to sender */
    TrackingEventState["RETURNED_TO_SENDER"] = "RETURNED_TO_SENDER";
    /** The package was not received from the merchant */
    TrackingEventState["NOT_RECEIVED"] = "NOT_RECEIVED";
    /** There was an issue with the package and it is being triaged */
    TrackingEventState["PROBLEM"] = "PROBLEM";
    /** The package is in storage and will be sent out at a later date */
    TrackingEventState["STORAGE"] = "STORAGE";
})(TrackingEventState = exports.TrackingEventState || (exports.TrackingEventState = {}));
