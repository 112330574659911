import { Grid, LoadingButton, styled, TextField } from "@helo/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { CountryISO2 } from "@swyft/types";
import { useTranslate } from "react-admin";
import { useForm } from "react-hook-form";

import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import { PackageSize, PackageWeight, MonthlyVolume } from "~/constants/form";
import { getMerchantSchema } from "~/common/validators/ftux/merchantSchema";
import {
  CountryOptions,
  MonthlyVolumeOptions,
  PackageSizeOptions,
  PackageWeightOptions,
} from "~/common/consts";

const MerchantForm = ({ onSubmit }: MerchantFormProps) => {
  const translate = useTranslate();
  const defaultValues: MerchantFormValues = {
    country: "" as CountryISO2,
    companyName: "",
    jobTitle: "",
    monthlyVolume: "" as MonthlyVolume,
    packageSize: "" as PackageSize,
    packageWeight: "" as PackageWeight,
  };
  const { register, handleSubmit, formState, control } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(getMerchantSchema(translate)),
    mode: "onChange",
  });

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            error={Boolean(formState?.errors?.companyName)}
            helperText={formState?.errors?.companyName?.message}
            label={translate("Company Name")}
            {...register("companyName")}
            name="companyName"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            error={Boolean(formState?.errors?.jobTitle)}
            helperText={formState?.errors?.jobTitle?.message}
            label={translate("Job Title")}
            {...register("jobTitle")}
            name="jobTitle"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ControlledAutocomplete
            control={control}
            options={CountryOptions}
            name="country"
            rules={{ required: true }}
            error={Boolean(formState?.errors?.country)}
            helperText={formState?.errors?.country?.message}
            label={translate("Country")}
            placeholder={translate("shared.form.placeholder.select_country")}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ControlledAutocomplete
            control={control}
            options={MonthlyVolumeOptions}
            name="monthlyVolume"
            rules={{ required: true }}
            error={Boolean(formState?.errors?.monthlyVolume)}
            helperText={formState?.errors?.monthlyVolume?.message}
            label={translate("Monthly Shipping Volume")}
            placeholder={translate("shared.form.placeholder.select_volume")}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ControlledAutocomplete
            control={control}
            options={PackageSizeOptions}
            name="packageSize"
            rules={{ required: true }}
            error={Boolean(formState?.errors?.packageSize)}
            helperText={formState?.errors?.packageSize?.message}
            label={translate("Average Package Size")}
            placeholder={translate("shared.form.placeholder.select_size")}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ControlledAutocomplete
            control={control}
            options={PackageWeightOptions}
            name="packageWeight"
            rules={{ required: true }}
            error={Boolean(formState?.errors?.packageWeight)}
            helperText={formState?.errors?.packageWeight?.message}
            label={translate("Average Package Weight")}
            placeholder={translate("shared.form.placeholder.select_weight")}
          />
        </Grid>
        <Grid item>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={formState?.isSubmitting}
            size="large"
          >
            {translate("Save")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
};

const Form = styled("form")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: "1000px",
  },
}));

// TODO: this interface needs to check against the key of the enum instead of the value
export interface MerchantFormValues {
  country: CountryISO2;
  companyName: string;
  jobTitle: string;
  monthlyVolume: MonthlyVolume;
  packageSize: PackageSize;
  packageWeight: PackageWeight;
}

interface MerchantFormProps {
  onSubmit: (formValues: MerchantFormValues) => Promise<void>;
}

export default MerchantForm;
