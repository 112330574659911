import { GetOneResult, DataProvider, UpdateResult } from "react-admin";
import { FirebaseOptions } from "firebase/app";
import {
  LabelController,
  PrintPdfLabelsCallableRequestParams,
} from "@swyft/domain";

/**
 * Data Provider for the "labels" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): LabelDataProvider => {
  const labelController = new LabelController(firebaseConfig);

  // TODO: update these functions to those used in ./firestore.ts for labels
  return {
    getList: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      return await labelController.getOne(params);
    },
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: async (resource, params) => {
      throw new Error("Function not created.");
    },
    update: async (resource, params): Promise<UpdateResult> => {
      return await labelController.update({
        ...params,
        id: String(params.id),
      });
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    deleteMany: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getPrintPdfs: async (
      resource: string,
      params: PrintPdfLabelsCallableRequestParams,
    ) => {
      try {
        const data = await labelController.generatePrintPdfs(params);
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
  };
};

export interface LabelDataProvider extends DataProvider {
  getPrintPdfs: (
    resource: string,
    params: PrintPdfLabelsCallableRequestParams,
  ) => Promise<{ data: string }>;
}
