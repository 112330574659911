import { useTranslate, useLocaleState } from "react-admin";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@helo/ui";
import { PackageSize, ServiceType, RateCard } from "@swyft/types";

import { currencyFormatter } from "~/common/helpers";

interface RateCardV1ServiceLevelsProps {
  title: string;
  rateCard: RateCard;
  serviceType: ServiceType;
}

const RateCardV1ServiceLevels = ({
  title,
  rateCard,
  serviceType,
}: RateCardV1ServiceLevelsProps) => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const ratePlaceholder = "-";

  // retrieve rate for specific package size to display in correct column
  const getRate = (size: PackageSize, serviceType: ServiceType): string => {
    return rateCard?.services[serviceType] &&
      rateCard.services[serviceType][size]
      ? currencyFormatter(
          locale,
          rateCard.services[serviceType][size] || 0,
          rateCard.currency,
        )
      : ratePlaceholder;
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        {translate(`rates.service_level.title.${title}`)}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate("rates.service_level.table.head.small")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.medium")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.large")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.extra_large")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{getRate(PackageSize.SMALL, serviceType)}</TableCell>
              <TableCell>{getRate(PackageSize.MEDIUM, serviceType)}</TableCell>
              <TableCell>{getRate(PackageSize.LARGE, serviceType)}</TableCell>
              <TableCell>
                {getRate(PackageSize.EXTRA_LARGE, serviceType)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RateCardV1ServiceLevels;
