"use strict";
exports.__esModule = true;
exports.dimensionsSchema = exports.validatedAddressV2Schema = exports.addressSchema = void 0;
var zod_1 = require("zod");
var common_1 = require("../../types/common");
exports.addressSchema = zod_1.z.discriminatedUnion("country", [
    zod_1.z.object({
        country: zod_1.z.literal("CA"),
        line1: zod_1.z.string(),
        line2: zod_1.z.string().optional(),
        city: zod_1.z.string(),
        province: zod_1.z["enum"](common_1.CA_PROVINCES),
        postalCode: zod_1.z
            .string()
            .regex(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/)
    }),
    zod_1.z.object({
        country: zod_1.z.literal("US"),
        line1: zod_1.z.string(),
        line2: zod_1.z.string().optional(),
        city: zod_1.z.string(),
        province: zod_1.z["enum"](common_1.US_STATES),
        postalCode: zod_1.z.string().regex(/\d{5}([-]\d{4})?/)
    }),
]);
exports.validatedAddressV2Schema = zod_1.z.discriminatedUnion("country", [
    zod_1.z.object({
        country: zod_1.z.literal("CA"),
        street: zod_1.z.string(),
        line2: zod_1.z.string().optional(),
        buildingNumber: zod_1.z.string(),
        label: zod_1.z.string(),
        city: zod_1.z.string(),
        province: zod_1.z["enum"](common_1.CA_PROVINCES),
        postalCode: zod_1.z
            .string()
            .regex(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/),
        geocode: zod_1.z.object({
            latitude: zod_1.z.number(),
            longitude: zod_1.z.number()
        }),
        geohash: zod_1.z.string().optional(),
        geocodePrecision: zod_1.z.nativeEnum(common_1.ISmartyStreetsPrecision),
        addressPrecision: zod_1.z.nativeEnum(common_1.ISmartyStreetsPrecision),
        validationSource: zod_1.z.nativeEnum(common_1.ValidationEngine),
        taxRegionId: zod_1.z.string().optional()
    }),
    zod_1.z.object({
        country: zod_1.z.literal("US"),
        street: zod_1.z.string(),
        line2: zod_1.z.string().optional(),
        buildingNumber: zod_1.z.string(),
        label: zod_1.z.string(),
        city: zod_1.z.string(),
        province: zod_1.z["enum"](common_1.US_STATES),
        postalCode: zod_1.z.string().regex(/\d{5}([-]\d{4})?/),
        geocode: zod_1.z.object({
            latitude: zod_1.z.number(),
            longitude: zod_1.z.number()
        }),
        geohash: zod_1.z.string().optional(),
        geocodePrecision: zod_1.z.nativeEnum(common_1.ISmartyStreetsPrecision),
        addressPrecision: zod_1.z.nativeEnum(common_1.ISmartyStreetsPrecision),
        validationSource: zod_1.z.nativeEnum(common_1.ValidationEngine),
        taxRegionId: zod_1.z.string().optional()
    }),
]);
exports.dimensionsSchema = zod_1.z.object({
    lengthInches: zod_1.z.number(),
    widthInches: zod_1.z.number(),
    heightInches: zod_1.z.number(),
    weight: zod_1.z.object({
        unit: zod_1.z["enum"](["pounds", "ounces"]),
        value: zod_1.z.number()
    })
});
