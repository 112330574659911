import React from "react";
import { useTranslate } from "react-admin";
import { Box } from "@helo/ui";
import { SearchProvider } from "@elastic/react-search-ui";

import { configuration, NULL_SEARCH_TOKEN } from "~/config/elasticAppSearch";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { LabelsContainer } from "./LabelsContainer";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import { AppViewLayout } from "~/layouts/app";

export const Labels: React.FunctionComponent = () => {
  const { merchant } = useAuthenticatedContext();
  const translate = useTranslate();
  const searchToken = merchant?.searchToken || NULL_SEARCH_TOKEN;

  const isOrgActive = merchant?.isActive;

  return (
    <AppViewLayout
      title="menu.shipments.manage"
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("shipments.content.list.unactive_alert")}
          />
        )
      }
    >
      <Box
        sx={{
          marginTop: "2rem",
          maxWidth: "100%",
        }}
      >
        {/* @ts-ignore */}
        <SearchProvider config={configuration(searchToken)}>
          <LabelsContainer validSearchToken={searchToken} />
        </SearchProvider>
      </Box>
    </AppViewLayout>
  );
};
