"use strict";
exports.__esModule = true;
exports.ScanType = exports.determineIfScanEvent = void 0;
function determineIfScanEvent(event) {
    return !!((event === null || event === void 0 ? void 0 : event.type) && (event === null || event === void 0 ? void 0 : event.timestamp));
}
exports.determineIfScanEvent = determineIfScanEvent;
/** Specifies different kinds of scans that can take place */
var ScanType;
(function (ScanType) {
    /** Package was scanned when scanner app is in received mode */
    ScanType["ARRIVAL_SCAN"] = "ARRIVAL_SCAN";
    /** Package was scanned when scanner app is in sortation mode */
    ScanType["SORT_SCAN"] = "SORT_SCAN";
    /** Package scanned in read only mode */
    ScanType["READ_ONLY_SCAN"] = "READ_ONLY_SCAN";
    /** Package scanned by gatsby */
    ScanType["GATSBY_SCAN"] = "GATSBY_SCAN";
    /** Package scanned by a driver */
    ScanType["DRIVER_SCAN"] = "DRIVER_SCAN";
})(ScanType = exports.ScanType || (exports.ScanType = {}));
