import {
  List,
  useTranslate,
  useListContext,
  useNotify,
  useCreate,
  useDelete,
  useRefresh,
} from "react-admin";
import {
  Breadcrumbs,
  Link,
  Stack,
  Skeleton,
  Typography,
  Tooltip,
  Grid,
  styled,
  LoadingButton,
  Alert,
  HelpIcon,
  Card,
} from "@helo/ui";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { OrganizationType } from "@swyft/types";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import ResourceEmptyState from "~/components/data/ResourceEmptyState";
import { AppViewLayout } from "~/layouts/app";
import { AppResource } from "~/config/resources";
import { IApiToken, ApiTokenType } from "@swyft/swyft-common";
import { Webhooks } from "./Webhooks";

const ApiList = () => {
  useDefineAppLocation("menu.settings.integrations.api");
  const translate = useTranslate();
  const { merchant } = useAuthenticatedContext();

  const filter = {
    org: {
      id: merchant?.id,
      type: !!merchant
        ? OrganizationType.Merchant
        : OrganizationType.DeliveryServicePartner,
    },
  };

  return (
    <AppViewLayout
      title={translate("menu.settings.integrations.api")}
      breadcrumbs={
        <Breadcrumbs>
          <Link underline="none" color="text.primary">
            {translate("api.description")}
          </Link>
        </Breadcrumbs>
      }
    >
      <List
        filter={filter}
        actions={false}
        empty={<ResourceEmptyState />}
        component="div"
        pagination={false}
      >
        <ApiListTable />
      </List>
      <Alert severity="info">
        <Link
          href="https://api.useswyft.com/docs"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate("api.documentation_description")}
        </Link>
      </Alert>
      <Webhooks />
    </AppViewLayout>
  );
};

const ApiCard = ({
  isActive,
  isSandbox,
  currApiKey,
  tokenType,
}: ApiCardProps) => {
  const [deleteOne, { isLoading: deleteContextIsLoading }] = useDelete();
  const [create, { isLoading: createContextIsLoading }] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const { merchant } = useAuthenticatedContext();
  const toastDuration = 2000;

  const createKey = async (merchant: any, tokenType: ApiTokenType) => {
    try {
      const token = await create(
        AppResource.Api,
        {
          data: {
            isProductionToken: tokenType === ApiTokenType.LIVE,
            id: merchant?.id,
          },
        },
        { returnPromise: true },
      );
      if (token.id !== null) {
        notify("shared.message.create.ok", {
          type: "success",
          autoHideDuration: toastDuration,
          messageArgs: {
            name: translate("api.notify", { smart_count: 1 }),
            smart_count: 1,
          },
        });
      } else {
        notify(
          `${translate("shared.message.create.fail", {
            name: translate("api.notify", { smart_count: 1 }),
            smart_count: 1,
          })} ${translate("api.error")}`,
          {
            type: "warning",
            autoHideDuration: toastDuration,
          },
        );
      }
      refresh();
    } catch (error) {
      console.error(error);
    }
  };

  const removeKey = async (apiKey: IApiToken, tokenType: ApiTokenType) => {
    try {
      const token = await deleteOne(AppResource.Api, {
        id: apiKey.id,
        meta: {
          isProductionToken: tokenType === ApiTokenType.LIVE,
        },
      });
      if (token !== null) {
        notify("shared.message.remove.ok", {
          messageArgs: {
            smart_count: 1,
            name: translate("api.notify", {
              smart_count: 1,
            }),
          },
        });
        refresh();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card
      key={isActive ? currApiKey?.id : tokenType}
      sx={{ overflow: "visible" }}
    >
      <KeyContainer sx={{ padding: 3 }}>
        <TitleContainer>
          <Typography variant="h6" fontWeight={600} letterSpacing={-0.5}>
            {isSandbox
              ? translate("api.sandbox.name")
              : translate("api.live.name")}
          </Typography>
          <Tooltip
            title={
              isSandbox
                ? translate("api.sandbox.tooltip")
                : translate("api.live.tooltip")
            }
            placement="right-start"
          >
            <HelpIcon sx={{ marginLeft: "8px" }} />
          </Tooltip>
        </TitleContainer>
        <Typography variant="h6" fontWeight={400} letterSpacing={-0.5}>
          {isActive && currApiKey?.id}
        </Typography>
        {isActive ? (
          <IfCanAccess action="delete">
            <LoadingButton
              color="error"
              type="submit"
              variant="contained"
              size="large"
              loading={deleteContextIsLoading}
              onClick={() => removeKey(currApiKey, tokenType)}
            >
              {translate("api.delete")}
            </LoadingButton>
          </IfCanAccess>
        ) : (
          <IfCanAccess action="create">
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={createContextIsLoading}
              onClick={() => createKey(merchant, tokenType)}
            >
              {translate("api.btn")}
            </LoadingButton>
          </IfCanAccess>
        )}
      </KeyContainer>
    </Card>
  );
};

const ApiListTable = () => {
  const { isLoading, data } = useListContext();

  if (isLoading) {
    return (
      <Stack sx={{ mb: 2 }} spacing={2}>
        {[...Array(2)].map((...[, idx]) => (
          <Skeleton variant="rectangular" key={`${idx}-skeleton`} />
        ))}
      </Stack>
    );
  }

  return (
    <>
      {Object.values(ApiTokenType).map((tokenType) => {
        const isSandbox = tokenType === ApiTokenType.SANDBOX;
        const currApiKey = data.find((token) => token.type === tokenType);
        const isActive = currApiKey !== undefined;

        return (
          <div key={tokenType}>
            <ApiCard
              key={tokenType}
              isActive={isActive}
              currApiKey={currApiKey}
              isSandbox={isSandbox}
              tokenType={tokenType}
            />
          </div>
        );
      })}
    </>
  );
};

const KeyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: "40px",
  alignItems: "center",
  flexDirection: "column",
  gap: "12px",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

const TitleContainer = styled(Grid)(() => ({
  display: "flex",
}));

export interface ApiCardProps {
  isActive: boolean;
  isSandbox: boolean;
  currApiKey: IApiToken;
  tokenType: ApiTokenType;
}

export default ApiList;
