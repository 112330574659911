import { Box } from "@helo/ui";

import Steps from "~/features/onboarding/Steps";
import OnboardingViewLayout from "~/features/onboarding/OnboardingViewLayout";
import {
  MerchantOnboardingContextProvider,
  useMerchantOnboardingContext,
} from "~/components/MerchantOnboardingContext";
import { AppShellLayout } from "~/layouts/app";

const MerchantOnboarding = () => {
  return (
    <AppShellLayout>
      <MerchantOnboardingContextProvider>
        <MerchantOnboardingCore />
      </MerchantOnboardingContextProvider>
    </AppShellLayout>
  );
};

const MerchantOnboardingCore = () => {
  const { currentStep } = useMerchantOnboardingContext();

  return (
    <Box sx={{ display: "flex" }}>
      <Steps
        sx={{
          position: "sticky",
          top: "80px",
          alignSelf: "flex-start",
        }}
      />
      <OnboardingViewLayout
        sx={({ palette }) => ({
          minHeight: "100vh",
          width: "100%",
          backgroundColor: palette.primaryScale[1000],
          display: "grid",
          gridTemplateRows: "auto 1fr",
        })}
      >
        {currentStep.content}
      </OnboardingViewLayout>
    </Box>
  );
};

export default MerchantOnboarding;
