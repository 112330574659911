import { useTranslate } from "react-admin";
import { Breadcrumbs, Link, Stack, Skeleton, styled, Card } from "@helo/ui";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Integration } from "@swyft/swyft-common";

import { AppViewLayout } from "~/layouts/app";

const CarriersList = ({ data, isLoading }: CarriersProps) => {
  useDefineAppLocation("menu.settings.integrations.carriers");
  const translate = useTranslate();

  return (
    <AppViewLayout
      title={translate("menu.settings.integrations.carriers")}
      breadcrumbs={
        <Breadcrumbs>
          <Link underline="none" color="text.primary">
            {translate("carriers.description")}
          </Link>
        </Breadcrumbs>
      }
    >
      <CarriersListTable data={data} isLoading={isLoading} />
    </AppViewLayout>
  );
};

const CarrierCard = ({ data }: CarrierCardProps) => {
  return (
    <Card sx={{ width: "120px", height: "120px", padding: "10px" }}>
      <img
        key={data.id}
        src={data.logo}
        alt={data.name}
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    </Card>
  );
};

const CarriersListTable = ({ data, isLoading }: CarriersProps) => {
  if (isLoading) {
    return (
      <Stack spacing={2} direction="row">
        {[...Array(5)].map((...[, idx]) => (
          <Skeleton
            width={120}
            height={120}
            variant="rectangular"
            key={`${idx}-skeleton`}
          />
        ))}
      </Stack>
    );
  }

  return (
    <CarrierContainer>
      {data &&
        data.map((store: Integration) => (
          <CarrierCard key={store.id} data={store} />
        ))}
    </CarrierContainer>
  );
};

const CarrierContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  flexWrap: "wrap",
  gap: 20,
});

interface CarriersProps {
  data: Integration[] | undefined;
  isLoading: boolean;
}

interface CarrierCardProps {
  data: Integration;
}

export default CarriersList;
export { CarriersListTable };
