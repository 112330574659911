import { Translate } from "react-admin";
import { object, string } from "yup";
import { REGEX_PATTERNS } from "~/common/consts";

export const getAddressEditSchema = (translate: Translate) =>
  object({
    line2: string(),
    notes: string(),
    phone: string()
      .max(15, translate("shared.validation.max_dynamic", { value: 15 }))
      .required(translate("shared.validation.required")),
  });
