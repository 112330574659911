import {
  ensureInitialized,
  fetchAndActivate,
  getBoolean,
  getRemoteConfig,
} from "firebase/remote-config";
import { firebaseApp } from "./firebase";

const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 300000; // ensure remote config is refreshed within 5 minutes

// remote config defaults
remoteConfig.defaultConfig = {
  feature_signup_enabled: false,
  feature_label_details: false,
  feature_integrations: false,
  feature_invoices: false,
  feature_reports: false,
};

export const initializeRemoteConfig = async () => {
  const activated = await fetchAndActivate(remoteConfig);
  await ensureInitialized(remoteConfig);
};

export const isSignupEnabled = () =>
  getBoolean(remoteConfig, "feature_signup_enabled");
export const isLabelDetailsEnabled = () =>
  getBoolean(remoteConfig, "feature_label_details");
export const isIntegrationsEnabled = () =>
  getBoolean(remoteConfig, "feature_integrations");
export const isInvoicesEnabled = () =>
  getBoolean(remoteConfig, "feature_invoices");
export const isReportsEnabled = () =>
  getBoolean(remoteConfig, "feature_reports");
