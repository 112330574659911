import {
  ContactMerchantSupportParams,
  ContactSalesParams,
  DeactivateZoneParams,
  GetRatesParams,
  MerchantController,
  MerchantRequestQuoteParams,
} from "@swyft/domain";
import {
  CountryISO2,
  Currency,
  Merchant,
  RateCard,
  RateCardType,
  RateCardV2,
} from "@swyft/types";
import { FirebaseOptions } from "firebase/app";
import {
  CreateResult,
  DataProvider,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

import { placeholderRates } from "../placeholder";

/**
 * Data Provider for the "merchants" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): MerchantDataProvider => {
  const merchantController = new MerchantController(firebaseConfig);

  return {
    getList: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const { id } = params;
      const merchant = await merchantController.getOne({ id });

      return merchant;
    },
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    update: async (
      resource,
      params: UpdateParams<Merchant>,
    ): Promise<UpdateResult> =>
      await merchantController.update({
        id: String(params.id),
        data: params.data,
        previousData: params.previousData,
        meta: params.meta,
      }),
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: async (resource, params): Promise<CreateResult> => {
      const { data: merchantId } = await merchantController.create(params);

      return await merchantController.getOne({ id: merchantId });
    },
    delete: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    deleteMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getRates: async (
      resource: string,
      params: GetRatesParams,
    ): Promise<{
      data: { rateCardType: RateCardType; rateCard: RateCard | RateCardV2 };
    }> => {
      //if merchant is unactive use placeholderRates
      if (!params.isActive) {
        if (
          params.country === "CANADA" ||
          params.country === CountryISO2.CANADA
        ) {
          placeholderRates.rateCard.currency = Currency.CAD;
        }
        return { data: placeholderRates };
      }
      const { data } = await merchantController.getRates({
        merchantId: params.merchantId,
        zoneId: params.zoneId,
      });
      return { data };
    },
    getZonesList: async (
      resource: string,
      params: GetOneParams,
    ): Promise<GetOneResult> => {
      return await merchantController.getZones(params);
    },
    deactivateZone: async (resource: string, params: DeactivateZoneParams) => {
      try {
        await merchantController.deactivateZone(params);

        return { data: true };
      } catch (err) {
        return Promise.reject(err);
      }
    },
    requestZoneActivation: async (
      resource: string,
      params: MerchantRequestQuoteParams,
    ) => {
      try {
        await merchantController.requestZoneActivation(params);

        return { data: true };
      } catch (err) {
        return Promise.reject(err);
      }
    },
    contactSales: async (resource: string, params: ContactSalesParams) => {
      try {
        await merchantController.contactSales(params);

        return { data: true };
      } catch (err) {
        return Promise.reject(err);
      }
    },
    contactMerchantSupport: async (
      resource: string,
      params: ContactMerchantSupportParams,
    ) => {
      await merchantController.contactMerchantSupport(params);
      return { data: true };
    },
  };
};

export interface MerchantDataProvider extends DataProvider {
  getRates: (
    resource: string,
    params: GetRatesParams,
  ) => Promise<{
    data: { rateCardType: RateCardType; rateCard: RateCard | RateCardV2 };
  }>;
  getZonesList: (
    resource: string,
    params: GetOneParams,
  ) => Promise<GetOneResult>;
  deactivateZone: (
    resource: string,
    params: DeactivateZoneParams,
  ) => Promise<{ data: boolean }>;
  requestZoneActivation: (
    resource: string,
    params: MerchantRequestQuoteParams,
  ) => Promise<{ data: boolean }>;
  contactSales: (
    resource: string,
    params: ContactSalesParams,
  ) => Promise<{ data: boolean }>;
  contactMerchantSupport: (
    resource: string,
    params: ContactMerchantSupportParams,
  ) => Promise<{ data: boolean }>;
}
