import { useState } from "react";
import { useTranslate, useShowContext, useNotify } from "react-admin";
import {
  Box,
  LoadingButton,
  Button,
  Typography,
  HelpIcon,
  ReceiptIcon,
  DownloadIcon,
  Skeleton,
  Stack,
  LaunchIcon,
} from "@helo/ui";
import { StorageFileLocationType } from "@swyft/types";

import { exportPDF } from "~/common/fileExportHelper";
import { COMPANY_NAME } from "~/common/consts";
import InvoiceClaimDialog from "./InvoiceClaimDialog";
import InvoiceSupportDialog from "./InvoiceSupportDialog";
import { InvoiceStatus, ViewInvoice } from "~/services/data/types";
import { isInternalStorageDownloadLink } from "~/common/helpers";

const InvoiceActions = () => {
  const translate = useTranslate();
  const { record, isLoading } = useShowContext<ViewInvoice>();
  const notify = useNotify();
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [isInvoiceClaimOpen, setIsInvoiceClaimOpen] = useState(false);
  const [isInvoiceSupportOpen, setIsInvoiceSupportOpen] = useState(false);

  if (!record) {
    return null;
  }

  const downloadInvoicePdf = async () => {
    try {
      setIsDownloadingPdf(true);

      if (record.exportFileLocation.type !== StorageFileLocationType.URL) {
        throw new Error("Invoice PDF download not a url");
      }

      if (!isInternalStorageDownloadLink(record.exportFileLocation.url)) {
        throw new Error("Invoice PDF download url is not internal");
      }

      const fileName = `${COMPANY_NAME.toLowerCase()}-invoice--${
        record.label
      }.pdf`;

      await exportPDF(record.exportFileLocation.url, fileName, false);
    } catch (err) {
      notify("invoices.content.details.download.fail", { type: "error" });
      console.error(err);
    } finally {
      setIsDownloadingPdf(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ ml: "auto" }}>
        <Stack
          spacing={2}
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {[...Array(4)].map((...[, idx]) => (
            <Skeleton
              variant="rectangular"
              width={100}
              height={40}
              key={`${idx}-skeleton`}
            />
          ))}
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ ml: "auto" }}>
      {record.externalPaymentLink && record.status !== InvoiceStatus.PAID && (
        <Button
          variant="contained"
          startIcon={<LaunchIcon />}
          href={record.externalPaymentLink}
          target="_blank"
        >
          {translate("invoices.content.details.pay.external_link")}
        </Button>
      )}
      {record.exportFileLocation.type === StorageFileLocationType.URL &&
        record.exportFileLocation.url &&
        isInternalStorageDownloadLink(record.exportFileLocation.url) && (
          <LoadingButton
            loading={isDownloadingPdf}
            variant="contained"
            onClick={downloadInvoicePdf}
            sx={{ ml: 2 }}
          >
            <DownloadIcon
              sx={({ breakpoints }) => ({
                mr: 0,
                [breakpoints.up("lg")]: {
                  mr: 1,
                },
              })}
            ></DownloadIcon>
            <Typography
              variant="inherit"
              sx={({ breakpoints }) => ({
                display: "none",
                [breakpoints.up("lg")]: {
                  display: "inline-block",
                },
              })}
            >
              {translate("shared.action.download")}
            </Typography>
          </LoadingButton>
        )}
      <Button
        variant="outlined"
        onClick={() => {
          setIsInvoiceSupportOpen(true);
        }}
        sx={{ ml: 2 }}
      >
        <HelpIcon
          sx={({ breakpoints }) => ({
            mr: 0,
            [breakpoints.up("lg")]: {
              mr: 1,
            },
          })}
        ></HelpIcon>
        <Typography
          variant="inherit"
          sx={({ breakpoints }) => ({
            display: "none",
            [breakpoints.up("lg")]: {
              display: "inline-block",
            },
          })}
        >
          {translate("menu.help")}
        </Typography>
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          setIsInvoiceClaimOpen(true);
        }}
        sx={{ ml: 2 }}
      >
        <ReceiptIcon
          sx={({ breakpoints }) => ({
            mr: 0,
            [breakpoints.up("lg")]: {
              mr: 1,
            },
          })}
        ></ReceiptIcon>
        <Typography
          variant="inherit"
          sx={({ breakpoints }) => ({
            display: "none",
            [breakpoints.up("lg")]: {
              display: "inline-block",
            },
          })}
        >
          {translate("claim")}
        </Typography>
      </Button>
      <InvoiceClaimDialog
        isInvoiceClaimOpen={isInvoiceClaimOpen}
        setIsInvoiceClaimOpen={setIsInvoiceClaimOpen}
      />
      <InvoiceSupportDialog
        isOpen={isInvoiceSupportOpen}
        setIsOpen={setIsInvoiceSupportOpen}
      />
    </Box>
  );
};

export default InvoiceActions;
