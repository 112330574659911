import { Breadcrumbs, Link } from "@helo/ui";
import { useTranslate, useCreate, useNotify } from "react-admin";
import { useCanAccess } from "@react-admin/ra-rbac";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { UserRole } from "@swyft/types";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { Routes } from "~/config/Routes";
import { useGetIdentity } from "~/services/auth/hooks";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import { AppViewLayout } from "~/layouts/app";
import { AppResource } from "~/config/resources";
import LocationCreateForm, {
  CreatePickupLocationFormValues,
} from "./LocationCreateForm";

const LocationCreate = () => {
  const [create] = useCreate();
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();
  const { identity } = useGetIdentity();
  const { merchant } = useAuthenticatedContext();
  const { canAccess, isLoading: isAccessLoading } = useCanAccess({
    action: "create",
    resource: AppResource.Location,
  });

  const onSubmit = async (formValues: CreatePickupLocationFormValues) => {
    if (!merchant?.isActive) {
      notify("location.message.create.unactive_fail", {
        type: "warning",
      });
      return;
    }

    try {
      const data = {
        merchant: {
          id: identity?.organizationId,
        },
        notes: formValues.pickupNotes,
        name: formValues.name,
        contact: {
          address: {
            line1: formValues.addressLine1,
            line2: formValues.addressLine2,
            city: formValues.city,
            postalCode: formValues.postalCode,
            province: formValues.province,
            country: formValues.country,
          },
          phone: formValues.phone,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          title: formValues.jobTitle,
          email: formValues.email,
        },
      };

      await create(AppResource.Location, { data }, { returnPromise: true });
      navigate(Routes.Locations);
    } catch (err: any) {
      notify(
        `${translate("shared.message.submit.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning" },
      );
    }
  };

  return (
    <AppViewLayout
      variant="centered"
      title={translate("shared.content.create.title", {
        name: translate("resources.locations.name", { smart_count: 1 }),
      })}
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link
            color="inherit"
            underline="hover"
            component={RouterLink}
            to={Routes.Locations}
          >
            {translate("menu.settings.locations")}
          </Link>
          <Link color="text.primary" underline="none">
            {translate("shared.content.create.title", {
              name: translate("resources.locations.name", { smart_count: 1 }),
            })}
          </Link>
        </Breadcrumbs>
      }
    >
      {!canAccess ? (
        <GracefulFallback
          type="blocked-warning"
          details={translate("shared.message.create.fail_role_auth", {
            role: UserRole.ADMIN,
            name: translate("resources.locations.name", { smart_count: 1 }),
          })}
        />
      ) : (
        <LocationCreateForm onSubmit={onSubmit} />
      )}
    </AppViewLayout>
  );
};

export default LocationCreate;
