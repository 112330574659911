import { useTranslate, EditButton, useRecordContext } from "react-admin";
import { Breadcrumbs, Link, Stack, Skeleton, AddOutlinedIcon } from "@helo/ui";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Integration } from "@swyft/swyft-common";
import { Datagrid, IfCanAccess } from "@react-admin/ra-rbac";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { AppViewLayout } from "~/layouts/app";
import { AppResource } from "~/config/resources";

const TmsList = ({ data, isLoading }: TmsProps) => {
  useDefineAppLocation("menu.settings.integrations.tms");
  const translate = useTranslate();

  return (
    <AppViewLayout
      title={translate("menu.settings.integrations.tms")}
      breadcrumbs={
        <Breadcrumbs>
          <Link underline="none" color="text.primary">
            {translate("tms.description")}
          </Link>
        </Breadcrumbs>
      }
    >
      <TmsListTable data={data} isLoading={isLoading} />
    </AppViewLayout>
  );
};

const TmsListTable = ({ data, isLoading }: TmsProps) => {
  const ConfigButton = () => {
    const { merchant } = useAuthenticatedContext();
    const record = useRecordContext();
    const translate = useTranslate();

    return (
      <IfCanAccess action="edit">
        {record?.properties.length > 0 && (
          <EditButton
            resource={AppResource.Integrations}
            record={record}
            size="large"
            startIcon={<AddOutlinedIcon sx={{ marginRight: 0 }} />}
            label={translate("tms.btn_config")}
          />
        )}
      </IfCanAccess>
    );
  };

  const Image = () => {
    const record = useRecordContext();
    if (record?.logo !== undefined) {
      return (
        <img
          style={{ maxWidth: "100px" }}
          src={record.logo}
          alt={record.name}
        />
      );
    } else {
      return <></>;
    }
  };

  if (isLoading) {
    return (
      <Stack spacing={2} direction="column">
        {[...Array(3)].map((...[, idx]) => (
          <Skeleton height={70} variant="rectangular" key={`${idx}-skeleton`} />
        ))}
      </Stack>
    );
  }

  return (
    <Datagrid
      data={data}
      header={<></>}
      bulkActionButtons={false}
      hover={false}
      sx={{
        "& .MuiTableSortLabel-root": {
          display: "none",
        },
        "& .RaDatagrid-row": {
          pointerEvents: "none",
        },
        "& .RaDatagrid-rowCell": {
          height: "160px",
        },
        "& .RaDatagrid-row .RaDatagrid-rowCell:nth-of-type(2)": {
          textAlign: "right",
        },
        "& .RaDatagrid-row a": {
          pointerEvents: "initial",
        },
      }}
    >
      <Image />
      <ConfigButton />
    </Datagrid>
  );
};

interface TmsProps {
  data: Integration[] | undefined;
  isLoading: boolean;
}

export default TmsList;
