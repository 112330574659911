import { LabelCreationMethod } from "@swyft/swyft-common";
import moment from "moment";
import * as FirestoreService from "~/services/firestore";
import { useTranslate, useNotify } from "react-admin";

import CreateLabelForm, {
  CreateSingleLabelFormValues,
} from "~/features/shipments/components/CreateSingleLabelForm";
import { useMerchantOnboardingContext } from "~/components/MerchantOnboardingContext";
import { validateDims } from "~/common/labelHelpers";
import { exportPDF } from "~/common/fileExportHelper";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { COMPANY_NAME, DATE_FORMATS } from "~/common/consts";
import { useLabelDataProvider } from "~/services/data/shipment";

const ShipmentsStep = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const { goToNextStep, markStepAsComplete, currentStep } =
    useMerchantOnboardingContext();
  const { merchant } = useAuthenticatedContext();
  const [resource, dataProvider] = useLabelDataProvider();

  const onSubmit = async (values: CreateSingleLabelFormValues) => {
    //REVIEW: current single label create does not pass on the pickuplocation it uses the first in the list
    //REVIEW: current single label create does not pass set label as test label
    //REVIEW: current label creation requires dims to be string if you  omit shipdate
    //REVIEW: current label creation not assign rate id to label
    try {
      const createLabelRequest: any = {
        firstName: values.firstName,
        lastName: values.lastName,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        postalCode: values.postalCode,
        province: values.province,
        country: values.country,
        phoneNumber: values.phoneNumber,
        email: values.email,
        orderNumber: values.orderNumber,
        notes: values.notes,
        dims: {
          length: values.length?.toString(),
          width: values.width?.toString(),
          height: values.height?.toString(),
          weight: values.weight?.toString(),
        },
        signatureRequired: values.signatureRequired,
        creationMethod: LabelCreationMethod.MERCHANT_DASHBOARD,
        isTest: values.isTest,
        rateId: values.rate,
      };

      //Validate supported dims
      validateDims([createLabelRequest]);

      //Create single label
      const labelId = await FirestoreService.createLabel(createLabelRequest);
      const label = await FirestoreService.getLabelByMerchantIdAndLabelId(
        merchant?.id ?? "",
        labelId,
      );

      // prepare and print label pdf
      const {
        destination: { firstName, lastName },
        shipDate,
      } = label;

      const formattedDate = moment(shipDate).format(DATE_FORMATS.moment.export);
      const fileName = `${COMPANY_NAME.toLowerCase()}-label--${formattedDate}-${firstName}-${lastName}.pdf`;

      const { data } = await dataProvider.getPrintPdfs(resource, {
        labelIds: [labelId],
      });
      await exportPDF(data, fileName);
      notify("shipments.message.create.ok", {
        type: "success",
        autoHideDuration: 2000,
      });
    } catch (err: any) {
      console.error(err);
      notify(
        `${translate("shipments.message.create.fail")} ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning", autoHideDuration: 2000 },
      );
    } finally {
      try {
        //TODO: Move to initial try block once label creation in unblocked.
        await markStepAsComplete(currentStep.id);
        goToNextStep();
      } catch (err: any) {
        console.error(err);
        notify(`${err?.message ?? JSON.stringify(err)}`, {
          type: "warning",
          autoHideDuration: 2000,
        });
      }
    }
  };

  return (
    // TEST: this is WIP
    <CreateLabelForm isTest={true} onSubmit={onSubmit} />
  );
};

export default ShipmentsStep;
