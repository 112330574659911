"use strict";
exports.__esModule = true;
exports.DSPService = exports.DSPSoftware = exports.VehicleType = void 0;
/**
 * Vehicle our drivers use (inferred from onfleet)
 * See @onfleet/node-onfleet/Resources/Workers for more details
 */
var VehicleType;
(function (VehicleType) {
    VehicleType["CAR"] = "CAR";
    VehicleType["TRUCK"] = "TRUCK";
    VehicleType["UNKNOWN"] = "UNKNOWN";
})(VehicleType = exports.VehicleType || (exports.VehicleType = {}));
var DSPSoftware;
(function (DSPSoftware) {
    DSPSoftware["XCELERATOR"] = "Xcelerator";
    DSPSoftware["CXT"] = "CXT";
    DSPSoftware["ECOURIER"] = "Ecourier";
    DSPSoftware["OTHER"] = "Other";
})(DSPSoftware = exports.DSPSoftware || (exports.DSPSoftware = {}));
var DSPService;
(function (DSPService) {
    DSPService["PICKUP"] = "PICKUP";
    DSPService["SORT"] = "SORT";
    DSPService["DELIVERY"] = "DELIVERY";
    DSPService["STORAGE"] = "STORAGE";
    DSPService["MIDMILE"] = "MIDMILE";
})(DSPService = exports.DSPService || (exports.DSPService = {}));
