import {
  Currency,
  PackageSize,
  RateCard,
  RateCardType,
  RateCardV2,
} from "@swyft/types";

export const placeholderRates: {
  rateCard: RateCard | RateCardV2;
  rateCardType: RateCardType;
} = {
  rateCardType: RateCardType.rateCard,
  rateCard: {
    currency: Currency.USD,
    defaultPackageType: PackageSize.SMALL,
    allowedDeliveryAttempts: 2,
    redeliveryRate: {
      SMALL: 100,
    },
    services: {
      SAMEDAY: {
        SMALL: 100,
      },
      NEXTDAY: {
        SMALL: 100,
      },
    },
    addOns: {
      ADDRESS_CORRECTION: 300,
      CHECK_ID: 100,
      RELABELLING_FEE: 200,
      SIGNATURE: 300,
      WAITING_FEE: 5000,
      XL_PACKAGE: 500,
    },
  },
};
