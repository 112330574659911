import { useGetList } from "react-admin";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { IntegrationType } from "@swyft/swyft-common";
import { useLocation } from "react-router-dom";
import { AppResource } from "~/config/resources";
import StoresList from "./StoresList";
import CarriersList from "./CarriersList";
import TmsList from "./TmsList";

const IntegrationsList = () => {
  const location = useLocation();
  const { merchant } = useAuthenticatedContext();
  const integrationParam = location.pathname.replace("/", "").toUpperCase();

  const currIntegration = Object.keys(IntegrationType).find((type) => {
    return type === integrationParam;
  });

  const { data, isLoading } = useGetList(AppResource.Integrations, {
    filter: {
      org: {
        id: merchant?.id,
      },
      integrationType: currIntegration,
    },
  });

  if (currIntegration === IntegrationType.TMS) {
    return <TmsList data={data} isLoading={isLoading} />;
  } else if (currIntegration === IntegrationType.STORES) {
    return <StoresList data={data} isLoading={isLoading} />;
  } else {
    return <CarriersList data={data} isLoading={isLoading} />;
  }
};

export default IntegrationsList;
