import { useTranslate, useNotify, useCreate } from "react-admin";

import LocationCreateForm, {
  CreatePickupLocationFormValues,
} from "~/features/locations/LocationCreateForm";
import { useMerchantOnboardingContext } from "~/components/MerchantOnboardingContext";
import { AppResource } from "~/config/resources";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";

const PickupLocationConfigStep = () => {
  const { goToNextStep, markStepAsComplete, currentStep } =
    useMerchantOnboardingContext();
  const translate = useTranslate();
  const notify = useNotify();
  const { merchant, identity } = useAuthenticatedContext();
  const [create] = useCreate();

  const onSubmit = async (formValues: CreatePickupLocationFormValues) => {
    try {
      const data = {
        merchant: {
          id: identity?.organizationId ?? merchant?.id,
        },
        notes: formValues.pickupNotes,
        name: formValues.name,
        contact: {
          address: {
            line1: formValues.addressLine1,
            line2: formValues.addressLine2,
            city: formValues.city,
            postalCode: formValues.postalCode,
            province: formValues.province,
            country: formValues.country,
          },
          phone: formValues.phone,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          title: formValues.jobTitle,
          email: formValues.email,
        },
      };

      await create(AppResource.Location, { data }, { returnPromise: true });
      await markStepAsComplete(currentStep.id);
      goToNextStep();
    } catch (err: any) {
      notify(
        `${translate("shared.message.submit.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning", autoHideDuration: 2000 },
      );
    }
  };
  return <LocationCreateForm onSubmit={onSubmit} />;
};

export default PickupLocationConfigStep;
