import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import {
  useTranslate,
  List,
  Datagrid,
  TextField,
  FunctionField,
  useLocaleState,
  TextInput,
  SelectInput,
  DateInput,
} from "react-admin";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Card, Chip, InputAdornment, SearchIcon } from "@helo/ui";

import AppViewLayout from "~/layouts/app/AppViewLayout";
import ResourceEmptyState from "~/components/data/ResourceEmptyState";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import { ViewInvoice, InvoiceStatus } from "~/services/data/types";
import { currencyFormatter } from "~/common/helpers";
import { DATE_FORMATS, InvoiceStatusOptions } from "~/common/consts";

const InvoicesList = () => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const { merchant } = useAuthenticatedContext();
  const isOrgActive = merchant?.isActive;
  const sort = {
    field: "invoiceDate",
    order: "desc",
  };
  const filter = {
    orgId: merchant?.id,
    isVoid: false,
  };
  const filters = [
    <TextInput
      alwaysOn
      resettable
      source="label"
      placeholder={translate("shipments.content.list.filter.label")}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        minWidth: 250,
      }}
    />,
    <SelectInput
      alwaysOn
      source="status"
      choices={InvoiceStatusOptions.map((opt) => ({
        id: opt.value,
        name: opt.label,
      }))}
      emptyText="shared.content.none"
      sx={{
        my: 0,
      }}
    />,
    <DateInput alwaysOn source="invoiceDate" />,
    <DateInput alwaysOn source="dueDate" />,
  ];

  return (
    <AppViewLayout
      title="menu.invoices"
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("invoices.content.list.unactive_alert")}
          />
        )
      }
    >
      <List
        filters={filters}
        filter={filter}
        sort={sort}
        exporter={false}
        empty={<ResourceEmptyState />}
        component="div"
      >
        <Card>
          <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            sx={{
              "& .RaDatagrid-headerCell": {
                fontWeight: "bold",
              },
            }}
          >
            <TextField source="label" />
            <FunctionField
              source="invoiceDate"
              render={(record: ViewInvoice) => {
                if (!record.invoiceDate) {
                  return translate("shared.content.not_available");
                }

                return format(
                  utcToZonedTime(record.invoiceDate, "UTC"),
                  DATE_FORMATS.dateFns.displayFull,
                );
              }}
            />
            <FunctionField
              source="dueDate"
              render={(record: ViewInvoice) => {
                if (!record.dueDate) {
                  return translate("shared.content.not_available");
                }

                return format(
                  utcToZonedTime(record.dueDate, "UTC"),
                  DATE_FORMATS.dateFns.displayFull,
                );
              }}
            />
            <FunctionField
              source="totalAmount"
              render={(record: ViewInvoice) => {
                if (!record.dueDate) {
                  return translate("shared.content.not_available");
                }

                return currencyFormatter(
                  locale,
                  record.totalAmount,
                  record.currency,
                  1,
                );
              }}
            />
            <FunctionField
              source="balanceAmount"
              render={(record: ViewInvoice) => {
                if (!record.dueDate) {
                  return translate("shared.content.not_available");
                }

                return currencyFormatter(
                  locale,
                  record.balanceAmount,
                  record.currency,
                  1,
                );
              }}
            />
            <FunctionField
              source="status"
              render={(record: ViewInvoice) => {
                if (!record.status) {
                  return translate("shared.content.not_available");
                }

                return (
                  <Chip
                    label={translate(
                      `invoices.content.status.${record.status}`,
                    )}
                    variant="filled"
                    size="small"
                    color="neutral"
                    sx={({ palette }) => {
                      const bgColor =
                        record.status === InvoiceStatus.INVOICED
                          ? `${palette.primary.main}`
                          : record.status === InvoiceStatus.PAID
                          ? `${palette.success.main}`
                          : `${palette.error.main}`;

                      const textColor =
                        record.status === InvoiceStatus.INVOICED
                          ? `${palette.primary.contrastText}`
                          : record.status === InvoiceStatus.PAID
                          ? `${palette.success.darker}`
                          : `${palette.error.contrastText}`;

                      return {
                        backgroundColor: bgColor,
                        color: textColor,
                      };
                    }}
                  />
                );
              }}
            />
          </Datagrid>
        </Card>
      </List>
    </AppViewLayout>
  );
};

export default InvoicesList;
