import { Typography } from "@helo/ui";
import { Currency as CurrencyEnum } from "@swyft/types";
import { useLocaleState, useTranslate } from "react-admin";
import { currencyFormatter } from "~/common/helpers";

const Currency = ({ amount, currency, divider = 100 }: CurrencyProps) => {
  const [locale] = useLocaleState();
  const translate = useTranslate();

  if (!amount) {
    return (
      <Typography variant="inherit">
        {translate("shared.content.not_available")}
      </Typography>
    );
  }

  return (
    <Typography variant="inherit">
      {currencyFormatter(locale, amount, currency, divider)}
    </Typography>
  );
};

interface CurrencyProps {
  amount?: number;
  divider?: number;
  currency: CurrencyEnum;
  displayCurrency?: CurrencyEnum;
}

export default Currency;
