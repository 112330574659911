import { Routes } from "~/config/Routes";
import { Box, Button, Grid, styled, Typography, DoneIcon } from "@helo/ui";
import { useNavigate } from "react-router-dom";
import { useTranslate, useNotify } from "react-admin";

import { useMerchantOnboardingContext } from "~/components/MerchantOnboardingContext";

const FinishStep = () => {
  const options: FinishOption[] = [
    {
      option: "ecommerce",
      url: Routes.Stores,
    },
    {
      option: "pickupLocation",
      url: Routes.Locations,
    },
    {
      option: "labelSingle",
      url: `${Routes.Shipments}/create`,
    },
    {
      option: "labelApi",
      url: Routes.Api,
    },
    {
      option: "labelBulk",
      url: `${Routes.Shipments}/create`,
    },
  ];
  const finishOption: FinishOption = {
    option: "dashboard",
    url: Routes.Dashboard,
  };
  return <OptionLinks options={options} finalOption={finishOption} />;
};

export default FinishStep;

const OptionLinks = ({
  options,
  finalOption,
}: {
  options: FinishOption[];
  finalOption: FinishOption;
}) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const { markStepAsComplete, currentStep, currentStepIdx, steps } =
    useMerchantOnboardingContext();

  const onOptionClick = async (option: FinishOption) => {
    try {
      await markStepAsComplete(currentStep.id);
      notify(translate("onboarding.finish.message.complete"), {
        type: "success",
      });
      //if create label via csv open correct tab
      option.option === "labelCsv"
        ? navigate(option.url, { state: { defaultActiveKey: "2" } })
        : navigate(option.url);
    } catch (err: any) {
      notify(
        `${translate("onboarding.finish.message.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning" },
      );
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <OptionGrid gap={2}>
        {options.map((option) => {
          return (
            <Button
              key={`finish-onboarding-options-${option.option}`}
              variant="outlined"
              size="large"
              sx={{ textTransform: "none" }}
              onClick={async () => {
                await onOptionClick(option);
              }}
            >
              <Typography variant="subtitle1">
                {translate(`onboarding.finish.options.${option.option}`)}
              </Typography>
            </Button>
          );
        })}
      </OptionGrid>
      {steps.length === currentStepIdx + 1 ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            size="large"
            sx={{ textTransform: "none" }}
            startIcon={<DoneIcon />}
            onClick={async () => {
              await onOptionClick(finalOption);
            }}
          >
            <Typography variant="subtitle1">
              {translate(`onboarding.finish.options.${finalOption.option}`)}
            </Typography>
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

const OptionGrid = styled(Grid)(({ theme }) => ({
  maxWidth: "500px",
  display: "grid",
  gridTemplateColumns: "1fr",
}));

interface FinishOption {
  option: string;
  url: string;
}
