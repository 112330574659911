"use strict";
exports.__esModule = true;
exports.CostBreakdownType = exports.CostBreakdownSource = exports.DeliveryAttemptDetails = exports.ActivityType = void 0;
var ActivityType;
(function (ActivityType) {
    ActivityType["TRACKING"] = "TRACKING";
    ActivityType["SCAN"] = "SCAN";
    ActivityType["COMMS"] = "COMMS";
    ActivityType["DELIVERY_ATTEMPTS"] = "DELIVERY_ATTEMPTS";
})(ActivityType = exports.ActivityType || (exports.ActivityType = {}));
var DeliveryAttemptDetails;
(function (DeliveryAttemptDetails) {
    DeliveryAttemptDetails["SUCCESS"] = "SUCCESS";
    DeliveryAttemptDetails["FAILURE"] = "FAILURE";
    DeliveryAttemptDetails["NO_ACCESS_COMMERCIAL_ADDRESS"] = "NO_ACCESS_COMMERCIAL_ADDRESS";
    DeliveryAttemptDetails["NO_ACCESS_RESIDENTIAL_ADDRESS"] = "NO_ACCESS_RESIDENTIAL_ADDRESS";
    DeliveryAttemptDetails["UNABLE_TO_COLLECT_SIGNATURE"] = "UNABLE_TO_COLLECT_SIGNATURE";
    DeliveryAttemptDetails["CUSTOMER_REFUSED_DELIVERY"] = "CUSTOMER_REFUSED_DELIVERY";
    DeliveryAttemptDetails["INCORRECT_CUSTOMER_ADDRESS"] = "INCORRECT_CUSTOMER_ADDRESS";
    DeliveryAttemptDetails["MISSING_PACKAGE"] = "MISSING_PACKAGE";
    DeliveryAttemptDetails["DRIVER_ACCIDENT"] = "DRIVER_ACCIDENT";
    DeliveryAttemptDetails["ITEM_DAMAGED"] = "ITEM_DAMAGED";
    DeliveryAttemptDetails["PAST_DELIVERY_WINDOW"] = "PAST_DELIVERY_WINDOW";
    DeliveryAttemptDetails["NO_SPACE_IN_CAR"] = "NO_SPACE_IN_CAR";
    DeliveryAttemptDetails["OTHER"] = "OTHER";
})(DeliveryAttemptDetails = exports.DeliveryAttemptDetails || (exports.DeliveryAttemptDetails = {}));
var CostBreakdownSource;
(function (CostBreakdownSource) {
    CostBreakdownSource["RATE_CARD_V1"] = "RATE_CARD_V1";
    CostBreakdownSource["RATE_CARD_V2"] = "RATE_CARD_V2";
})(CostBreakdownSource = exports.CostBreakdownSource || (exports.CostBreakdownSource = {}));
var CostBreakdownType;
(function (CostBreakdownType) {
    CostBreakdownType["BASE_RATE"] = "BASE_RATE";
    CostBreakdownType["REDELIVERY_FEE"] = "REDELIVERY_FEE";
    CostBreakdownType["ADDON_FEE"] = "ADDON_FEE";
})(CostBreakdownType = exports.CostBreakdownType || (exports.CostBreakdownType = {}));
