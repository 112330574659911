import { Translate } from "react-admin";
import { object, string } from "yup";
import { CountryISO2 } from "@swyft/types";
import { DSPSoftware, FleetSize } from "~/constants/form";

export const getDspSchema = (translate: Translate) =>
  object({
    country: string()
      .oneOf(
        Object.values(CountryISO2),
        translate("shared.validation.required_select"),
      )
      .nullable(),
    companyName: string().min(1, translate("shared.validation.required")),
    jobTitle: string().min(1, translate("shared.validation.required")),
    drivers: string()
      .oneOf(Object.keys(FleetSize), translate("shared.validation.required"))
      .nullable(),
    software: string()
      .oneOf(Object.keys(DSPSoftware), translate("shared.validation.required"))
      .nullable(),
  });
