import {
  Box,
  CloseIcon,
  Dialog,
  FormLabel,
  Grid,
  IconButton,
  LoadingButton,
  styled,
  TextField,
  Typography,
} from "@helo/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ContactMerchantSupportParams,
  SupportRequestType,
} from "@swyft/domain";
import { Currency, LabelClaimReason } from "@swyft/types";
import { nanoid } from "nanoid";
import React from "react";
import {
  useEditContext,
  useLocaleState,
  useNotify,
  useTranslate,
} from "react-admin";
import { SubmitHandler, useForm } from "react-hook-form";

import { STORAGE_BUCKET_FOLDERS } from "~/common/consts";
import { currencyFormatter, getOptions } from "~/common/helpers";
import { LabelClaimRequestValidationSchema } from "~/common/validators/shipment/LabelClaimSchema";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useNotifyDialog } from "~/components/feedback/GlobalAlerts";
import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import CurrencyInput, { ValueType } from "~/components/inputs/CurrencyInput";
import FileDrop, { FileDropFile } from "~/components/inputs/FileDrop";
import { useMerchantDataProvider } from "~/services/data/merchant";

interface ClaimFormValues {
  trackingNumber: string;
  amount: number;
  fileURL: FileDropFile;
  description: string;
  reason: string;
}

interface ClaimProps {
  claimProps: { isOpen: boolean; currency: Currency | "" };
  setClaimProps: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; currency: Currency | "" }>
  >;
}

const ClaimDialog = ({ claimProps, setClaimProps }: ClaimProps) => {
  const [resource, dataProvider] = useMerchantDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const notifyDialog = useNotifyDialog();
  const [locale] = useLocaleState();
  const { record } = useEditContext();
  const { merchant } = useAuthenticatedContext();
  const merchantId = merchant?.id;
  const acceptedFileTypes = {
    "image/png": [".png"],
    "image/jpeg": [".jpeg, .jpg"],
    "application/pdf": [".pdf"],
  }; // as specified in https://react-dropzone.netlify.app/#section-accepting-specific-file-types
  const formResolver = yupResolver(LabelClaimRequestValidationSchema);
  const LabelClaimReasonOptions = getOptions(LabelClaimReason, {
    capitalize: false,
    getValue: ([key, value]: [string, any]) =>
      translate(`shipments.content.details.claim.reasons.${key}`),
    getLabel: ([key, value]: [string, any]) =>
      translate(`shipments.content.details.claim.reasons.${key}`),
  });

  const { register, handleSubmit, formState, reset, control } =
    useForm<ClaimFormValues>({ resolver: formResolver });

  //resets and closes form
  const onClose = () => {
    reset();
    setClaimProps({ isOpen: false, currency: "" });
  };

  //Send message to support
  const onSubmit: SubmitHandler<ClaimFormValues> = async (formvalues) => {
    if (merchantId) {
      const params: ContactMerchantSupportParams = {
        requestType: SupportRequestType.LABEL_CLAIM,
        merchantId: merchantId,
        message: formvalues.description,
        reason: formvalues.reason,
        trackingNumber: formvalues.trackingNumber,
        packageValue: currencyFormatter(
          locale,
          formvalues.amount,
          claimProps.currency,
          1,
        ),
        productReceiptFileURL: formvalues.fileURL?.storagePreviewURL ?? "",
      };
      //on successful close and reset form and trigger success modal
      try {
        await dataProvider.contactMerchantSupport(resource, params);
        onClose();
        notifyDialog({
          title: translate("shared.message.submit.ok"),
          content: translate("shipments.content.details.claim.response"),
          isOpen: true,
        });
      } catch (err: unknown) {
        notify(
          translate(`${translate("shared.message.submit.fail")} (${err})`),
          {
            type: "error",
          },
        );
      }
    }
  };
  return (
    <Dialog onClose={onClose} open={claimProps.isOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ p: 2 }}
          justifyContent="flex-end"
        >
          <CloseIconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </CloseIconButton>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 900, textTransform: "capitalize" }}
            >
              {translate("shipments.content.details.claim.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={translate("resources.shipments.fields.trackingNumber")}
              placeholder={translate(
                "recources.shipments.fields.trackingNumber",
              )}
              {...register("trackingNumber")}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              value={record.trackingNumber}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CurrencyInput
              label={translate("shipments.content.details.claim.amount")}
              name="amount"
              control={control as any}
              prefix={claimProps.currency === Currency.USD ? "$ " : "CA$ "}
              valueType={ValueType.floatValue}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              control={control}
              options={LabelClaimReasonOptions}
              name="reason"
              error={Boolean(formState?.errors?.reason)}
              helperText={
                Boolean(formState?.errors?.reason)
                  ? translate("shared.message.error.required")
                  : ""
              }
              label={translate("shipments.content.details.claim.reason")}
              placeholder={translate(
                "shipments.content.details.claim.select_reason",
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={translate("shipments.content.details.claim.description")}
              placeholder={translate(
                "shipments.content.details.claim.description",
              )}
              {...register("description")}
              multiline
              rows={6}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ pl: 2, pb: 1 }}>
              <FormLabel>
                {translate("shipments.content.details.claim.receipt")}
              </FormLabel>
            </Box>
            <FileDrop
              accept={acceptedFileTypes}
              name={"fileURL"}
              control={control as any}
              getUploadToStoragePath={(file: File) =>
                `${STORAGE_BUCKET_FOLDERS.label_claim}/${nanoid()}/${file.name}`
              }
            />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              size="medium"
              variant="contained"
              disableElevation
              loading={formState?.isSubmitting}
            >
              {translate("shared.action.submit")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: 16,
  height: 24,
  width: 24,
  position: "absolute",
  right: 16,
  top: 16,
  backgroundColor: theme.palette.neutralScale[500],
  color: theme.palette.primary.contrastText,
  ":hover": { color: theme.palette.neutralScale[400] },
}));

export default ClaimDialog;
