import { useTranslate } from "react-admin";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import inactiveDashboardImg from "assets/images/inactive-dashboard.png";
import DataStudioEmbed from "~/components/data/DataStudioEmbed";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import { AppViewLayout } from "~/layouts/app";

const SimpleDashboard = () => {
  const translate = useTranslate();
  const { merchant } = useAuthenticatedContext();
  const isOrgActive = merchant?.isActive;
  const merchantId = merchant?.id;

  return (
    <AppViewLayout
      title="pages.dashboard.title"
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("dashboard.messaging.unactive_alert")}
          />
        )
      }
    >
      <DataStudioEmbed
        isOrgActive={isOrgActive}
        organizationId={merchantId}
        title={translate("menu.dashboard")}
        placeholderImgSrc={inactiveDashboardImg}
      />
    </AppViewLayout>
  );
};

export default SimpleDashboard;
