import {
  Grid,
  styled,
  TextField,
  LoadingButton,
  Typography,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Card,
  Button,
} from "@helo/ui";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNotify, useTranslate } from "react-admin";
import { useState, useEffect, useMemo } from "react";
import {
  CA_PROVINCES,
  US_STATES,
  CountryISO2,
  PickupLocation,
  OrganizationType,
} from "@swyft/types";

import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import { CountryOptions } from "~/common/consts";
import { getLabelCreateSchema } from "~/common/validators/shipment/LabelCreateSchema";
import { useLocationDataProvider } from "~/services/data/location";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import RatesRadioGroup from "./RatesRadioGroup";
import { getPickupLocationOptions, SelectOption } from "~/common/helpers";

const CreateLabelForm = ({ isTest, onSubmit }: CreateSingleLabelFormProps) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { merchant } = useAuthenticatedContext();
  const [locationResource, locationDataProvider] = useLocationDataProvider();

  //states
  const [showRates, setShowRates] = useState<boolean>(false);
  const [pickupLocations, setPickupLocations] = useState<PickupLocation[]>(
    [] as PickupLocation[],
  );
  const [pickupLocationOptions, setPickupLocationOptions] = useState<
    SelectOption[]
  >([] as SelectOption[]);

  //default form values
  const defaultValues: CreateSingleLabelFormValues = {
    pickupLocation: null,
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postalCode: "",
    province: null,
    country: null,
    email: "",
    countryCode: "+1",
    phoneNumber: "",
    orderNumber: "",
    notes: "",
    weight: null,
    length: null,
    width: null,
    height: null,
    signatureRequired: false,
    rate: null,
    isTest,
  };

  const caProvinceOptions = useMemo(() => {
    return CA_PROVINCES.map((province) => {
      return {
        value: province,
        label: translate(`shared.label.ca_provinces.${province}`),
      };
    });
  }, []);

  const usStateOptions = useMemo(() => {
    return US_STATES.map((state) => {
      return {
        value: state,
        label: translate(`shared.label.us_states.${state}`),
      };
    });
  }, []);

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(getLabelCreateSchema(translate)),
    mode: "onChange",
  });

  //if country changes after a state or provinces has been selected clear province field
  const countryValue = form.watch("country");

  useEffect(() => {
    form.resetField("province");
  }, [countryValue]);

  useEffect(() => {
    //on mount fetch merchant's pickup locations
    const fetchPickupLocations = async (id: string) => {
      try {
        const { data } = await locationDataProvider.getList(locationResource, {
          filter: {
            org: {
              id,
              type: OrganizationType.Merchant,
            },
          },
          pagination: { page: 1, perPage: 100 },
          sort: { field: "createdAt", order: "ASC" },
        });
        setPickupLocations(data);
        setPickupLocationOptions(getPickupLocationOptions(data));
      } catch (err: any) {
        console.error(err);
        notify(
          `${translate("No pickup Locations exist for this merchant")} ${
            err?.message ?? JSON.stringify(err)
          }`,
          { type: "warning", autoHideDuration: 2000 },
        );
      }
    };
    if (merchant?.id) {
      fetchPickupLocations(merchant?.id);
    }
  }, [merchant]);

  useEffect(() => {
    //once pickup locations are available select first pickup location by default
    if (pickupLocationOptions.length) {
      form.setValue("pickupLocation", pickupLocationOptions[0].value);
    }
  }, [pickupLocationOptions]);

  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <SubFormContainer>
          <Typography variant="subtitle1" fontWeight={600}>
            {translate("shipments.content.create.form.from")}
          </Typography>
          <ControlledAutocomplete
            control={form.control}
            options={pickupLocationOptions}
            name="pickupLocation"
            rules={{ required: true }}
            error={Boolean(form.formState?.errors?.pickupLocation)}
            helperText={form.formState?.errors?.pickupLocation?.message?.toString()}
            label={translate("shipments.content.create.form.pickup_location")}
            disabled={showRates}
          />
          <Typography variant="subtitle1" fontWeight={600}>
            {translate("shipments.content.create.form.to")}
          </Typography>
          <DualColumns>
            <TextField
              label={translate("shipments.content.create.form.firstName.label")}
              error={Boolean(form.formState?.errors?.firstName)}
              helperText={form.formState?.errors?.firstName?.message}
              {...form.register("firstName")}
            />
            <TextField
              label={translate("shipments.content.create.form.lastName.label")}
              error={Boolean(form.formState?.errors?.lastName)}
              helperText={form.formState?.errors?.lastName?.message}
              {...form.register("lastName")}
            />
          </DualColumns>
          <DualColumns>
            <TextField
              label={translate(
                "shipments.content.create.form.address.line1.label",
              )}
              error={Boolean(form.formState?.errors?.addressLine1)}
              helperText={form.formState?.errors?.addressLine1?.message}
              {...form.register("addressLine1")}
              disabled={showRates}
            />
            <TextField
              label={translate(
                "shipments.content.create.form.address.line2.label",
              )}
              error={Boolean(form.formState?.errors?.addressLine2)}
              helperText={form.formState?.errors?.addressLine2?.message}
              {...form.register("addressLine2")}
            />
          </DualColumns>
          <DualColumns>
            <DualColumns>
              <TextField
                label={translate("shipments.content.create.form.city.label")}
                error={Boolean(form.formState?.errors?.city)}
                helperText={form.formState?.errors?.city?.message}
                {...form.register("city")}
                disabled={showRates}
              />
              <TextField
                label={translate("shipments.content.create.form.postal.label")}
                error={Boolean(form.formState?.errors?.postalCode)}
                helperText={form.formState?.errors?.postalCode?.message}
                {...form.register("postalCode")}
                disabled={showRates}
              />
            </DualColumns>
            <DualColumns
              sx={({ breakpoints }) => ({
                [breakpoints.up("lg")]: {
                  gridTemplateColumns: `${
                    !!form.watch("country") ? "1fr 1fr" : "1fr"
                  }`,
                },
              })}
            >
              {!!form.watch("country") && (
                <ControlledAutocomplete
                  control={form.control}
                  options={
                    form.watch("country") === CountryISO2.USA
                      ? usStateOptions
                      : caProvinceOptions
                  }
                  name="province"
                  rules={{ required: true }}
                  error={Boolean(form.formState?.errors?.province)}
                  helperText={form.formState?.errors?.province?.message?.toString()}
                  label={translate(
                    "shipments.content.create.form.province.label",
                  )}
                  disabled={showRates}
                />
              )}
              <ControlledAutocomplete
                control={form.control}
                options={CountryOptions}
                name="country"
                rules={{ required: true }}
                error={Boolean(form.formState?.errors?.country)}
                helperText={form.formState?.errors?.country?.message?.toString()}
                label={translate("shipments.content.create.form.country.label")}
                disabled={showRates}
              />
            </DualColumns>
          </DualColumns>
          <DualColumns>
            <TextField
              label={translate("shipments.content.create.form.email.label")}
              error={Boolean(form.formState?.errors?.email)}
              helperText={form.formState?.errors?.email?.message}
              {...form.register("email")}
              type={"email"}
            />
            <PhoneContainer>
              <TextField
                label={translate("shared.label.address.countryCode")}
                type="tel"
                defaultValue={"+1"}
                name="countryCode"
                disabled
                error={Boolean(form.formState?.errors?.countryCode)}
                helperText={form.formState?.errors?.countryCode?.message}
              />
              <Tooltip
                title={translate(
                  "shipments.content.create.form.phoneNumber.tooltip",
                )}
                arrow
              >
                <TextField
                  label={translate(
                    "shipments.content.create.form.phoneNumber.label",
                  )}
                  error={Boolean(form.formState?.errors?.phoneNumber)}
                  helperText={form.formState?.errors?.phoneNumber?.message}
                  type="tel"
                  {...form.register("phoneNumber")}
                />
              </Tooltip>
            </PhoneContainer>
          </DualColumns>
          <Typography variant="subtitle1" fontWeight={600}>
            {translate("shipments.content.create.form.package_details")}
          </Typography>
          <Tooltip
            title={translate(
              "shipments.content.create.form.orderNumber.tooltip",
            )}
            arrow
          >
            <TextField
              label={translate(
                "shipments.content.create.form.orderNumber.label",
              )}
              {...form.register("orderNumber")}
            />
          </Tooltip>
          <Tooltip
            title={translate("shipments.content.create.form.notes.tooltip")}
            arrow
          >
            <TextField
              multiline
              rows={4}
              error={Boolean(form.formState?.errors?.notes)}
              helperText={form.formState?.errors?.notes?.message}
              label={translate("shipments.content.create.form.notes.label")}
              {...form.register("notes")}
            />
          </Tooltip>
          <DualColumns>
            <DualColumns>
              <TextField
                label={translate("shipments.content.create.form.weight.label")}
                error={Boolean(form.formState?.errors?.weight)}
                helperText={form.formState?.errors?.weight?.message}
                {...form.register("weight")}
                type="number"
                disabled={showRates}
              />
              <TextField
                label={translate("shipments.content.create.form.length.label")}
                error={Boolean(form.formState?.errors?.length)}
                helperText={form.formState?.errors?.length?.message}
                {...form.register("length")}
                type="number"
                disabled={showRates}
              />
            </DualColumns>
            <DualColumns>
              <TextField
                label={translate("shipments.content.create.form.width.label")}
                error={Boolean(form.formState?.errors?.width)}
                helperText={form.formState?.errors?.width?.message}
                {...form.register("width")}
                type="number"
                disabled={showRates}
              />
              <TextField
                label={translate("shipments.content.create.form.height.label")}
                error={Boolean(form.formState?.errors?.height)}
                helperText={form.formState?.errors?.height?.message}
                {...form.register("height")}
                type="number"
                disabled={showRates}
              />
            </DualColumns>
          </DualColumns>
          <Controller
            name="signatureRequired"
            control={form.control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                }
                label={translate(
                  "shipments.content.create.form.signature.label",
                )}
              />
            )}
          />
          {!showRates && (
            <ButtonContainer>
              <Button
                sx={{ width: "fit-content" }}
                onClick={async () => {
                  const isValid = await form.trigger([
                    "addressLine1",
                    "city",
                    "country",
                    "province",
                    "postalCode",
                    "pickupLocation",
                    "weight",
                    "width",
                    "length",
                    "height",
                  ]);
                  if (isValid) {
                    setShowRates(true);
                  }
                }}
                variant="contained"
                size="large"
                disableElevation
              >
                {translate("shipments.content.create.form.rate.fetch")}
              </Button>
            </ButtonContainer>
          )}
        </SubFormContainer>
        {showRates && (
          <Card
            sx={{
              p: 3,
              height: "fit-content",
              position: "sticky",
              top: "88px",
            }}
          >
            <Typography variant="subtitle1" fontWeight={600}>
              {translate("shipments.content.create.form.rate.select")}
            </Typography>
            <RatesRadioGroup
              originList={pickupLocations}
              name="rate"
              control={form.control}
            />
            <ButtonContainer>
              <Controller
                name="isTest"
                control={form.control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label={translate("shipments.content.create.form.test")}
                  />
                )}
              />
              <Grid
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  columnGap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    form.resetField("rate");
                    setShowRates(false);
                  }}
                >
                  {translate("shipments.content.create.form.edit")}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={form.formState?.isSubmitting}
                  size="large"
                  disableElevation
                  disabled={!form.watch("rate")}
                  sx={{ width: "fit-content" }}
                >
                  {translate("shipments.content.create.form.create")}
                </LoadingButton>
              </Grid>
            </ButtonContainer>
          </Card>
        )}
      </Form>
    </FormProvider>
  );
};

const Form = styled("form")({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: 20,
  placeContent: "center",
  maxWidth: "1500px",
});

const DualColumns = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  columnGap: 16,
  rowGap: 16,
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "1fr 1fr",
  },
}));

const PhoneContainer = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  columnGap: 16,
});

const ButtonContainer = styled(Grid)({
  display: "grid",
  gridTemplateRows: "auto",
  rowGap: 16,
});

const SubFormContainer = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "1fr",
  columnGap: 16,
  rowGap: 16,
});

interface CreateSingleLabelFormProps {
  isTest: boolean;
  onSubmit: (formValues: CreateSingleLabelFormValues) => Promise<void>;
}

export interface CreateSingleLabelFormValues {
  pickupLocation: string | null;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  province: string | null;
  country: string | null;
  email: string;
  countryCode: string;
  phoneNumber: string;
  orderNumber?: string;
  notes?: string;
  weight: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  signatureRequired: boolean;
  rate: string | null;
  isTest: boolean;
}

export default CreateLabelForm;
