import {
  useTranslate,
  useEditContext,
  Edit,
  useRedirect,
  useNotify,
} from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Link, Stack, Skeleton, styled, Box } from "@helo/ui";
import { Label, TrackingEventState, Coordinate } from "@swyft/types";
import { useEffect, useState } from "react";

import { useGetIdentity } from "~/services/auth/hooks";
import { AppViewLayout } from "~/layouts/app";
import LabelMap from "./components/LabelMap";
import LabelActivityLog from "./components/LabelActivityLog";
import LabelShipmentDetails from "./components/LabelShipmentDetails";
import ProofOfDelivery from "./components/ProofOfDelivery";
import LabelActions from "./components/LabelActions";
import LabelTimeline from "./components/LabelTimeline";
import { Routes } from "~/config/Routes";
import { getLabelStateColors } from "~/common/labelHelpers";
import { trackingEventStateAlias } from "~/common/consts";
import { useLocationDataProvider } from "~/services/data/location";

export const LabelsEdit = () => {
  const {
    identity,
    isLoading: isLoadingIdentity,
    error: errorIdentity,
  } = useGetIdentity();
  return (
    <Edit
      component="div"
      queryOptions={{
        meta: {
          org: {
            id: identity?.organizationId,
          },
        },
        enabled: !isLoadingIdentity && !errorIdentity,
      }}
      mutationOptions={{
        meta: {
          org: {
            id: identity?.organizationId,
          },
        },
      }}
      redirect={false}
      sx={{
        height: "100%",
        ["& .RaEdit-card"]: {
          height: "100%",
        },
        ["& .RaEdit-noActions"]: {
          height: "100%",
          margin: 0,
        },
      }}
    >
      <LabelDetailsLayout />
    </Edit>
  );
};

const LabelDetailsLayout = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { record, isLoading } = useEditContext<Label>();

  if (!record) {
    redirect(Routes.Shipments);
    return null;
  }

  const { bgColor: summaryBgColor, fontColor: summaryFontColor } =
    getLabelStateColors({
      state: record.state ?? TrackingEventState.PENDING,
    });

  if (isLoading) {
    return (
      <Stack
        spacing={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={"30%"}
          height={50}
          sx={{ alignSelf: "flex-start", ml: "10%", mt: 5 }}
        />
        {[...Array(2)].map((...[, idx]) => (
          <Skeleton
            variant="rectangular"
            width={"80%"}
            height={500}
            key={`${idx}-skeleton`}
          />
        ))}
      </Stack>
    );
  }
  return (
    <AppViewLayout
      title={"shipments.content.details.title"}
      titleFlair={<LabelActions />}
      breadcrumbs={
        <Breadcrumbs>
          <Link underline="none" color="inherit">
            {translate("menu.shipments.shipments")}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={Routes.Shipments}
          >
            {translate("menu.shipments.manage")}
          </Link>
          {record && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link underline="none" color="text.primary">
                {record.trackingNumber}
              </Link>
              <StatusChipSmall
                sx={{
                  backgroundColor: summaryBgColor,
                  color: summaryFontColor,
                  ml: 1,
                }}
              >
                {(
                  trackingEventStateAlias[record.state as TrackingEventState] ||
                  record.state
                )
                  .split("_")
                  .join(" ")}
              </StatusChipSmall>
            </Box>
          )}
        </Breadcrumbs>
      }
    >
      <LabelDetailsView />
    </AppViewLayout>
  );
};

const LabelDetailsView = () => {
  const { record } = useEditContext();
  const [locationResource, locationDataProvider] = useLocationDataProvider();
  const [originCoordinates, setOriginCoordinates] = useState<
    Coordinate | undefined
  >(record?.origin.validatedAddressV2?.geocode);
  const translate = useTranslate();
  const notify = useNotify();
  const { identity } = useGetIdentity();

  //If validated address does not exist on label origin, check for pickupLocationId
  //Retrieve validatedAddress from pickuplocation
  useEffect(() => {
    const getOrigin = async (id: string) => {
      try {
        const { data } = await locationDataProvider.getOne(locationResource, {
          id,
          meta: { org: { id: identity?.organizationId } },
        });
        if (data?.contact.validatedAddressV2?.geocode) {
          setOriginCoordinates(data?.contact.validatedAddressV2?.geocode);
        }
      } catch (err: any) {
        console.error(err);
        notify(
          `${translate("shipments.content.details.map.fail")} ${
            err?.message ?? JSON.stringify(err)
          }`,
          { type: "warning", autoHideDuration: 2000 },
        );
      }
    };

    if (
      !record?.origin.validatedAddressV2?.geocode &&
      record?.pickupLocationId
    ) {
      getOrigin(record?.pickupLocationId);
    }
  }, [record]);

  return (
    <Stack>
      <LabelTimeline />
      <LabelMap
        origin={originCoordinates}
        destination={record?.destination.validatedAddressV2?.geocode}
        sx={{ mb: 3 }}
      ></LabelMap>
      <LabelShipmentDetails />
      <LabelActivityLog />
      <ProofOfDelivery />
    </Stack>
  );
};

const StatusChipSmall = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: 5,
  padding: "5px 10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  backgroundColor: palette.neutral.main,
}));
