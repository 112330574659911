import { useRef, useEffect, useState } from "react";
import { render } from "react-dom";
import {
  useTranslate,
  useGetList,
  usePermissions,
  useNotify,
} from "react-admin";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { canAccess } from "@react-admin/ra-rbac";
import { useNavigate } from "react-router-dom";
import {
  styled,
  Breadcrumbs,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CloseIcon,
  IconButton,
  PlaceTwoTone,
} from "@helo/ui";
import { ZoneStatus } from "@swyft/types";
import { Marker } from "mapbox-gl";
// @ts-ignore
// eslint-disable-next-line
import mapboxgl, { Map } from "!mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./map_styles/mapbox.css";

import theme from "~/config/theme";
import {
  addClickEvent,
  onSelectEvent,
  addHoverEvent,
  mapOnLoad,
  paintZones,
  PaintStyles,
  marketZoneReset,
} from "./helper_functions/mapEvents";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useGlobalComponentContext } from "~/components/GlobalComponentContext";
import { useNotifyDialog } from "~/components/feedback/GlobalAlerts";
import ZonePopup from "./PopUp";
import ToolTip from "./Tooltip";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import { Routes } from "~/config/Routes";
import { AppViewLayout } from "~/layouts/app";
import { useMerchantDataProvider } from "~/services/data/merchant";
import Loading from "~/components/feedback/Loading";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

//types
export interface IPaintZones {
  reset: string;
  focus: string;
  hover: string;
}

export interface deactivateZoneRequest {
  merchantId: string;
  zoneId: string;
}

//consts
const initialLng = -98.5795;
const initialLat = 39.828175;
const initialZoom = 3.4;
//@ts-ignore
const inactiveZoneColor = theme.palette?.primary.main;
//@ts-ignore
const activeZoneColor = theme.palette?.success.main;
//@ts-ignore
const pendingZoneColor = theme.palette?.secondary.main;

const ZonesMap = () => {
  useDefineAppLocation("settings.coverage");
  const translate = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const { merchant } = useAuthenticatedContext();
  const merchantId = merchant?.id || "";
  const isOrgActive = merchant?.isActive;
  const [resource, dataProvider] = useMerchantDataProvider();
  const { permissions } = usePermissions();
  const canRequestQuote = canAccess({
    permissions,
    action: "write",
    resource: "zones",
  });
  const { data: zonesData, isLoading } = useGetList("zones", {
    filter: {},
    meta: {
      fetchGeoJSON: true,
      merchantId,
    },
  });
  const { data: marketsData, isLoading: isLoadingMarkets } = useGetList(
    "markets",
    {
      filter: {},
      meta: {
        fetchGeoJSON: true,
        merchantId,
      },
    },
  );
  const notifyDialog = useNotifyDialog();
  const managePickupLocation = () => {
    navigate(Routes.Locations);
  };

  //triggers deactivateZone callable
  const deactivateZoneRequestHandler = async ({
    merchantId,
    zoneId,
  }: deactivateZoneRequest) => {
    try {
      //close popup and open full page loading modal
      map.current.fire("closePopup");
      setIsLoadingModalOpen(true);
      await dataProvider.deactivateZone(resource, {
        zoneId,
        merchantId,
      });
      notifyDialog({
        title: translate(
          "maps.popup.requests.deactivate.message.success.title",
        ),
        content: translate(
          "maps.popup.requests.deactivate.message.success.description",
        ),
        isOpen: true,
      });
    } catch (err: unknown) {
      notify(`${translate("maps.popup.requests.deactivate.message.error")}`, {
        type: "warning",
      });
    } finally {
      setIsLoadingModalOpen(false);
    }
  };

  //states
  const [selectMarket, setSelectMarket] = useState<string>("");
  const [currentMarket, setCurrentMarket] = useState<IPaintZones | undefined>({
    reset: "",
    focus: "",
    hover: "",
  });
  const [currentZone, setCurrentZone] = useState<IPaintZones | undefined>({
    reset: "",
    focus: "",
    hover: "",
  });

  const { setIsLoadingModalOpen } = useGlobalComponentContext();
  const marketsForSelection = marketsData?.map((market) => {
    return {
      label: market.name,
      value: market.id,
    };
  });
  const handleChange = (event: any) => {
    setSelectMarket(event.target.value);
    if (marketsData) {
      const currentMarket = marketsData.find(
        (market) => market.id === event.target.value,
      );
      const coords =
        currentMarket.polygon.geometry.coordinates[
          Math.floor(currentMarket.polygon.geometry.coordinates.length / 2)
        ][0][0];
      onSelectEvent(
        map,
        currentMarket.id,
        coords,
        mapboxgl,
        <></>,
        { zoom: initialZoom, lat: initialLat, long: initialLng },
        setCurrentMarket,
      );
    }
  };

  const onClose = (selectMarket: string | null) => {
    if (selectMarket) {
      map.current.fire("closePopupFromSearch");
      setSelectMarket("");
      setCurrentMarket((prev) => {
        return {
          reset: prev?.focus ?? "",
          focus: "",
          hover: "",
        };
      });
      map.current.fire("closePopup");
    }
  };

  //Mapbox setup
  const mapContainer = useRef<any>();
  const geocoderContainerRef = useRef<any>();
  const map = useRef<Map>();
  const geocoderRef = useRef<MapboxGeocoder>();

  useEffect(() => {
    if (mapContainer.current && marketsData && zonesData) {
      if (!map.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/light-v10",
          center: [initialLng, initialLat],
          zoom: initialZoom,
        });

        geocoderRef.current = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          reverseGeocode: true,
          types: "postcode",
          countries: "us,ca",
          flyTo: {
            zoom: 7,
          },
          placeholder: translate("maps.search.placeholder"),
          marker: false,
        });
        geocoderContainerRef.current.appendChild(
          geocoderRef.current?.onAdd(map.current),
        );

        //custom map pin
        const markerNode = document.createElement("div");
        const markerIcon = <PlaceTwoTone color="primary" fontSize="large" />;
        render(markerIcon, markerNode);
        const marker: Marker = new mapboxgl.Marker(markerNode);

        //add custom marker to map on result
        geocoderRef.current.on("result", (e) => {
          marker.setLngLat(e.result.center).addTo(map.current);
        });

        //remove custom marker to map on clear and zoom out
        geocoderRef.current.on("clear", () => {
          map.current?.easeTo({
            center: [initialLng, initialLat],
            zoom: initialZoom,
            duration: 1000,
          });
          marker.remove();
        });

        //add controls
        map.current.addControl(
          new mapboxgl.NavigationControl({
            showZoom: true,
            showCompass: false,
          }),
          "top-right",
        );

        //removes search result marker if you click on the map
        map.current.on("click", () => {
          geocoderRef.current?.clear();
        });

        //add polygon layers
        mapOnLoad(map, marketsData, zonesData, {
          active: activeZoneColor,
          inactive: inactiveZoneColor,
          pending: pendingZoneColor,
        });

        for (const market of marketsData) {
          const popupContent = {
            title: translate("maps.popup.title", { name: `${market.name} ` }),
          };
          addHoverEvent(
            map,
            market.id,
            mapboxgl,
            <ToolTip zoneName={popupContent.title} />,
            setCurrentMarket,
          );
          addClickEvent(
            map,
            market.id,
            mapboxgl,
            <></>,
            { zoom: initialZoom, lat: initialLat, long: initialLng },
            setCurrentMarket,
            setSelectMarket,
            marketsData,
          );
        }

        //add layer events for zones
        for (const zone of zonesData) {
          const marketZones = zonesData?.filter(
            (z) => z.marketId === zone.marketId,
          );
          // reactRouterDom render does not support usetranslate within renedered component unless passed as prop
          const popupContent = {
            title: translate("maps.popup.title", { name: `${zone.name} ` }),
            feature_title: translate("maps.popup.zone_features.title"),
            features: [
              translate("maps.popup.zone_features.sms"),
              translate("maps.popup.zone_features.pod"),
              translate("maps.popup.zone_features.delivery_attempts"),
            ],
            download_zips: translate("maps.popup.downloads.zips"),
            download_otd: translate("maps.popup.downloads.otd"),
            quote: translate("maps.popup.requests.quote"),
            deactive: translate("maps.popup.requests.deactivate.button"),
            pending: translate("maps.popup.requests.pending"),
            manage_pickup: translate("maps.popup.requests.manage_pickup"),
          };
          addClickEvent(
            map,
            zone.id,
            mapboxgl,
            <ZonePopup
              content={popupContent}
              marketZones={marketZones}
              chipColor={
                zone.status === ZoneStatus.ACTIVE
                  ? activeZoneColor
                  : inactiveZoneColor
              }
              zoneId={zone.id}
              merchantId={merchantId}
              zipUrl={zone.coverageFileUrl}
              otdUrl={zone.trailing90OTDUrl}
              canRequestQuote={canRequestQuote}
              status={zone.status || ZoneStatus.INACTIVE}
              deactivateZoneRequestHandler={deactivateZoneRequestHandler}
              managePickupLocationsHandler={managePickupLocation}
              requestQuoteHandler={() => {
                navigate(`${Routes.Zones}/create`, {
                  state: { zone, step: 1 },
                });
              }}
            />,
            { zoom: initialZoom, lat: initialLat, long: initialLng },
            setCurrentZone,
            setSelectMarket,
            marketsData,
          );
          addHoverEvent(
            map,
            zone.id,
            mapboxgl,
            <ToolTip zoneName={popupContent.title} />,
            setCurrentZone,
          );
        }
      }
    }
  }, [marketsData, zonesData]);

  useEffect(() => {
    if (currentMarket?.focus) {
      paintZones(PaintStyles.focus, currentMarket?.focus, map, zonesData);

      if (currentMarket?.reset) {
        paintZones(PaintStyles.reset, currentMarket.reset, map);
      }
    }
    if (currentMarket?.reset) {
      paintZones(PaintStyles.reset, currentMarket.reset, map, zonesData);
    }
  }, [currentMarket]);

  useEffect(() => {
    if (currentZone?.focus) {
      paintZones(PaintStyles.focus, currentZone?.focus, map, zonesData);
    }
    if (currentZone?.reset) {
      paintZones(PaintStyles.reset, currentZone.reset, map, zonesData);
    }
  }, [currentZone]);

  useEffect(() => {
    const zonesToShow = zonesData?.filter(
      (zone) => zone.marketId === selectMarket,
    );
    if (marketsData && zonesToShow && zonesData) {
      selectMarket
        ? marketZoneReset(map, false, marketsData, zonesToShow)
        : marketZoneReset(map, true, marketsData, zonesData);
    }
  }, [selectMarket]);

  return (
    <AppViewLayout
      title="maps.labels.page_title"
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link color="text.primary" underline="none">
            {translate("maps.labels.page_title")}
          </Link>
        </Breadcrumbs>
      }
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("maps.messaging.list_unactive_alert")}
          />
        )
      }
      sx={{
        "& .app-view-layout__header-container": {
          mb: 0,
        },
      }}
    >
      {isLoading ? <Loading /> : null}
      <SearchContainer>
        <GeocoderContainer ref={geocoderContainerRef} />
        <Divider />
        <FormControl
          sx={(theme: any) => ({
            maxWidth: "330px",
            [theme.breakpoints.down("lg")]: {
              maxWidth: "100%",
            },
            "& .MuiInputLabel-root": {
              paddingLeft: 2,
              paddingRight: 2,
              background: "#FFF",
            },
          })}
          fullWidth
        >
          <InputLabel>{translate("zones.select_label")}</InputLabel>
          <Select
            labelId={translate("zones.zone_title")}
            sx={{ whiteSpace: "pre-wrap", p: 0, m: 0 }}
            value={selectMarket}
            defaultValue={null}
            label={translate("zones.zone_title")}
            onChange={handleChange}
            endAdornment={
              <CloseIconButton
                sx={{ display: selectMarket ? "" : "none" }}
                aria-label="close"
                size="small"
                onClick={() => onClose(selectMarket)}
              >
                <CloseIcon fontSize="inherit" />
              </CloseIconButton>
            }
          >
            {marketsForSelection &&
              marketsForSelection.map((market) => {
                return (
                  <MenuItem key={market.value} value={market.value}>
                    {market.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </SearchContainer>
      <MapContainer ref={mapContainer} />
    </AppViewLayout>
  );
};

//styled containers
const MapContainer = styled("div")(({ theme }) => ({
  height: "66%",
  width: "100%",
  marginBlock: 10,
  [theme.breakpoints.up("lg")]: {
    height: "80%",
  },
}));

const GeocoderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("lg")]: {
    width: "initial",
  },
}));

const Divider = styled("div")(({ theme }) => ({
  width: "100%",
  height: "1px",
  margin: "20px",
  [theme.breakpoints.up("lg")]: {
    height: "46px",
    width: "1px",
    margin: "0px",
  },
  border: "0.3px solid rgba(0, 0, 0, 0.23)",
}));

const SearchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: 20,
  paddingBottom: 10,
  flexDirection: "column",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: 16,
  height: 24,
  width: 24,
  position: "absolute",
  right: 10,
  top: 16,
  backgroundColor: theme.palette.neutralScale[500],
  color: theme.palette.primary.contrastText,
  ":hover": { backgroundColor: theme.palette.neutralScale[500] },
}));

export default ZonesMap;
