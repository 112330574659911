import {
  Button,
  styled,
  Breadcrumbs,
  Link,
  Typography,
  OpenInNewIcon,
} from "@helo/ui";
import { ContactSalesParams } from "@swyft/domain";
import {
  useTranslate,
  useEditController,
  useGetList,
  useNotify,
} from "react-admin";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { IntegrationProperties } from "@swyft/swyft-common";
import { ApiTokenType } from "@swyft/swyft-common";

import { AppViewLayout } from "~/layouts/app";
import { AppResource } from "~/config/resources";
import Loading from "~/components/feedback/Loading";
import { useNotifyDialog } from "~/components/feedback/GlobalAlerts";
import { Routes } from "~/config/Routes";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useMerchantDataProvider } from "~/services/data/merchant";

const IntegrationsEdit = () => {
  const { merchant } = useAuthenticatedContext();
  const { id } = useParams();
  const { record, save, isLoading } = useEditController({
    resource: AppResource.Integrations,
    id,
  });
  const apiKeys = useGetList(AppResource.Api, {
    filter: {
      org: {
        id: merchant?.id,
      },
    },
  });
  const notify = useNotify();
  const notifyDialog = useNotifyDialog();
  const translate = useTranslate();
  const [resource, dataProvider] = useMerchantDataProvider();

  const Step = ({ step, i }: StepProps) => {
    return (
      <Typography
        key={step.id}
        variant="h6"
        align="center"
        sx={{ maxWidth: "90%" }}
      >
        {`${i + 1}. ${step.value}`}
      </Typography>
    );
  };

  const ApiKeys = () => {
    const liveKey =
      apiKeys.data &&
      apiKeys.data.find((apiKey) => apiKey.type === ApiTokenType.LIVE);

    if (liveKey) {
      return (
        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
          {`Live API Key: ${liveKey.id}`}
        </Typography>
      );
    } else {
      return (
        <Link sx={{ mb: 2 }} href={Routes.Api}>
          <Button size="large" type="submit" variant="contained">
            {translate("api.btn")}
          </Button>
        </Link>
      );
    }
  };

  const contactSales = async (step: IntegrationProperties) => {
    if (merchant?.id) {
      const params: ContactSalesParams = {
        merchantId: merchant.id,
        message: `Merchant: ${merchant.name} |
            Requesting configuration for TMS integration: ${step.name} |
            NOTE: Please notify merchant when integration is live |
            Instructions: https://www.notion.so/useswyft/Integrations-f55969066941421c9a8a9a7a9f063872`,
      };
      try {
        await dataProvider.contactSales(resource, params);
        notifyDialog({
          title: translate("shared.message.contact.success"),
          content: translate("shared.message.contact.description"),
          isOpen: true,
        });
      } catch (err: unknown) {
        notify(
          translate(`${translate("shared.message.submit.fail")} (${err})`),
          {
            type: "warning",
          },
        );
      }
    }
  };

  return (
    <AppViewLayout
      title={`${record?.name} ${translate("tms.instructions")}`}
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link
            color="inherit"
            underline="hover"
            component={RouterLink}
            to={Routes.Tms}
          >
            {translate("menu.settings.integrations.tms")}
          </Link>
          <Link color="inherit" underline="none">
            {record?.name}
          </Link>
        </Breadcrumbs>
      }
    >
      {isLoading ? (
        <Loading />
      ) : (
        <StepContainer>
          <ApiKeys />
          {record.properties.map((step: IntegrationProperties, i: number) => {
            if (step.type === StepTypes.SALES) {
              return (
                <Button
                  key={step.id}
                  onClick={() => contactSales(step)}
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  {translate("tms.contact")}
                </Button>
              );
            }
            if (step.type === StepTypes.INSTRUCTION) {
              return <Step key={step.id} step={step} i={i} />;
            }
            if (step.type === StepTypes.CTA) {
              return (
                <Link
                  key={step.id}
                  sx={{ mt: 2 }}
                  href={step.value}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button size="large" type="submit" variant="contained">
                    <OpenInNewIcon
                      sx={{ height: "20px", paddingRight: "5px" }}
                    />
                    {translate("tms.documentation", { company: step.name })}
                  </Button>
                </Link>
              );
            }
          })}
        </StepContainer>
      )}
    </AppViewLayout>
  );
};

const StepContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

interface StepProps {
  step: IntegrationProperties;
  i: number;
}

enum StepTypes {
  INSTRUCTION = "instruction",
  CTA = "cta",
  SALES = "cta-sales",
}

export default IntegrationsEdit;
