"use strict";
exports.__esModule = true;
exports.SMSType = exports.determineIfSMSEvent = void 0;
function determineIfSMSEvent(event) {
    return !!((event === null || event === void 0 ? void 0 : event.type) && (event === null || event === void 0 ? void 0 : event.createdAt));
}
exports.determineIfSMSEvent = determineIfSMSEvent;
/** Specifications of different types of SMS events */
var SMSType;
(function (SMSType) {
    SMSType["SCHEDULED_FOR_DELIVERY"] = "SCHEDULED_FOR_DELIVERY";
    SMSType["NEXT_FOR_DELIVERY"] = "NEXT_FOR_DELIVERY";
    SMSType["ARRIVING"] = "ARRIVING";
    SMSType["DELIVERED"] = "DELIVERED";
    SMSType["DELIVERED_SURVEY"] = "DELIVERED_SURVEY";
    SMSType["FAILED"] = "FAILED";
    SMSType["SCHEDULED_FOR_REDELIVERY"] = "SCHEDULED_FOR_REDELIVERY";
    SMSType["RETURNING_TO_SENDER"] = "RETURNING_TO_SENDER";
    SMSType["RECEIVED"] = "RECEIVED";
    SMSType["ERROR"] = "ERROR";
})(SMSType = exports.SMSType || (exports.SMSType = {}));
