import { useGetList, useRecordContext, useTranslate } from "react-admin";
import {
  AccessTimeIcon,
  Box,
  ErrorOutlinedIcon,
  Skeleton,
  styled,
  SxProps,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@helo/ui";
import { format } from "date-fns";
import { useMemo } from "react";
import { Label, TrackingEvent, TrackingEventState } from "@swyft/types";
import { ActivityLog, ActivityType, SortOrder } from "@swyft/domain";

import { AppResource } from "~/config/resources";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";

const LabelTimeline = () => {
  const record = useRecordContext<Label>();
  const { identity } = useAuthenticatedContext();
  const { data, isLoading, error } = useGetList<ActivityLog>(
    AppResource.ShipmentActivityLog,
    {
      filter: {
        org: {
          id: identity?.organizationId,
        },
        label: {
          id: record.id,
        },
        prune: true,
        type: [ActivityType.TRACKING],
      },
      sort: { field: "timestamp", order: "desc" },
    },
  );
  const timelineGroup = useMemo(
    () =>
      new TimelineGroupAdapter(data ?? [], [
        {
          checkIfComplete: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.PENDING,
          checkIfError: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.DELETED ||
            (e.data as TrackingEvent).state === TrackingEventState.PROBLEM,
          label: "shipments.content.details.timeline.track.segment.created",
        },
        {
          checkIfComplete: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.RECEIVED,
          checkIfError: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.PROBLEM ||
            (e.data as TrackingEvent).state === TrackingEventState.NOT_RECEIVED,
          label: "shipments.content.details.timeline.track.segment.received",
        },
        {
          checkIfComplete: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.IN_TRANSIT ||
            (e.data as TrackingEvent).state ===
              TrackingEventState.RETURNING_TO_SENDER,
          checkIfError: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.PROBLEM ||
            (e.data as TrackingEvent).state === TrackingEventState.FAILED,
          checkIfInProgress: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.ARRIVING,
          label: "shipments.content.details.timeline.track.segment.in_transit",
        },
        {
          checkIfComplete: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.DELIVERED ||
            (e.data as TrackingEvent).state ===
              TrackingEventState.RETURNED_TO_SENDER,
          checkIfError: (e) =>
            (e.data as TrackingEvent).state === TrackingEventState.PROBLEM,
          label: "shipments.content.details.timeline.track.segment.delivered",
        },
      ]),
    [data?.length],
  );

  if (error) {
    return null;
  }

  return (
    <TimelineContainer>
      <TimelineGroup>
        {timelineGroup.segments.map((segment, idx) => {
          if (isLoading) {
            return <Skeleton variant="rectangular" key={segment.label + idx} />;
          }

          let state = StatusChipState.INCOMPLETE;
          let errorMessage: string = "";
          let timestamp: Date | undefined;

          if (segment.isComplete) {
            state = StatusChipState.COMPLETE;
            timestamp = segment.getTimestampEvent()?.timestamp;
          } else if (segment.isInProgress) {
            state = StatusChipState.IN_PROGRESS;
          } else {
            state = StatusChipState.INCOMPLETE;
          }

          if (!segment.isValid) {
            state = StatusChipState.ERROR;
            errorMessage = segment.getLatestError()?.details
              ? `shipments.content.details.activity.message.track.${error}`
              : "shipments.content.details.timeline.error.unknown";
          }

          return (
            <StatusChip
              key={segment.label + idx}
              position={
                idx === 0
                  ? "start"
                  : idx + 1 === timelineGroup.segments.length
                  ? "end"
                  : "middle"
              }
              label={segment.label}
              state={state}
              timestamp={timestamp}
              error={errorMessage}
            />
          );
        })}
      </TimelineGroup>
    </TimelineContainer>
  );
};

const StatusChip = ({
  state = StatusChipState.INCOMPLETE,
  label,
  timestamp,
  position,
  error,
  sx = [],
}: StatusChipParams) => {
  const translate = useTranslate();
  const chipBaseStyleOverrides: SxProps<Theme> = [
    ({ palette }) => ({
      ...(position === "middle" && {
        borderRadius: 0,
        borderRight: "2px solid",
        borderRightColor: palette.neutralScale[400],
      }),
      ...(position === "start" && {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: "2px solid",
        borderRightColor: palette.neutralScale[400],
      }),
      ...(position === "end" && {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }),
      ...(state === StatusChipState.COMPLETE && {
        backgroundColor: palette.success.light,
        color: palette.success.contrastText,
      }),
      ...(state === StatusChipState.IN_PROGRESS && {
        backgroundColor: palette.primaryScale[800],
        color: palette.primary.dark,
      }),
      ...(state === StatusChipState.INCOMPLETE && {
        backgroundColor: palette.neutral.light,
        color: palette.neutral.dark,
      }),
      ...(state === StatusChipState.ERROR && {
        backgroundColor: palette.primaryScale[800],
        color: palette.error.dark,
      }),
    }),
    ...(Array.isArray(sx) ? sx : [sx]),
  ];

  return (
    <StatusChipBase sx={chipBaseStyleOverrides}>
      <Box sx={{ display: "inline-flex", alignItems: "center" }}>
        <Typography variant="subtitle1" component="p">
          {translate(label)}
        </Typography>
        {error && state === StatusChipState.ERROR && (
          <StatusChipIssueTooltip title={translate(error)}>
            <ErrorOutlinedIcon
              fontSize="medium"
              color="inherit"
              sx={{ ml: 0.5 }}
            />
          </StatusChipIssueTooltip>
        )}
      </Box>
      {timestamp && (
        <CaptionWithIconBox>
          <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, fontSize: 14 }} />
          <Typography variant="caption" sx={{ textAlign: "center " }}>
            {format(timestamp, "MMM do, yyy p")}
          </Typography>
        </CaptionWithIconBox>
      )}
    </StatusChipBase>
  );
};

class TimelineGroupAdapter {
  readonly events: ActivityLog[];
  private segmentBlueprints: TimelineSegmentBlueprint[];
  readonly eventsOrder: SortOrder;
  readonly segments: TimelineSegment[] = [];

  constructor(
    events: ActivityLog[] = [],
    segmentBlueprints: TimelineSegmentBlueprint[] = [],
    eventsOrder: SortOrder = SortOrder.DESC,
  ) {
    this.segmentBlueprints = segmentBlueprints;
    this.eventsOrder = eventsOrder;
    this.events =
      this.eventsOrder === SortOrder.ASC
        ? events.slice().reverse()
        : events.slice(); // always work with descending order (newest -> oldest) events
    this.segmentBlueprints.forEach((blueprint) =>
      this.insert(new TimelineSegment(this, blueprint)),
    );
    this.init();
  }

  insert(segment: TimelineSegment) {
    if (!this.segments.length) {
      segment.previous = null;
    }

    segment.next = null;

    const lastSegment = this.segments.at(-1);

    if (!!lastSegment) {
      lastSegment.next = segment;
      segment.previous = lastSegment;
    }

    this.segments.push(segment);
  }

  init() {
    if (!this.segments.length) {
      return;
    }

    this.segments.forEach((seg) => seg.setup());
  }
}

class TimelineSegment {
  private group: TimelineGroupAdapter;
  private events: ActivityLog[] = [];
  private inProgressEvents: ActivityLog[] = [];
  private errorEvents: ActivityLog[] = [];
  private blueprint: TimelineSegmentBlueprint;
  private isCompleteEvent?: ActivityLog;
  next: TimelineSegment | null = null;
  previous: TimelineSegment | null = null;
  label: string;

  get isComplete(): boolean {
    // override. if next segment is complete or in-progress, this one is complete too.
    if (this.next?.isComplete || this.next?.isInProgress) {
      return true;
    }

    return !!this.isCompleteEvent;
  }

  get isInProgress(): boolean {
    // if incomplete and there are some in-progress events
    return (
      !this.isComplete &&
      !!this.blueprint.checkIfInProgress &&
      this.inProgressEvents.length > 0
    );
  }

  get isValid(): boolean {
    // if there are no errors at all, or if the latest event in the segment is not an error
    return (
      !this.errorEvents.length ||
      this.getLatestEvent() !== this.getLatestError()
    );
  }

  /**
   * Constructs a timeline segment
   * NOTE: events are assumed to be in descending (oldest -> newest) order
   * @param group the parent entity that holds a group of segments together. Must provide timeline order and base set of events.
   * @param blueprint the blueprint for this segment
   * @param next reference to the next segment in timeline order. Always assumed to be oldest to newest.
   * @param previous reference to the previous segment in timeline order. Always assumed to be oldest to newest.
   */
  constructor(
    group: TimelineGroupAdapter,
    blueprint: TimelineSegmentBlueprint,
    next?: TimelineSegment,
    previous?: TimelineSegment,
  ) {
    this.group = group;
    this.blueprint = blueprint;
    this.label = this.blueprint.label;
    this.next = next ?? null;
    this.previous = previous ?? null;
    this.setup();
  }

  setup() {
    // events are sliced down to the only the events relevant to the segment
    this.events = this.group.events.slice(
      this.findStartBoundary(),
      this.findEndBoundary(),
    );
    this.isCompleteEvent = this.events.find((e) =>
      this.blueprint.checkIfComplete(e),
    );
    this.errorEvents = this.events.filter((e) =>
      this.blueprint.checkIfError(e),
    );
    this.inProgressEvents = this.events.filter(
      (e) =>
        this.blueprint.checkIfInProgress && this.blueprint.checkIfInProgress(e),
    );
  }

  getLatestError() {
    if (!this.errorEvents.length) {
      return null;
    }

    return this.errorEvents[0];
  }

  getLatestEvent() {
    if (!this.events.length) {
      return null;
    }

    return this.events[0];
  }

  getTimestampEvent() {
    if (!this.isValid) {
      return this.getLatestError();
    }

    if (this.isInProgress) {
      return this.getLatestEvent();
    }

    return this.isCompleteEvent ?? this.getLatestEvent();
  }

  private findStartBoundary(): number {
    let altStartBoundary = 0;

    // if incomplete, but next segment is available, check if there are any in-progress events, as the oldest event there is the start boundary
    // otherwise, keep searching recursively
    if (!this.isCompleteEvent && this.next) {
      altStartBoundary =
        this.next?.inProgressEvents.length > 0
          ? this.group.events.lastIndexOf(
              this.next.inProgressEvents.slice(0, -1)[0],
            )
          : this.next.findStartBoundary();
    }

    return !!this.isCompleteEvent
      ? this.getSafeFindIdx(
          this.group.events.findIndex((e) => this.blueprint.checkIfComplete(e)),
          0,
        )
      : altStartBoundary;
  }

  private findEndBoundary(): number {
    let altEndBoundary = this.group.events.length;

    // if incomplete but previous segment is available, check if there are any in-progress events, as the newest event there is the end boundary
    // otherwise, keep searching recursively
    if (!this.isCompleteEvent && this.previous) {
      altEndBoundary =
        this.previous?.inProgressEvents.length > 0
          ? this.group.events.indexOf(this.previous.inProgressEvents[0])
          : this.previous.findEndBoundary();
    }

    return this.previous
      ? !!this.previous.isCompleteEvent
        ? this.getSafeFindIdx(
            this.group.events.findIndex((e) =>
              this.previous?.blueprint.checkIfComplete(e),
            ),
            this.group.events.length,
          )
        : altEndBoundary
      : this.group.events.length;
  }

  private getSafeFindIdx(idx: number, fallback: number) {
    return idx === -1 ? fallback : idx;
  }
}

const StatusChipIssueTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      leaveDelay={200}
      classes={{ popper: className }}
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.accentRed.contrastText,
    maxWidth: 200,
  },
}));

const StatusChipBase = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: 5,
  padding: "10px 15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  backgroundColor: palette.neutral.main,
}));

const CaptionWithIconBox = styled(Box)({
  color: "inherit",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px 0",
  whiteSpace: "nowrap",
});

const TimelineContainer = styled(Box)({
  margin: "24px 0",
});

const TimelineGroup = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridTemplateRows: "minmax(min-content, max-content)",
  gap: 0,
});

interface StatusChipParams {
  position?: "start" | "middle" | "end";
  label: string;
  state?: StatusChipState;
  timestamp?: Date;
  error?: string;
  sx?: SxProps<Theme>;
}

enum StatusChipState {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  INCOMPLETE = "INCOMPLETE",
  ERROR = "ERROR",
}

interface TimelineSegmentBlueprint {
  checkIfComplete: (event: ActivityLog) => boolean; // what makes this segment complete?
  checkIfError: (event: ActivityLog) => boolean; // what makes this segment show an error?
  checkIfInProgress?: (event: ActivityLog) => boolean; // what makes this segment in-progress?
  label: string;
}

export default LabelTimeline;
