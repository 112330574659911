import React from "react";
import { render } from "react-dom";
import { ZoneStatus } from "@swyft/types";
import { IPaintZones } from "../ZonesMap";
import { MapZone } from "~/services/data/types";

interface LayerColor {
  active: string;
  inactive: string;
  pending: string;
}

/*
  iterates through markets & zones and creates a fill & border layer for each one .
  relies on status for color and tallies active zones to update active zone count displayed
*/
export const mapOnLoad = (
  map: React.MutableRefObject<mapboxgl.Map | undefined>,
  marketsData: MapZone[],
  zonesData: MapZone[],
  colors: LayerColor,
) => {
  map.current?.on("load", () => {
    // Markets
    const marketsWithZipTotal = marketsData.map((market) => {
      const zones = zonesData.filter(
        (zone) =>
          zone.marketId === market.id &&
          zone.destinationPostalCodes !== undefined,
      );

      const totalZipsInMarket = zones.reduce((acc, curr) => {
        if (curr.destinationPostalCodes) {
          return acc + curr.destinationPostalCodes.length;
        } else {
          return 0;
        }
      }, 0);

      return {
        ...market,
        zipAmount: totalZipsInMarket,
      };
    });

    const orderedMarketsData = marketsWithZipTotal.sort((a, b) => {
      return a.zipAmount < b.zipAmount ? -1 : 1;
    });
    if (marketsData) {
      for (const market of orderedMarketsData) {
        map.current?.addSource(market.id, {
          type: "geojson",
          //@ts-ignore
          data: market.polygon,
          generateId: true,
        });

        //Fill the polygon with color
        map.current?.addLayer({
          id: `${market.id}-fill`,
          type: "fill",
          source: market.id,
          paint: {
            "fill-color": colors.inactive,
            "fill-opacity": 0.4,
          },
        });

        //Draw a border around the polygon
        map.current?.addLayer({
          id: `${market.id}-border`,
          type: "line",
          source: market.id,
          paint: {
            "line-color": colors.inactive,
            "line-width": 1,
          },
        });
      }
    }
    // Zones
    const orderedZonesData = zonesData.sort((a, b) => {
      if (a.destinationPostalCodes === undefined) {
        return 1;
      }
      if (b.destinationPostalCodes === undefined) {
        return -1;
      }

      return a.destinationPostalCodes.length < b.destinationPostalCodes.length
        ? 1
        : -1;
    });
    if (zonesData) {
      for (const zone of orderedZonesData) {
        let color = colors.inactive;
        if (zone.status === ZoneStatus.ACTIVE) {
          color = colors.active;
        }
        if (zone.status === ZoneStatus.PENDING) color = colors.pending;
        if (zone.polygon) {
          map.current?.addSource(zone.id, {
            type: "geojson",
            //@ts-ignore
            data: zone.polygon,
            generateId: true,
          });
          map.current?.addLayer({
            id: `${zone.id}-fill`,
            type: "fill",
            source: zone.id,
            paint: {
              "fill-color": color,
              "fill-opacity": 0.4,
            },
            layout: {
              visibility: "none",
            },
          });
          map.current?.addLayer({
            id: `${zone.id}-border`,
            type: "line",
            source: zone.id,
            paint: {
              "line-color": color,
              "line-width": 1,
            },
            layout: {
              visibility: "none",
            },
          });
        }
      }
    }
  });
};

export const addClickEvent = (
  mapRef: React.MutableRefObject<mapboxgl.Map | undefined>,
  source: string,
  mapboxgl: any,
  el: JSX.Element,
  startingPoint: { zoom: number; lat: number; long: number },
  setCurrentMarket: React.Dispatch<
    React.SetStateAction<IPaintZones | undefined>
  >,
  setSelectMarket: React.Dispatch<React.SetStateAction<string>>,
  marketsData: MapZone[],
) => {
  mapRef.current?.on("click", `${source}-fill`, (e: any) => {
    if (!mapRef.current) return;
    if (e.features.length > 0) {
      const layer = e.features[0].layer.id;

      const isMarketId = marketsData.find((market) => market.id === source);
      setSelectMarket((prev) => {
        return isMarketId ? source : prev;
      });

      // fly to clicked zone and zoom
      mapRef.current?.easeTo({
        center: [e.lngLat.lng, e.lngLat.lat],
        zoom: 6,
        duration: 1000,
      });

      // Create a popup, but don't add it to the map yet.
      const popup = new mapboxgl.Popup({ anchor: true });

      // Copy coordinates array.
      const popupNode = document.createElement("div");

      render(el, popupNode);
      popup
        .addClassName("custom-popup")
        .setLngLat(e.lngLat)
        .setDOMContent(popupNode)
        .addTo(mapRef.current);

      //highlight clicked zone
      setCurrentMarket((prev) => {
        return {
          reset: prev?.focus ?? "",
          focus: layer,
          hover: "",
        };
      });

      let zoomOut = true;

      //add custom closePopup event listener
      mapRef.current?.on("closePopup", () => {
        zoomOut = true;
        popup?.remove();
      });

      mapRef.current?.on("closePopupFromSearch", () => {
        //search zooms to result, so back to default zoom should be false
        zoomOut = false;
        popup?.remove();
      });

      //on close remove highlighted zone
      popup.on("close", () => {
        //if true revert to default zoom and coordinates

        if (zoomOut) {
          mapRef.current?.easeTo({
            center: [startingPoint.long, startingPoint.lat],
            zoom: startingPoint.zoom,
            duration: 1000,
          });
        }
        //this should always default back to true
        zoomOut = true;
        setCurrentMarket(() => {
          return {
            reset: layer,
            focus: "",
            hover: "",
          };
        });
      });
    }
  });
};

export const addHoverEvent = (
  mapRef: React.MutableRefObject<mapboxgl.Map | undefined>,
  source: string,
  mapboxgl: any,
  el: JSX.Element,
  setCurrentMarket: React.Dispatch<
    React.SetStateAction<IPaintZones | undefined>
  >,
) => {
  // Create a popup, but don't add it to the map yet.
  const popup = new mapboxgl.Popup({ closeButton: false });
  popup.addClassName("mapbox-hover-tooltip");

  mapRef.current?.on("mouseenter", `${source}-fill`, (e: any) => {
    if (!mapRef.current) return;

    // Change the cursor style as a UI indicator.
    mapRef.current.getCanvas().style.cursor = "pointer";

    // Copy coordinates array.
    const toolTipNode = document.createElement("div");
    render(el, toolTipNode);

    setCurrentMarket((prev) => {
      if (prev?.focus === `${source}-fill`) return prev;

      popup
        .setLngLat(e.lngLat)
        .setDOMContent(toolTipNode)
        .addTo(mapRef.current);

      paintZones(PaintStyles.hover, `${source}-fill`, mapRef);

      return {
        reset: "",
        focus: "",
        hover: `${source}-fill`,
      };
    });
  });

  mapRef.current?.on("mouseleave", `${source}-fill`, (e: any) => {
    if (!mapRef.current) return;

    setCurrentMarket((prev) => {
      if (prev?.focus === `${source}-fill`) return prev;
      popup.remove();
      return {
        reset: `${source}-fill`,
        focus: "",
        hover: "",
      };
    });
  });
};

export const onSelectEvent = (
  mapRef: React.MutableRefObject<mapboxgl.Map | undefined>,
  source: string,
  coords: number[],
  mapboxgl: any,
  el: JSX.Element,
  startingPoint: { zoom: number; lat: number; long: number },
  setCurrentMarket: React.Dispatch<
    React.SetStateAction<IPaintZones | undefined>
  >,
) => {
  if (!mapRef.current) return;

  // fly to clicked zone and zoom
  mapRef.current?.easeTo({
    center: [coords[0], coords[1]],
    zoom: 6,
    duration: 1000,
  });

  // Create a popup, but don't add it to the map yet.
  const popup = new mapboxgl.Popup({ anchor: true });

  // Copy coordinates array.
  const popupNode = document.createElement("div");
  render(el, popupNode);
  popup
    .addClassName("custom-popup")
    .setLngLat(coords)
    .setDOMContent(popupNode)
    .addTo(mapRef.current);

  //highlight clicked zone
  setCurrentMarket((prev) => {
    return {
      reset: prev?.focus ?? "",
      focus: `${source}-fill`,
      // focus: layer,
      hover: "",
    };
  });

  let zoomOut = true;

  //add custom closePopup event listener
  mapRef.current?.on("closePopup", () => {
    zoomOut = true;
    popup?.remove();
  });

  mapRef.current?.on("closePopupFromSearch", () => {
    //search zooms to result, so back to default zoom should be false
    zoomOut = false;
    popup?.remove();
  });

  //on close remove highlighted zone
  popup.on("close", () => {
    //if true revert to default zoom and coordinates
    if (zoomOut) {
      mapRef.current?.easeTo({
        center: [startingPoint.long, startingPoint.lat],
        zoom: startingPoint.zoom,
        duration: 1000,
      });
    }
    //this should always default back to true
    zoomOut = true;
    setCurrentMarket(() => {
      return {
        // reset: layer,
        reset: `${source}-fill`,
        focus: "",
        hover: "",
      };
    });
  });
  // }
  // });
};

export enum PaintStyles {
  reset,
  focus,
  hover,
}

export const paintZones = (
  style: PaintStyles,
  zone: string,
  mapRef: React.MutableRefObject<mapboxgl.Map | undefined>,
  zonesData?: MapZone[],
) => {
  const currentMarketZones = zonesData?.filter(
    (z) => z.marketId === zone.replace("-fill", ""),
  );

  if (style === PaintStyles.reset) {
    {
      mapRef.current?.setPaintProperty(zone, "fill-opacity", 0.4);
      mapRef.current?.setPaintProperty(
        zone.replace(/fill/, "border"),
        "line-width",
        1,
      );
    }
  }
  if (style === PaintStyles.focus) {
    {
      const isZoneId = zonesData?.find(
        (z) => z.id === zone.replace(/-fill/, ""),
      );
      if (!isZoneId) {
        mapRef.current?.setPaintProperty(zone, "fill-opacity", 0.01);
        mapRef.current?.setPaintProperty(
          zone.replace(/fill/, "border"),
          "line-width",
          0.01,
        );
      }
    }
  }
  if (style === PaintStyles.hover) {
    {
      mapRef.current?.setPaintProperty(zone, "fill-opacity", 0.4);
      mapRef.current?.setPaintProperty(
        zone.replace(/fill/, "border"),
        "line-width",
        3,
      );
    }
  }
};

export const marketZoneReset = (
  mapRef: React.MutableRefObject<mapboxgl.Map | undefined>,
  reset: boolean,
  marketsData: MapZone[],
  zonesData: MapZone[],
) => {
  const setLayoutProp = (id: string, visibility: string) => {
    if (mapRef.current?.getLayer(id)) {
      return mapRef.current?.setLayoutProperty(id, "visibility", visibility);
    }
  };
  if (!reset) {
    marketsData?.map((market) => {
      if (mapRef.current?.getLayer(`${market.id}-fill`)) {
        setLayoutProp(`${market.id}-fill`, "none");
        setLayoutProp(`${market.id}-border`, "none");
      }
    });
    zonesData?.map((zone) => {
      if (mapRef.current?.getLayer(`${zone.id}-fill`)) {
        setLayoutProp(`${zone.id}-fill`, "visible");
        setLayoutProp(`${zone.id}-border`, "visible");
      }
    });
  } else {
    marketsData?.map((market) => {
      if (mapRef.current?.getLayer(`${market.id}-fill`)) {
        setLayoutProp(`${market.id}-fill`, "visible");
        setLayoutProp(`${market.id}-border`, "visible");
      }
    });
    zonesData?.map((zone) => {
      if (mapRef.current?.getLayer(`${zone.id}-fill`)) {
        setLayoutProp(`${zone.id}-fill`, "none");
        setLayoutProp(`${zone.id}-border`, "none");
      }
    });
  }
};
