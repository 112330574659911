import { useTranslate, useNotify } from "react-admin";
import { useState, useEffect } from "react";
import { Box, Typography, Link, Breadcrumbs } from "@helo/ui";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import {
  Merchant,
  RateCard,
  RateCardType,
  RateCardV2,
  ServiceType,
} from "@swyft/types";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useGlobalComponentContext } from "~/components/GlobalComponentContext";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import AddOnRates from "./AddOnRates";
import RateCardV1ServiceLevels from "./RateCardV1ServiceLevels";
import { AppViewLayout } from "~/layouts/app";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import { useMerchantDataProvider } from "~/services/data/merchant";
import Loading from "~/components/feedback/Loading";
import RateCardV2ServiceLevels from "./RateCardV2ServiceLevels";

const RatesList = () => {
  useDefineAppLocation("settings.rates");
  const { merchant } = useAuthenticatedContext();
  const isOrgActive = merchant?.isActive;
  const translate = useTranslate();
  const notify = useNotify();
  const [resource, dataProvider] = useMerchantDataProvider();
  const [rates, setRates] = useState<{
    rateCardType: RateCardType;
    rateCard: RateCard | RateCardV2;
  }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const fetchRates = async (merchant: Merchant) => {
      try {
        const { data } = await dataProvider.getRates(resource, {
          merchantId: merchant.id,
          isActive: merchant.isActive,
          country: merchant.countryCode,
        });
        setRates(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
        setLoading(false);
        notify(`${translate("shared.message.generic.fail")}`, {
          type: "warning",
        });
      }
    };

    if (!merchant?.id) {
      return;
    }

    fetchRates(merchant);
  }, [merchant]);

  if (!rates) {
    return <Loading />;
  }

  const { rateCardType, rateCard } = rates;

  return (
    <AppViewLayout
      title="rates.header.title"
      context={translate("rates.header.currency", {
        currency: rateCard?.currency || "-",
      })}
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link color="text.primary" underline="none">
            {translate("rates.header.title")}
          </Link>
        </Breadcrumbs>
      }
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("rates.messaging.list_unactive_alert")}
          />
        )
      }
    >
      <RatesCard {...{ rateCard, rateCardType, loading, error }} />
    </AppViewLayout>
  );
};

const RatesCard = ({
  rateCard,
  rateCardType,
  loading,
  error,
}: RatesCardProps) => {
  const translate = useTranslate();
  const { setIsContactSalesOpen } = useGlobalComponentContext();

  if (loading) {
    return <Loading loadingSecondary={translate("rates.loading")} />;
  }

  if (!rateCard || error) {
    return (
      <GracefulFallback type="error" details="rates.messaging.fetch_error" />
    );
  }

  return (
    <>
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="body1">
          {translate("rates.header.disclaimer")}.
          <Link
            sx={{ marginLeft: 1, cursor: "pointer" }}
            onClick={() => {
              setIsContactSalesOpen(true);
            }}
          >
            <Typography variant="body1" component="span">
              {translate("rates.header.contact_sales")}!
            </Typography>
          </Link>
        </Typography>
      </Box>
      {rateCard && rateCardType === RateCardType.rateCard
        ? [ServiceType.SAME_DAY, ServiceType.NEXT_DAY].map((serviceType) => {
            return (
              <RateCardV1ServiceLevels
                key={`${serviceType}-rates-table`}
                title={serviceType}
                rateCard={rateCard as RateCard}
                serviceType={serviceType}
              />
            );
          })
        : null}
      {rateCard && rateCardType === RateCardType.rateCardV2
        ? [ServiceType.SAME_DAY, ServiceType.NEXT_DAY].map((serviceType) => {
            return (
              <RateCardV2ServiceLevels
                key={`${serviceType}-rates-table`}
                title={serviceType}
                rateCard={rateCard as RateCardV2}
                serviceType={serviceType}
              />
            );
          })
        : null}
      {rateCard?.addOns ? (
        <AddOnRates
          addOns={rateCard.addOns}
          currency={rateCard.currency || ""}
        />
      ) : null}
    </>
  );
};

interface RatesCardProps {
  rateCard: RateCard | RateCardV2;
  rateCardType: RateCardType;
  loading: boolean;
  error: any;
}

export default RatesList;
