export enum AppResource {
  User = "users",
  Merchant = "merchants",
  DSP = "dsps",
  Location = "locations",
  Rate = "rates",
  Shipment = "shipments",
  ShipmentActivityLog = "shipments-activity-log",
  Zone = "zones",
  Markets = "markets",
  Reports = "reports",
  Api = "api",
  Webhook = "webhook",
  Integrations = "integrations",
  Invoices = "invoices",
}
