import {
  Box,
  CloseIcon,
  Dialog,
  FormLabel,
  Grid,
  IconButton,
  LoadingButton,
  styled,
  TextField,
  Typography,
} from "@helo/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ContactMerchantSupportParams,
  SupportRequestType,
} from "@swyft/domain";
import { Currency } from "@swyft/types";
import { nanoid } from "nanoid";
import {
  useLocaleState,
  useNotify,
  useShowContext,
  useTranslate,
} from "react-admin";
import { SubmitHandler, useForm } from "react-hook-form";
import { STORAGE_BUCKET_FOLDERS } from "~/common/consts";
import { currencyFormatter } from "~/common/helpers";
import {
  InvoiceClaimRequestValidationSchema,
  InvoiceClaimSchema,
} from "~/common/validators/invoice/InvoiceClaimSchema";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useNotifyDialog } from "~/components/feedback/GlobalAlerts";
import CurrencyInput, { ValueType } from "~/components/inputs/CurrencyInput";
import FileDrop from "~/components/inputs/FileDrop";
import { useMerchantDataProvider } from "~/services/data/merchant";

interface ClaimProps {
  isInvoiceClaimOpen: boolean;
  setIsInvoiceClaimOpen: (bool: boolean) => void;
}

const InvoiceClaimDialog = ({
  isInvoiceClaimOpen,
  setIsInvoiceClaimOpen,
}: ClaimProps) => {
  const [resource, dataProvider] = useMerchantDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const notifyDialog = useNotifyDialog();
  const [locale] = useLocaleState();
  const { record } = useShowContext();
  const { merchant } = useAuthenticatedContext();
  const merchantId = merchant?.id;
  const acceptedFileTypes = {
    "image/png": [".png"],
    "image/jpeg": [".jpeg, .jpg"],
    "application/pdf": [".pdf"],
  }; // as specified in https://react-dropzone.netlify.app/#section-accepting-specific-file-types
  const defaultValues: InvoiceClaimSchema = {
    invoiceNumber: record.label,
    invoiceDate: new Date(record.invoiceDate).toLocaleDateString(locale, {
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
    amount: 0.0,
    fileURL: null,
    description: "",
  };
  const formResolver = yupResolver(InvoiceClaimRequestValidationSchema);

  const { register, handleSubmit, formState, reset, control } =
    useForm<InvoiceClaimSchema>({ resolver: formResolver, defaultValues });

  //resets and closes form
  const onClose = () => {
    reset();
    setIsInvoiceClaimOpen(false);
  };

  //Send message to support
  const onSubmit: SubmitHandler<InvoiceClaimSchema> = async (formvalues) => {
    if (merchantId) {
      const params: ContactMerchantSupportParams = {
        requestType: SupportRequestType.INVOICE_CLAIM,
        merchantId: merchantId,
        message: formvalues.description,
        invoiceNumber: formvalues.invoiceNumber,
        invoiceDate: formvalues.invoiceDate,
        amount: currencyFormatter(
          locale,
          formvalues.amount,
          record.currency,
          1,
        ),
        supportingFileURL: formvalues.fileURL?.storagePreviewURL ?? "",
      };
      //on successful close and reset form and trigger success modal
      try {
        await dataProvider.contactMerchantSupport(resource, params);
        onClose();
        notifyDialog({
          title: translate("shared.message.submit.ok"),
          content: translate("invoices.content.details.claim.response"),
          isOpen: true,
        });
      } catch (err: unknown) {
        notify(
          translate(`${translate("shared.message.submit.fail")} (${err})`),
          {
            type: "error",
          },
        );
      }
    }
  };
  return (
    <Dialog onClose={onClose} open={isInvoiceClaimOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ p: 2 }}
          justifyContent="flex-end"
        >
          <CloseIconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </CloseIconButton>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 900, textTransform: "capitalize" }}
            >
              {translate("shipments.content.details.claim.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={translate("resources.invoices.fields.label")}
              placeholder={translate("resources.invoices.fields.label")}
              {...register("invoiceNumber")}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={translate("resources.invoices.fields.invoiceDate")}
              placeholder={translate("resources.invoices.fields.invoiceDate")}
              {...register("invoiceDate")}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyInput
              label={translate("invoices.content.details.claim.amount")}
              name="amount"
              control={control as any}
              prefix={record.currency === Currency.USD ? "$ " : "CA$ "}
              valueType={ValueType.floatValue}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={translate("invoices.content.details.claim.description")}
              placeholder={translate(
                "invoices.content.details.claim.description",
              )}
              {...register("description")}
              multiline
              rows={6}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ pl: 2, pb: 1 }}>
              <FormLabel>
                {translate("invoices.content.details.claim.supporting_file")}
              </FormLabel>
            </Box>
            <FileDrop
              accept={acceptedFileTypes}
              name={"fileURL"}
              control={control as any}
              getUploadToStoragePath={(file: File) =>
                `${STORAGE_BUCKET_FOLDERS.invoice_claim}/${nanoid()}/${
                  file.name
                }`
              }
            />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              size="medium"
              variant="contained"
              disableElevation
              loading={formState?.isSubmitting}
            >
              {translate("shared.action.submit")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: 16,
  height: 24,
  width: 24,
  position: "absolute",
  right: 16,
  top: 16,
  backgroundColor: theme.palette.neutralScale[500],
  color: theme.palette.primary.contrastText,
  ":hover": { color: theme.palette.neutralScale[400] },
}));

export default InvoiceClaimDialog;
