import {
  useTranslate,
  Loading,
  useRecordContext,
  useGetList,
} from "react-admin";
import { ActivityLog, ActivityType } from "@swyft/domain";
import { Card, Box, Typography, InboxIcon } from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import { AppResource } from "~/config/resources";

const ProofOfDelivery = () => {
  const translate = useTranslate();
  const { identity } = useAuthenticatedContext();
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList<ActivityLog>(
    AppResource.ShipmentActivityLog,
    {
      filter: {
        org: {
          id: identity?.organizationId,
        },
        label: {
          id: record.id,
        },
        type: [ActivityType.DELIVERY_ATTEMPTS],
      },
      sort: { field: "timestamp", order: "desc" },
    },
  );

  return (
    <Card sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" component="h3" sx={{ fontWeight: 600, mb: 2 }}>
        {translate("shipments.content.details.pod.title")}
      </Typography>
      {isLoading && <Loading loadingSecondary="" />}
      {error && <GracefulFallback type="error" />}
      {!!data?.length && data[0].data.photoURLs?.length ? (
        <Box
          sx={{
            display: "grid",
            gap: "16px",
            gridTemplateColumns: "repeat(auto-fit, 275px)",
          }}
        >
          {data[0].data.photoURLs.map((url: string, idx: number) => {
            return (
              <Box key={`${url}` ?? `pod-${idx}`} sx={{ width: "275px" }}>
                <img
                  alt={`${translate("shipments.content.details.pod.title")}${
                    idx + 1
                  }`}
                  src={url}
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                  }}
                />
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            color: theme.palette.neutral.dark,
          })}
        >
          <InboxIcon sx={{ width: "50px", height: "50px", mr: 2 }} />
          <Typography variant="body1">
            {translate("shipments.content.details.pod.empty")}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default ProofOfDelivery;
