import { Card, Typography, styled } from "@helo/ui";
import {
  useTranslate,
  useEditContext,
  Datagrid,
  Loading,
  FunctionField,
  useList,
  ListContextProvider,
  useGetList,
} from "react-admin";
import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { ActivityLog } from "@swyft/domain";
import {
  determineIfTrackingEvent,
  TrackingEventState,
  determineIfScanEvent,
  ScanType,
  determineIfSMSEvent,
  determineIfDeliveryAttempt,
} from "@swyft/types";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import { AppResource } from "~/config/resources";
import { DATE_FORMATS } from "~/common/consts";

const LabelActivityLog = () => {
  const translate = useTranslate();
  const { record } = useEditContext();
  const { identity } = useAuthenticatedContext();
  const [query, setQuery] = useState({
    filter: {
      org: {
        id: identity?.organizationId,
      },
      label: {
        id: record.id,
      },
    },
    sort: { field: "timestamp", order: "desc" },
  });
  const { data, total, isLoading, isFetching, error } = useGetList<ActivityLog>(
    AppResource.ShipmentActivityLog,
    { ...query },
  );
  const filteredData = useMemo(() => {
    return (
      data?.filter((log) => {
        // filter out certain events from the activity log
        if (determineIfTrackingEvent(log.data)) {
          return ![
            TrackingEventState.CONFIRMED,
            TrackingEventState.ASSIGNED,
            TrackingEventState.ARRIVING,
          ].includes(log.data.state);
        } else if (determineIfScanEvent(log.data)) {
          return ![ScanType.ARRIVAL_SCAN, ScanType.READ_ONLY_SCAN].includes(
            log.data.type,
          );
        } else {
          return true;
        }
      }) ?? []
    );
  }, [data?.length]);
  const listContext = useList({
    data: filteredData,
    isLoading,
    isFetching,
    error,
    sort: query.sort,
  });

  useEffect(() => {
    setQuery({
      ...query,
      sort: listContext.sort,
    });
  }, [listContext.sort.field, listContext.sort.order]);

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" component="h3" sx={{ fontWeight: 600, mb: 2 }}>
        {translate("shipments.content.details.activity.title")}
      </Typography>
      {isLoading && <Loading loadingSecondary="" />}
      {error && <GracefulFallback type="error" />}
      {data && (
        <ListContextProvider value={listContext}>
          <LogDatagrid
            data={data}
            total={total}
            bulkActionButtons={false}
            sort={query.sort}
          >
            <FunctionField
              source="timestamp"
              sortable={true}
              render={(record: ActivityLog) =>
                record.timestamp
                  ? format(
                      record.timestamp,
                      DATE_FORMATS.dateFns.displayFullWithTz,
                    )
                  : translate("shared.content.not_available")
              }
            ></FunctionField>
            <FunctionField
              source="type"
              render={(record: ActivityLog) =>
                translate(
                  `shipments.content.details.activity.type.${record.type}`,
                )
              }
            ></FunctionField>
            <FunctionField
              source="details"
              render={(record: ActivityLog) => {
                if (
                  determineIfTrackingEvent(record.data) &&
                  record.data.state
                ) {
                  return translate(
                    `shipments.content.details.activity.message.track.${record.data.state}`,
                  );
                } else if (
                  determineIfScanEvent(record.data) &&
                  record.data.type
                ) {
                  return translate(
                    `shipments.content.details.activity.message.scan.${record.data.type}`,
                  );
                } else if (
                  determineIfSMSEvent(record.data) &&
                  record.data.content
                ) {
                  return record.data.content;
                } else if (
                  determineIfDeliveryAttempt(record.data) &&
                  record.details
                ) {
                  return translate(
                    `shipments.content.details.activity.message.deliveryAttempt.${record.details}`,
                  );
                } else {
                  return record.details ? translate(record.details) : "";
                }
              }}
            ></FunctionField>
          </LogDatagrid>
        </ListContextProvider>
      )}
    </Card>
  );
};

const LogDatagrid = styled(Datagrid)(() => ({
  "& .RaDatagrid-headerCell": { fontWeight: 600 },
}));

export default LabelActivityLog;
