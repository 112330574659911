import { PickupLocationController } from "@swyft/domain";
import { PickupLocation } from "@swyft/types";
import {
  CreateResult,
  DataProvider,
  DeleteManyResult,
  DeleteResult,
  GetListResult,
  GetOneResult,
  UpdateResult,
} from "react-admin";
import { FirebaseOptions } from "firebase/app";

/**
 * Data Provider for the "locations" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): LocationDataProvider => {
  const pickupLocationController = new PickupLocationController(firebaseConfig);

  return {
    getOne: async (resource, params): Promise<GetOneResult> =>
      await pickupLocationController.getOne(params),

    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },

    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },

    getList: async (resource, params): Promise<GetListResult> =>
      await pickupLocationController.getList(params),

    create: async (resource, params): Promise<CreateResult> => {
      const { data } = await pickupLocationController.create(params);

      return {
        data: {
          id: data,
        },
      };
    },

    update: async (resource, params): Promise<UpdateResult> => {
      const data = await pickupLocationController.update({
        id: String(params.id),
        data: params.data as PickupLocation,
        previousData: params.previousData as PickupLocation,
        meta: params.meta,
      });

      return data;
    },

    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },

    delete: async (resource, params): Promise<DeleteResult> => {
      const data = await pickupLocationController.delete({
        id: String(params.id),
      });

      return { data };
    },

    deleteMany: async (resource, params): Promise<DeleteManyResult> => {
      const data = await pickupLocationController.deleteMany({
        ids: params.ids.map((id) => String(id)),
      });

      return { data };
    },
  };
};

export interface LocationDataProvider extends DataProvider {}
