import {
  CreateResult,
  DeleteManyResult,
  GetListResult,
  GetOneResult,
  DeleteResult,
  DataProvider,
} from "react-admin";
import { RatesController } from "@swyft/domain";
import { FirebaseOptions } from "firebase/app";

/**
 * Data Provider for the rates resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): RateDataProvider => {
  const ratesController = new RatesController(firebaseConfig);

  return {
    getList: async (resource, params): Promise<GetListResult> => {
      const rates = await ratesController.getList(params);
      return {
        data: rates,
        total: rates.length,
      };
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      //TODO: leverage this to return rates by id via the rates service
      throw new Error("Function not implemented.");
    },
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: async (resource, params): Promise<CreateResult> => {
      throw new Error("Function not implemented.");
    },
    update: async (resource, params): Promise<CreateResult> => {
      throw new Error("Function not implemented.");
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      throw new Error("Function not implemented.");
    },
    deleteMany: async (resource, params): Promise<DeleteManyResult> => {
      throw new Error("Function not implemented.");
    },
  };
};

export interface RateDataProvider extends DataProvider {}
