import { useTranslate } from "react-admin";
import { Breadcrumbs, Link, Stack, Skeleton, styled, Card } from "@helo/ui";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Integration } from "@swyft/swyft-common";

import { AppViewLayout } from "~/layouts/app";

const StoresList = ({ data, isLoading }: StoreProps) => {
  useDefineAppLocation("menu.settings.integrations.stores");
  const translate = useTranslate();

  return (
    <AppViewLayout
      title={translate("menu.settings.integrations.stores")}
      breadcrumbs={
        <Breadcrumbs>
          <Link underline="none" color="text.primary">
            {translate("stores.description")}
          </Link>
        </Breadcrumbs>
      }
    >
      <StoresListTable data={data} isLoading={isLoading} />
    </AppViewLayout>
  );
};

const StoreCard = ({ data }: StoreCardProps) => {
  return (
    <Card sx={{ width: "120px", height: "120px", padding: "10px" }}>
      <img
        key={data.id}
        src={data.logo}
        alt={data.name}
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    </Card>
  );
};

const StoresListTable = ({ data, isLoading }: StoreProps) => {
  if (isLoading) {
    return (
      <Stack spacing={2} direction="row">
        {[...Array(5)].map((...[, idx]) => (
          <Skeleton
            width={120}
            height={120}
            variant="rectangular"
            key={`${idx}-skeleton`}
          />
        ))}
      </Stack>
    );
  }

  return (
    <StoresContainer>
      {data &&
        data.map((store: Integration) => (
          <StoreCard key={store.id} data={store} />
        ))}
    </StoresContainer>
  );
};

const StoresContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  flexWrap: "wrap",
  gap: 20,
});

export interface StoreCardProps {
  data: Integration;
}

interface StoreProps {
  data: Integration[] | undefined;
  isLoading: boolean;
}

export default StoresList;
