import { useTranslate, useNotify, useEditContext } from "react-admin";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Dialog,
  Typography,
  IconButton,
  CloseIcon,
  TextField,
  LoadingButton,
  Grid,
  styled,
} from "@helo/ui";
import {
  ContactMerchantSupportParams,
  SupportRequestType,
} from "@swyft/domain";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useNotifyDialog } from "~/components/feedback/GlobalAlerts";
import { useMerchantDataProvider } from "~/services/data/merchant";

interface SupportFormValues {
  trackingNumber: string;
  description: string;
}

interface SupportProps {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
}

const LabelSupport = ({ isOpen, setIsOpen }: SupportProps) => {
  const [resource, dataProvider] = useMerchantDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const notifyDialog = useNotifyDialog();
  const { record } = useEditContext();
  const { merchant } = useAuthenticatedContext();
  const merchantId = merchant?.id;

  const { register, handleSubmit, formState, reset } =
    useForm<SupportFormValues>();

  //resets and closes form
  const onClose = () => {
    reset();
    setIsOpen(false);
  };

  //Send message to support
  const onSubmit: SubmitHandler<SupportFormValues> = async (formvalues) => {
    if (merchantId) {
      const params: ContactMerchantSupportParams = {
        requestType: SupportRequestType.LABEL_SUPPORT,
        merchantId,
        trackingNumber: formvalues.trackingNumber,
        message: formvalues.description,
      };
      //on successful close and reset form and trigger success modal
      try {
        await dataProvider.contactMerchantSupport(resource, params);
        onClose();
        notifyDialog({
          title: translate("shared.message.submit.ok"),
          content: translate("shipments.content.details.support.response"),
          isOpen: true,
        });
      } catch (err: unknown) {
        notify(
          translate(`${translate("shared.message.submit.fail")} (${err})`),
          {
            type: "warning",
          },
        );
      }
    }
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ p: 2 }}
          justifyContent="flex-end"
        >
          <CloseIconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </CloseIconButton>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 900, textTransform: "capitalize" }}
            >
              {translate("shipments.content.details.support.title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={translate("resources.shipments.fields.trackingNumber")}
              placeholder={translate(
                "recources.shipments.fields.trackingNumber",
              )}
              {...register("trackingNumber")}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              value={record.trackingNumber}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={translate("shipments.content.details.claim.description")}
              placeholder={translate(
                "shipments.content.details.claim.description",
              )}
              {...register("description")}
              multiline
              rows={6}
              fullWidth
              required
            />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              size="medium"
              variant="contained"
              disableElevation
              loading={formState?.isSubmitting}
            >
              {translate("shared.action.submit")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: 16,
  height: 24,
  width: 24,
  position: "absolute",
  right: 16,
  top: 16,
  backgroundColor: theme.palette.neutralScale[500],
  color: theme.palette.primary.contrastText,
  ":hover": { color: theme.palette.neutralScale[400] },
}));

export default LabelSupport;
