import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useReducer,
} from "react";
import { useUpdate } from "react-admin";
import { Merchant } from "@swyft/types";

import BusinessInfoStep from "~/features/onboarding/merchant/steps/BusinessInfoStep";
import PickupLocationConfigStep from "~/features/onboarding/merchant/steps/PickupLocationConfigStep";
import ShipmentsStep from "~/features/onboarding/merchant/steps/ShipmentsStep";
import FinishStep from "~/features/onboarding/merchant/steps/FinishStep";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { AppResource } from "~/config/resources";

interface MerchantOnboardingContextType extends StepState {
  currentStep: Step;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  markStepAsComplete: (stepId: string, merchantId?: string) => Promise<void>;
  isLoading: boolean;
}

interface Step {
  id: string;
  title: string;
  subtitle: string;
  label: string;
  isComplete: boolean;
  content: ReactElement;
}

const OnboardingSteps = [
  {
    id: "sign-up",
    title: "Sign up",
    subtitle: "Create a new merchant account",
    label: "Sign up",
    content: <></>,
    isComplete: true,
  },
  {
    id: "org-info",
    title: "Organization Information",
    subtitle: "Tell us more about your business",
    label: "Your business info",
    content: <BusinessInfoStep />,
    isComplete: false,
  },
  {
    id: "pickup-info",
    title: "Pickup Location Configuration",
    subtitle: "Where can we pickup your packages?",
    label: "Configure pickup locations",
    content: <PickupLocationConfigStep />,
    isComplete: false,
  },
  {
    id: "shipments",
    title: "Create a Shipment",
    subtitle:
      "A shipment is made by first creating a label for a package. You can make a test label below to understand how Swyft works.",
    label: "Create shipment",
    content: <ShipmentsStep />,
    isComplete: false,
  },
  {
    id: "next-steps",
    title: "Next Steps",
    subtitle:
      "There's a lot you can do with Swyft. Here are a few things you can do next...",
    label: "Finish",
    content: <FinishStep />,
    isComplete: false,
  },
];

const MerchantOnboardingContext =
  createContext<MerchantOnboardingContextType | null>(null);

export const useMerchantOnboardingContext = () => {
  const context = useContext(MerchantOnboardingContext);

  if (context === null) {
    throw new Error("Must be called within MerchantOnboardingContextProvider");
  }

  return context;
};

export function MerchantOnboardingContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const { merchant } = useAuthenticatedContext();
  const [update] = useUpdate<Merchant>();

  // get the saved step from the merchant document, if available
  const getSavedStepFromOnboarding = (): StepState => {
    let currentStepIdx = 1; // step @ index 0 is a dummy step, so start at step @ index 1 instead

    // if org assoc exists, check for the onboarding property
    if (!!merchant) {
      // if onboarding state exists, and is not complete, get the last completed step
      if (merchant.onboarding && !merchant.onboarding.complete) {
        currentStepIdx =
          OnboardingSteps.findIndex(
            (s) => s.id === merchant.onboarding?.lastCompletedStep,
          ) + 1;
      }

      // if onboarding state does not exist but merchant association does, skip org creation
      if (!merchant.onboarding) {
        currentStepIdx = 2; //create pickupLocation
      }
    }

    // mark all steps before the current step as complete
    const steps = OnboardingSteps.map((step, idx) => {
      step.isComplete = idx < currentStepIdx;

      return step;
    });

    return {
      steps,
      currentStepIdx: currentStepIdx <= 0 ? 1 : currentStepIdx,
    };
  };

  const [stepState, dispatch] = useReducer(
    stepReducer,
    null,
    getSavedStepFromOnboarding,
  );
  const goToNextStep = () => {
    dispatch({ type: "increment" });
  };
  const goToPreviousStep = () => {
    // TODO: if navigating to step 1, logout and navigate to the sign-up page?
    dispatch({ type: "decrement" });
  };
  const markStepAsComplete = async (stepId: string, merchantId?: string) => {
    // update onboarding progress
    if (!!merchant) {
      await update(
        AppResource.Merchant,
        {
          id: merchant.id,
          data: {
            onboarding: {
              complete: stepState.currentStepIdx >= stepState.steps.length - 1, // if final step, mark onboarding as a whole complete
              lastCompletedStep: stepId,
            },
          },
          previousData: merchant,
        },
        {
          returnPromise: true,
        },
      );
    } else if (merchantId) {
      await update(
        AppResource.Merchant,
        {
          id: merchantId,
          data: {
            onboarding: {
              complete: stepState.currentStepIdx >= stepState.steps.length - 1, // if final step, mark onboarding as a whole complete
              lastCompletedStep: stepId,
            },
          },
          previousData: merchant,
        },
        {
          returnPromise: true,
        },
      );
    }

    dispatch({ type: "complete" });
  };

  return (
    <MerchantOnboardingContext.Provider
      value={{
        ...stepState,
        currentStep: stepState.steps[stepState.currentStepIdx],
        goToNextStep,
        goToPreviousStep,
        markStepAsComplete,
        isLoading: false,
      }}
    >
      {children}
    </MerchantOnboardingContext.Provider>
  );
}

function stepReducer(state: StepState, action: StepAction): StepState {
  const { steps, currentStepIdx } = state;
  const { type } = action;

  switch (type) {
    case "increment":
      if (currentStepIdx + 1 > steps.length) {
        return state;
      }

      return {
        steps,
        currentStepIdx: currentStepIdx + 1,
      };
    case "decrement":
      if (currentStepIdx - 1 < 0) {
        return state;
      }

      return {
        steps,
        currentStepIdx: currentStepIdx - 1,
      };
    case "complete":
      const stepsCopy = steps.slice();
      const matchingStep = stepsCopy[currentStepIdx];

      if (!matchingStep) {
        return state;
      }

      matchingStep.isComplete = true;

      return {
        steps: stepsCopy,
        currentStepIdx,
      };
    default:
      throw Error("Unknown action: " + type);
  }
}

type StepState = {
  steps: Step[];
  /** 0-indexed marker of the current step */
  currentStepIdx: number;
};

type StepAction = {
  type: "increment" | "decrement" | "complete";
};
