import { useRedirect } from "react-admin";
import { PropsWithChildren, useEffect } from "react";
import { styled } from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import BaseLayout from "~/layouts/BaseLayout";
import { Routes } from "~/config/Routes";
import AuthenticatedLayout from "./AuthenticatedLayout";
import Navbar from "~/components/navigation/navbar";
import Footer from "~/components/navigation/footer";

const AppShellLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <AuthenticatedLayout>
      <AppShellLayoutCore>{children}</AppShellLayoutCore>
    </AuthenticatedLayout>
  );
};

const AppShellLayoutCore = ({ children }: PropsWithChildren<{}>) => {
  const { identity, user, merchant } = useAuthenticatedContext();
  const redirect = useRedirect();

  useEffect(() => {
    // if the org association exists through claims or on the user document, AND onboarding is complete, optimistically take them to the main app experience
    if (
      (identity?.organizationId || user?.organizationId) &&
      merchant?.onboarding?.complete
    ) {
      redirect(Routes.Root);
    }
  }, [identity?.organizationId, user?.organizationId]);

  return (
    <BaseLayout>
      <PageContainer>
        <Navbar />
        {children}
        <Footer />
      </PageContainer>
    </BaseLayout>
  );
};

const PageContainer = styled("div")({
  height: "fit-content",
});

export default AppShellLayout;
