import { styled } from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import AppViewLayout from "~/layouts/app/AppViewLayout";

const Performance = () => {
  const { merchant } = useAuthenticatedContext();

  return (
    <AppViewLayout title="pages.performance.title">
      {merchant?.id && (
        <PerformanceIframe
          src={`https://datastudio.google.com/embed/u/1/reporting/ed2447be-4a36-429e-a9e6-aa9cf2a3c577/page/p_iphkdqlizc?params={"df15":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${merchant.id}"}`}
          frameBorder="0"
          allowFullScreen
          id={`performance-${merchant.name}-embed`}
        />
      )}
    </AppViewLayout>
  );
};

export default Performance;

const PerformanceIframe = styled("iframe")(({ theme }) => ({
  height: "900px",
  width: "100%",
  margin: "0 auto",
  maxWidth: theme.breakpoints.values.xl,
  alignSelf: "center",
}));
