import { useEffect, useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import moment from "moment";
import {
  DATE_FORMAT,
  CountryISO2,
  ServiceType,
  IPickupLocation,
  ICreateLabelCallableDashboardRequest,
  LabelCreationMethod,
  ICreateLabelCallableMerchantRequest,
} from "@swyft/swyft-common";
import { getEarliestShipDate } from "@swyft/swyft-common/lib/helpers/ShipDateHelpers";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Modal,
  Typography,
  Checkbox,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import { MerchantController } from "@swyft/domain";
import { ServiceAddOns } from "@swyft/types";

import * as FirestoreService from "~/services/firestore";
import { COMPANY_NAME, DATE_FORMATS } from "~/common/consts";
import { isEmpty, isUndefined } from "lodash";
import { validateDims } from "~/common/labelHelpers";
import { exportPDF } from "~/common/fileExportHelper";
import { GENERIC_ERROR_MESSAGE } from "~/common/consts";
import { ICreateLabelFormValues, CreateLabelFormProps } from "./types";
import ActionAlert from "~/components/feedback/ActionAlert";
import { Routes } from "~/config/Routes";
import { useLabelDataProvider } from "~/services/data/shipment";
import { AppResource } from "~/config/resources";

const { Text } = Typography;

export const CreateLabelForm = ({
  merchant,
  isMerchantGroup,
}: CreateLabelFormProps) => {
  const [form] = Form.useForm<ICreateLabelFormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pickupLocation, setPickupLocation] = useState<IPickupLocation>(
    {} as IPickupLocation,
  );
  const [shipDate, setShipDate] = useState<string>("");
  const [showActionAlert, setShowActionAlert] = useState<boolean | null>(null);

  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();
  const [resource, dataProvider] = useLabelDataProvider();

  // derived values
  const isOrgActive = merchant.isActive;
  const isInvalid =
    isEmpty(merchant) || isEmpty(pickupLocation) || !isOrgActive;
  const { Option } = Select;

  useEffect(() => {
    if (!isEmpty(merchant)) {
      fetchPickupLocation(merchant.id);
    }
  }, [merchant]);

  useEffect(() => {
    if (!isEmpty(merchant)) {
      setFormDefaults();

      if (!pickupLocation) {
        setShowActionAlert(true);
      }
    }
  }, [merchant, pickupLocation]);

  const fetchPickupLocation = async (merchantId: string) => {
    try {
      const pickupLocationData =
        await FirestoreService.getPickupLocationsForMerchantId(merchantId);
      setPickupLocation(pickupLocationData[0]);
    } catch (err) {
      notify(GENERIC_ERROR_MESSAGE, {
        type: "warning",
        autoHideDuration: 2000,
      });
      console.error(err);
    }
  };

  const setFormDefaults = () => {
    if (!pickupLocation) {
      return;
    }

    const { cutoffTime } = pickupLocation;

    if (!isUndefined(cutoffTime)) {
      const shipDate = getEarliestShipDate(pickupLocation);

      form.setFieldsValue({
        shipDate: shipDate.format("MMM DD, YYYY"),
        merchantName: merchant.name,
      });
    }
  };

  const resetForm = () => {
    form.resetFields();
    setFormDefaults();
  };

  const createLabel = async (values: ICreateLabelFormValues) => {
    if (!isOrgActive) {
      notify("shipments.message.create.unactive_fail", {
        type: "warning",
      });
      return;
    }

    if (isInvalid) {
      notify("shared.message.create.invalid", {
        type: "warning",
        messageArgs: {
          name: translate("resources.labels.name", { smart_count: 1 }),
          smart_count: 1,
        },
      });
      return;
    }

    setIsLoading(true);

    // Convert the human readable date back to the required format
    const currentShipDate = moment(values.shipDate, "MMM DD, YYYY").format(
      DATE_FORMAT.SHIP_DATE,
    );

    const earliestShipDate = getEarliestShipDate(pickupLocation);

    setShipDate(earliestShipDate.format(DATE_FORMAT.READABLE_SHIP_DATE));

    const formattedEarliestShipDate = earliestShipDate.format(
      DATE_FORMAT.SHIP_DATE,
    );

    const currentDate = moment().format(DATE_FORMAT.SHIP_DATE);

    if (currentShipDate !== formattedEarliestShipDate) {
      setIsModalVisible(true);
    } else {
      const createLabelRequest: ICreateLabelCallableMerchantRequest = {
        firstName: values.firstName,
        lastName: values.lastName,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        postalCode: values.postalCode,
        province: values.province,
        country: values.country,
        phoneNumber: values.phoneNumber,
        email: values.email,
        orderNumber: values.orderNumber,
        notes: values.notes,
        shipDate: currentShipDate,
        serviceType:
          currentShipDate === currentDate
            ? ServiceType.SAME_DAY
            : ServiceType.NEXT_DAY,
        dims: {
          length: values.length,
          width: values.width,
          height: values.height,
          weight: values.weight,
        },
        signatureRequired: values.signatureRequired,
        creationMethod: LabelCreationMethod.MERCHANT_DASHBOARD,
      };

      try {
        validateDims([createLabelRequest]);

        let labelId: string;
        if (isMerchantGroup) {
          const merchantGroupcreateLabelRequest: ICreateLabelCallableDashboardRequest =
            {
              ...createLabelRequest,
              slug: merchant.slug,
            };
          labelId = await FirestoreService.merchantGroupCreateLabel(
            merchantGroupcreateLabelRequest,
          );
        } else {
          labelId = await FirestoreService.createLabel(createLabelRequest);
        }

        const label = await FirestoreService.getLabelByMerchantIdAndLabelId(
          merchant.id,
          labelId,
        );

        const {
          destination: { firstName, lastName },
          shipDate,
        } = label;
        const formattedDate = moment(shipDate).format(
          DATE_FORMATS.moment.export,
        );
        const fileName = `${COMPANY_NAME.toLowerCase()}-label--${formattedDate}-${firstName}-${lastName}.pdf`;

        const { data } = await dataProvider.getPrintPdfs(AppResource.Shipment, {
          labelIds: [labelId],
        });
        await exportPDF(data, fileName);
        resetForm();
        notify("shipments.message.create.ok", {
          type: "success",
          autoHideDuration: 2000,
        });
      } catch (err: any) {
        console.error(err);
        notify(
          `${translate("shipments.message.create.fail")} ${
            err?.message ?? JSON.stringify(err)
          }`,
          { type: "warning", autoHideDuration: 2000 },
        );
      }
    }
    setIsLoading(false);
  };

  const handleConfirm = () => {
    form.setFieldsValue({
      shipDate: shipDate,
    });

    createLabel(form.getFieldsValue());

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.setFieldsValue({
      shipDate: shipDate,
    });

    setIsModalVisible(false);
  };

  return (
    <>
      {showActionAlert && isOrgActive && (
        <ActionAlert
          alertSeverity="warning"
          alertMessage={translate("location.message.create.pickup_req_warn")}
          actionText={translate("shared.content.create.title", {
            name: translate("resources.locations.name", { smart_count: 1 }),
          })}
          actionHandler={() => navigate(`${Routes.Locations}/create`)}
          sx={{
            marginBottom: 2,
          }}
        />
      )}
      <Form form={form} layout="vertical" onFinish={createLabel}>
        {isMerchantGroup ? (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={translate("createLabelForm.shipDate")}
                name="shipDate"
              >
                <Input
                  disabled
                  placeholder={translate("createLabelForm.shipDate")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate("createLabelForm.merchantName")}
                name="merchantName"
              >
                <Input
                  disabled
                  placeholder={translate("createLabelForm.merchantName")}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row>
            <Form.Item
              label={translate("createLabelForm.shipDate")}
              name="shipDate"
            >
              <Input
                disabled
                placeholder={translate("createLabelForm.shipDate")}
              />
            </Form.Item>
          </Row>
        )}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={translate("createLabelForm.firstName.label")}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: translate("createLabelForm.firstName.error"),
                },
              ]}
            >
              <Input
                tabIndex={1}
                disabled={isInvalid}
                placeholder={translate("createLabelForm.firstName.label")}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={translate("createLabelForm.address.line1.label")}
              name="addressLine1"
              rules={[
                {
                  required: true,
                  message: translate("createLabelForm.address.line1.error"),
                },
              ]}
            >
              <Input
                tabIndex={3}
                disabled={isInvalid}
                placeholder={translate("createLabelForm.address.line1.label")}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={translate("createLabelForm.city.label")}
              name="city"
              rules={[
                {
                  required: true,
                  message: translate("createLabelForm.city.error"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                disabled={isInvalid}
                tabIndex={5}
                placeholder={translate("createLabelForm.city.label")}
              />
            </Form.Item>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label={translate("createLabelForm.phoneNumber.label")}
                  name="phoneNumber"
                  tooltip={translate("createLabelForm.phoneNumber.toolTip")}
                  rules={[
                    {
                      required: true,
                      message: translate("createLabelForm.phoneNumber.error"),
                    },
                  ]}
                >
                  <Input
                    tabIndex={8}
                    disabled={isInvalid}
                    type="tel"
                    placeholder={translate("createLabelForm.phoneNumber.label")}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={translate("createLabelForm.email.label")}
                  name="email"
                >
                  <Input
                    disabled={isInvalid}
                    tabIndex={9}
                    type="email"
                    placeholder={translate("createLabelForm.email.label")}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate("createLabelForm.lastName.label")}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: translate("createLabelForm.lastName.error"),
                },
              ]}
            >
              <Input
                tabIndex={2}
                disabled={isInvalid}
                placeholder={translate("createLabelForm.lastName.label")}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={translate("createLabelForm.address.line2.label")}
              name="addressLine2"
            >
              <Input
                disabled={isInvalid}
                tabIndex={4}
                placeholder={translate("createLabelForm.address.line2.label")}
                autoComplete="off"
              />
            </Form.Item>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label={translate("createLabelForm.postal.label")}
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: translate("createLabelForm.postal.error"),
                    },
                  ]}
                >
                  <Input
                    tabIndex={6}
                    disabled={isInvalid}
                    placeholder={translate(
                      "createLabelForm.postal.placeholder",
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={translate("createLabelForm.province.label")}
                  name="province"
                >
                  <Input disabled={isInvalid} tabIndex={7} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={translate("createLabelForm.country.label")}
                  name="country"
                >
                  <Select
                    disabled={isInvalid}
                    placeholder={translate(
                      "shared.form.placeholder.select_country",
                    )}
                  >
                    <Option value={CountryISO2.CANADA}>
                      {translate("shared.form.placeholder.country.CA")}
                    </Option>
                    <Option value={CountryISO2.USA}>
                      {translate("shared.form.placeholder.country.US")}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={translate("createLabelForm.orderNumber.label")}
              name="orderNumber"
              tooltip={translate("createLabelForm.orderNumber.tooltip")}
            >
              <Input
                tabIndex={10}
                disabled={isInvalid}
                type="text"
                placeholder={translate("createLabelForm.orderNumber.label")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label={translate("createLabelForm.notes.label")}
              name="notes"
              tooltip={translate("createLabelForm.notes.tooltip")}
            >
              <Input
                tabIndex={11}
                disabled={isInvalid}
                type="text"
                placeholder={translate("createLabelForm.notes.label")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={translate("createLabelForm.weight.label")}
              name="weight"
              rules={[
                {
                  required: true,
                  message: translate("createLabelForm.weight.error"),
                },
              ]}
            >
              <Input
                tabIndex={12}
                disabled={isInvalid}
                type="number"
                placeholder={translate("createLabelForm.weight.label")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label={translate("createLabelForm.length.label")}
                  name="length"
                  rules={[
                    {
                      required: true,
                      message: translate("createLabelForm.length.error"),
                    },
                  ]}
                >
                  <Input
                    disabled={isInvalid}
                    tabIndex={13}
                    type="number"
                    placeholder={translate("createLabelForm.length.label")}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={translate("createLabelForm.width.label")}
                  name="width"
                  rules={[
                    {
                      required: true,
                      message: translate("createLabelForm.width.error"),
                    },
                  ]}
                >
                  <Input
                    tabIndex={14}
                    disabled={isInvalid}
                    type="number"
                    placeholder={translate("createLabelForm.width.label")}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={translate("createLabelForm.height.label")}
                  name="height"
                  rules={[
                    {
                      required: true,
                      message: translate("createLabelForm.height.error"),
                    },
                  ]}
                >
                  <Input
                    disabled={isInvalid}
                    tabIndex={15}
                    type="number"
                    placeholder={translate("createLabelForm.height.label")}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          {MerchantController.isRateCardAddOnAvailable(
            merchant,
            ServiceAddOns.SIGNATURE,
          ) && (
            <Col span={6}>
              <Form.Item
                aria-label={translate("createLabelForm.signature.label")}
                name="signatureRequired"
                valuePropName="checked"
              >
                <Checkbox disabled={isInvalid}>
                  {translate("createLabelForm.signature.label")}
                </Checkbox>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Form.Item>
          <Space>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              disabled={isInvalid}
            >
              {translate("createLabelForm.create")}
            </Button>
            <Button
              disabled={isInvalid}
              type="default"
              htmlType="submit"
              onClick={resetForm}
            >
              {translate("createLabelForm.clear")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <Modal
        visible={isModalVisible}
        closable={false}
        width={"450px"}
        footer={null}
      >
        <Row
          justify="center"
          align="middle"
          style={{ margin: ".6em 0 .6em 0" }}
        >
          <Text style={{ fontSize: "25px" }}>
            <b> {translate("createLabelForm.confirmation")}</b>
          </Text>
        </Row>
        <Row
          justify="center"
          align="middle"
          style={{ margin: "10px 70px 10px 70px" }}
        >
          <Text style={{ textAlign: "center", fontSize: "16px" }}>
            {translate("createLabelForm.shipDateConfirmation.part1")}
            <b> {`${shipDate}`}</b>{" "}
            {translate("createLabelForm.shipDateConfirmation.part2")}
          </Text>
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: "1.6em" }}>
          <Col style={{ padding: ".6em" }}>
            <Button
              type="default"
              onClick={handleCancel}
              style={{
                backgroundColor: "lightgrey",
                color: "white",
                width: "10em",
              }}
            >
              {translate("createLabelForm.cancel")}
            </Button>
          </Col>
          <Col style={{ padding: ".6em" }}>
            <Button
              type="primary"
              onClick={handleConfirm}
              style={{ width: "10em" }}
            >
              {translate("createLabelForm.confirm")}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
