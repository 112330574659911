import { ILabelV2 } from "@swyft/swyft-common";
import { message } from "antd";
import moment from "moment";
import {
  CSV_EXPORT_COLUMNS,
  getTrackingPageUrl,
  GENERIC_ERROR_MESSAGE,
} from "~/common/consts";
import { Timestamp } from "firebase/firestore";

/**
 * LabelsV1 suffer from a malformed completion time bug and a string timestamp bug.
 * @todo Remove the following after LabelsV2 migration, where these issues are fixed.
 */
const formatCompletionTime = (completionTime: any) => {
  if (
    completionTime.seconds !== undefined &&
    completionTime.nanoseconds !== undefined
  ) {
    return moment((completionTime as Timestamp).toDate()).format();
  } else if (completionTime?._seconds !== undefined) {
    let completionDate = new Date(Date.UTC(1970, 0, 1));
    completionDate.setUTCSeconds(completionTime._seconds);
    return moment(completionDate).format();
  } else if (typeof completionTime === "string") {
    return moment(completionTime).format();
  }
};

/**
 * Download an array of labels rendered as a CSV.
 *
 * @param fileName - Name of the file to be downloaded.
 * @param labels - Array of labels to be rendered.
 */
export const exportLabelsCSV = async (fileName: string, labels: ILabelV2[]) => {
  try {
    const formattedLabels = labels.map((label: ILabelV2) => ({
      ...label,
      addressLine1: label.destination.address.line1,
      addressLine2: label.destination.address.line2,
      city: label.destination.address.city,
      province: label.destination.address.province,
      postalCode: label.destination.address.postalCode,
      country: label.destination.address.country,
      recipientName: `${label.destination.firstName}${
        label.destination.lastName ? " " + label.destination.lastName : ""
      }`,
      ...(label.createdAt && {
        createdAt: moment((label.createdAt as Timestamp).toDate()).format(),
      }),
      ...(label.completionTime && {
        completionTime: formatCompletionTime(label.completionTime),
      }),
      deliveryServiceType: label.serviceType,
      packageType: label.packageType,
      trackingUrl: getTrackingPageUrl(label.trackingNumber),
      photoURLs: label.photoURLs?.join(", "),
    }));

    // Build header
    let csvContent = CSV_EXPORT_COLUMNS.join(",") + "\n";
    // Add the rows
    formattedLabels.forEach((label: any) => {
      csvContent +=
        CSV_EXPORT_COLUMNS.map((key) =>
          label[key] ? `"${label[key]}"` : "",
        ).join(",") + "\n";
    });

    exportFile(fileName, csvContent, "csv");
  } catch (err) {
    message.error(GENERIC_ERROR_MESSAGE);
  }
};

const downloadURI = (uri: string): Promise<string> => {
  return new Promise((resolves, reject) => {
    const xmlhttp = new XMLHttpRequest();
    try {
      xmlhttp.open("GET", uri);
      xmlhttp.onreadystatechange = () => {
        if (xmlhttp.readyState === 4) {
          if (xmlhttp.status == 200) {
            resolves(xmlhttp.response);
          } else {
            reject();
          }
        }
      };
      xmlhttp.responseType = "blob";
      xmlhttp.send();
    } catch (error) {
      reject();
    }
  });
};

/**
 * Prints an array of label. Rendered as PDF using PdfService.
 *
 * @param pdfUrl - pdf url string
 * @param fileName - The name of the file,
 * @param openNewTab  - When true will also open the rendered PDF in a new tab.
 */
export const exportPDF = async (
  pdfUrl: string,
  fileName: string,
  openNewTab: boolean = false,
) => {
  try {
    const fileContent = await downloadURI(pdfUrl);
    exportFile(fileName, fileContent, "application/pdf", openNewTab);
  } catch (err) {
    throw new Error(`Failed to export file: ${err}`);
  }
};

/**
 * Download a file through the browser.
 *
 * @param fileName - Name of the file to be downloaded.
 * @param contents - Rendered content of the file.
 * @param fileType - File extension.
 * @param openNewTab - When set to true will attempt to open the file in a new tab.
 */
export const exportFile = (
  fileName: string,
  contents: BlobPart,
  fileType: string,
  openNewTab: boolean = false,
) => {
  const blob = new Blob([contents], { type: fileType } as BlobPropertyBag);
  const blobURL = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = blobURL;
  link.setAttribute("target", "_blank");
  link.download = fileName;

  if (openNewTab) {
    let newTab = window.open();
    if (newTab) {
      newTab.location.href = blobURL;
    }
  }
  link.click();
};
