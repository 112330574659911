import {
  CountryISO2,
  Currency,
  Invoice,
  PackageSize,
  RateCardType,
  ServiceType,
  ZoneStatus,
} from "@swyft/types";
import { Feature, MultiPolygon, Polygon } from "@turf/helpers";

/*
  The types in this file are meant to be used for display purposes in this app alone. Think of these types as View Models.
*/
export interface MapZone {
  id: string;
  name: string;
  originPostalCodes: string[];
  destinationPostalCodes: string[];
  minDeliveryDays: number;
  maxDeliveryDays: number;
  country: CountryISO2;
  marketId: string;
  coverageFileUrl: string | null;
  trailing90OTDUrl: string | null;
  createdAt: Date;
  updatedAt: Date;
  /** URL where the geo-json polygon is stored */
  polygonUrl?: string;
  /** geo-json polygon representation */
  polygon?: Feature<Polygon | MultiPolygon> | null;
  status?: ZoneStatus;
  rate?: any;
}
//Rate card display types specific to marketplace app
export interface DisplayAddOns {
  addOn: string;
  rate: number;
}

export interface DisplayRateEntries {
  maxWeight?: number;
  minWeight?: number;
  rate: number | undefined;
  serviceType?: ServiceType;
  packageSize: PackageSize;
  redeliveryRate?: number;
}

export interface DisplayServiceLevel {
  NEXTDAY: DisplayRateEntries[];
  SAMEDAY: DisplayRateEntries[];
}

export interface DisplayRates {
  type: RateCardType;
  currency: Currency | undefined;
  addOns: DisplayAddOns[];
  serviceLevel: DisplayServiceLevel;
  entries?: DisplayRateEntries[];
  allowedDeliveryAttempts: number;
}

export interface Market {
  id: string;
  name: string;
  polygon: string | null;
}

export interface ViewInvoice extends Invoice {
  status: InvoiceStatus;
}

export enum InvoiceStatus {
  INVOICED = "INVOICED",
  PAID = "PAID",
  OVERDUE = "OVERDUE",
}
