import {
  query,
  collection,
  collectionGroup,
  where,
  limit,
  orderBy,
  getDocs,
  getDoc,
  doc,
  DocumentSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Moment } from "moment";
import {
  ILabelV2,
  TrackingEventState,
  IFulfillExternalOrdersResponse,
  IDeliveryAttempt,
  IPickupLocation,
  ICreateLabelCallableDashboardRequest,
} from "@swyft/swyft-common";
import { firebaseDb, firebaseFunctions } from "~/services/firebase";
import { Collections } from "@mui/icons-material";

// 540 seconds is the max function timeout setting this to 5 second more
const CLOUD_FUNCTION_MAX_TIMEOUT = 545000;

export const getUser = (uid: string) => {
  return getDocs(
    query(collection(firebaseDb, "users"), where("id", "==", uid), limit(1)),
  );
};

export const getMerchant = (merchantId: string) => {
  return getDoc(doc(firebaseDb, "merchants", merchantId));
};

export const getMerchantGroup = (merchantGroupId: string) => {
  return getDoc(doc(firebaseDb, "merchantGroups", merchantGroupId));
};

export const getLabelsV2Collection = (merchantId: string) => {
  return collection(firebaseDb, `merchants/${merchantId}/labelsV2`);
};

const getPickupLocationsCollection = (merchantId: string) => {
  return collection(firebaseDb, `merchants/${merchantId}/pickup-locations`);
};

export const getLabelsForDateRange = async (
  merchantId: string,
  dateRangeStart: Moment,
  dateRangeEnd: Moment,
) => {
  const result = await getDocs(
    query(
      getLabelsV2Collection(merchantId),
      where("createdAt", ">=", dateRangeStart.startOf("day").toDate()),
      where("createdAt", "<=", dateRangeEnd.endOf("day").toDate()),
      orderBy("createdAt", "desc"),
    ),
  );

  const labels = result.docs.map((docSnapshot: DocumentSnapshot) =>
    docSnapshot.data(),
  );

  return labels as ILabelV2[];
};

/**
 * Fetches the latest delivery attempt for the given label
 * @param labelId  id of label to fetch delivery attempt for
 * @param merchantId id of merchant
 * @returns the latest delivery attempt from db
 */
export const getLatestDeliveryAttemptForLabel = async (
  labelId: string,
  merchantId: string,
): Promise<IDeliveryAttempt> => {
  const result = await getDocs(
    query(
      collection(
        doc(getLabelsV2Collection(merchantId), labelId),
        "deliveryAttempts",
      ),
      orderBy("completionTime", "desc"),
      limit(1),
    ),
  );

  return result.docs[0]?.data() as IDeliveryAttempt;
};

/**
 * Gets a label by merchant and label ID
 * @param labelId
 * @param merchantId
 * @returns the label
 */
export const getLabelByMerchantIdAndLabelId = async (
  merchantId: string,
  labelId: string,
): Promise<ILabelV2> => {
  const result = await getDocs(
    query(
      collectionGroup(firebaseDb, "labelsV2"),
      where("merchantId", "==", merchantId),
      where("id", "==", labelId),
    ),
  );
  return result.docs[0].data() as ILabelV2;
};

export const deleteLabel = async (labelId: string) => {
  try {
    const deleteLabel = httpsCallable(
      firebaseFunctions,
      "httpsOnCallDeleteLabelWithLabelId",
    );
    await deleteLabel({
      labelId,
    });
  } catch (error) {
    return error;
  }
};

/**
 * Fulfill external orders for given labels
 * @param labelIds
 */
export const fulfillExternalOrders = async (
  labelIds: string[],
): Promise<IFulfillExternalOrdersResponse> => {
  const fulfillExternalOrdersCallable = httpsCallable<
    { labelIds: string[] },
    IFulfillExternalOrdersResponse
  >(firebaseFunctions, "httpsOnCallFulfillExternalOrders");

  const { data } = await fulfillExternalOrdersCallable({ labelIds });

  return data;
};

/**
 * Fetch the pickup locations associated with a merchant
 * @param merchantId
 * @returns array of the pickup locations
 */

export const getPickupLocationsForMerchantId = async (
  merchantId: string,
): Promise<IPickupLocation[]> => {
  const result = await getDocs(query(getPickupLocationsCollection(merchantId)));
  const pickupLocations = result.docs.map((docSnapshot: DocumentSnapshot) =>
    docSnapshot.data(),
  );
  return pickupLocations as IPickupLocation[];
};

/**
 * Creates a label
 * @param createLabelRequest
 * @returns the created label id
 */

export const createLabel = async (createLabelRequest: any): Promise<string> => {
  const createLabelCallable = httpsCallable<
    { createLabelRequest: any },
    { merchantLabelId: string }
  >(firebaseFunctions, "httpsOnCallLabels-createLabel");
  const {
    data: { merchantLabelId },
  } = await createLabelCallable(createLabelRequest);
  return merchantLabelId;
};

/**
 * Creates a label for merchant group
 * @param createLabelRequest
 * @returns the created label id
 */
export const merchantGroupCreateLabel = async (
  createLabelRequest: ICreateLabelCallableDashboardRequest,
): Promise<string> => {
  const createLabelCallable = httpsCallable<
    ICreateLabelCallableDashboardRequest,
    { merchantLabelId: string }
  >(firebaseFunctions, "httpsOnCallLabels-merchantGroupCreateLabel");
  const {
    data: { merchantLabelId },
  } = await createLabelCallable(createLabelRequest);
  return merchantLabelId;
};

/**
 * Creates labels in batch
 * @param createLabelRequests
 * @returns arrays of merchant and org label ids or an error
 */
export const batchCreateLabels = async (createLabelRequests: any[]) => {
  const batchCreateLabels = httpsCallable(
    firebaseFunctions,
    "httpsOnCallLabels-batchCreateLabelsFromCsv",
    {
      timeout: CLOUD_FUNCTION_MAX_TIMEOUT,
    },
  );

  const { data } = await batchCreateLabels(createLabelRequests);
  return data;
};

/**
 * Creates labels in batch for merchant group
 * @param createLabelRequests
 * @returns arrays of merchant and org label ids or an error
 */
export const merchantGroupBatchCreateLabels = async (
  createLabelRequests: ICreateLabelCallableDashboardRequest[],
) => {
  const merchantGroupBatchCreateLabels = httpsCallable(
    firebaseFunctions,
    "httpsOnCallLabels-merchantGroupBatchCreateLabelsFromCsv",
    {
      timeout: CLOUD_FUNCTION_MAX_TIMEOUT,
    },
  );
  const { data } = await merchantGroupBatchCreateLabels(createLabelRequests);
  return data;
};

export const printPdfLabels = async (
  printPdfLabelsRequest: any,
): Promise<string> => {
  const printPdfLabelsCallable = httpsCallable<{ labelIds: string[] }, string>(
    firebaseFunctions,
    "labels-printPdfLabels",
  );
  const { data } = await printPdfLabelsCallable(printPdfLabelsRequest);
  return data;
};
