import { PropsWithChildren } from "react";
import { useAuthState, useLogout } from "react-admin";
import { AdapterDateFns, LocalizationProvider } from "@helo/ui";
import en from "date-fns/locale/en-US";

import { AuthenticatedContextProvider } from "~/components/AuthenticatedContext";
import Loading from "~/components/feedback/Loading";

const AuthenticatedLayout = ({ children }: PropsWithChildren<{}>) => {
  // NOTE: react-admin renders routes optimistically - so we have to
  // implement pessimistic rendering ourselves with the hook below
  const { isLoading, authenticated } = useAuthState();
  const logout = useLogout();

  if (isLoading) {
    return <Loading loadingPrimary="shared.message.loading.auth" />;
  }

  if (!authenticated) {
    logout();
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
      <AuthenticatedContextProvider>{children}</AuthenticatedContextProvider>
    </LocalizationProvider>
  );
};

export default AuthenticatedLayout;
