"use strict";
exports.__esModule = true;
exports.WebhookStatus = exports.WebhookTopic = void 0;
var WebhookTopic;
(function (WebhookTopic) {
    WebhookTopic["TRACK_LABEL_UPDATES"] = "TRACK_LABEL_UPDATES";
})(WebhookTopic = exports.WebhookTopic || (exports.WebhookTopic = {}));
var WebhookStatus;
(function (WebhookStatus) {
    WebhookStatus["ACTIVE"] = "ACTIVE";
    WebhookStatus["INACTIVE"] = "INACTIVE";
})(WebhookStatus = exports.WebhookStatus || (exports.WebhookStatus = {}));
