import { PropsWithChildren } from "react";
import { styled } from "@helo/ui";

const BaseLayout = ({ children }: PropsWithChildren<{}>) => {
  return <SiteContainer>{children}</SiteContainer>;
};

const SiteContainer = styled("div")({
  display: "grid",
  width: "100%",
  height: "100vh",
});

export default BaseLayout;
