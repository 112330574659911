import { string, mixed, object } from "yup";

export const LabelClaimRequestValidationSchema = object({
  fileURL: object({
    previewURL: string(),
    rawFile: mixed(),
    storagePreviewPath: string(),
    storagePreviewURL: string().url(),
  })
    .nullable()
    .required("shared.validation.required")
    .test("invalid", "shared.message.file.upload_fail", (data) => {
      if (!data) {
        return true;
      }

      // empty files are invalid
      return !!data?.rawFile?.size;
    }),
  trackingNumber: string().required("shared.validation.required"),
  amount: string().required("shared.validation.required"),
  description: string().required("shared.validation.required"),
  reason: string().required("shared.validation.required"),
});
