import {
  Button,
  Grid,
  LoadingButton,
  styled,
  TextField,
  Typography,
} from "@helo/ui";
import { PasswordField } from "@helo/ui/components";
import { useLogin, useTranslate, useNotify } from "react-admin";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { getLoginFormSchema } from "~/common/validators/login-form/loginForm";
import UnauthenticatedLayout from "~/layouts/site/UnauthenticatedLayout";
import { Routes } from "~/config/Routes";
import { isSignupEnabled } from "~/services/remote-config";

const LoginPage = (props: any) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();

  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(getLoginFormSchema(translate)),
    mode: "onChange",
  });
  const onSubmit = async (formValues: LoginForm) => {
    const email = formValues.email;
    const password = formValues.password;

    try {
      await login({ email, password });
    } catch (err) {
      console.error(err);
      notify("user.message.login.fail", {
        type: "warning",
      });
    }
  };

  return (
    <UnauthenticatedLayout>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {translate("ra.auth.sign_in")}
      </Typography>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <TextField
              error={Boolean(formState?.errors?.email)}
              fullWidth
              helperText={formState?.errors?.email?.message || null}
              label={translate("shared.label.email")}
              margin="normal"
              type="email"
              {...register("email")}
              name="email"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <PasswordField
              error={Boolean(formState?.errors?.password)}
              fullWidth
              helperText={formState?.errors?.password?.message || null}
              label={translate("ra.auth.password")}
              margin="normal"
              {...register("password", {
                onChange: (e) => setValue("password", e?.target?.value),
              })}
              name="password"
            />
          </Grid>
          <Button
            type="reset"
            variant="text"
            size="small"
            onClick={() => navigate(Routes.ForgotPassword)}
            style={{ textTransform: "none" }}
          >
            {translate("user.form.login.forgot_password")}
          </Button>
          <SignInButtonContainer>
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              disableElevation
              style={{ textTransform: "none" }}
              loading={formState.isSubmitting}
              fullWidth
            >
              {translate("Sign In")}
            </LoadingButton>
          </SignInButtonContainer>
        </Grid>
      </form>
      {isSignupEnabled() && (
        <SignupButtonContainer>
          <Button
            type="reset"
            variant="text"
            size="small"
            style={{ textTransform: "none" }}
            onClick={() => navigate(Routes.Register)}
          >
            {translate("Sign up for an account")}
          </Button>
        </SignupButtonContainer>
      )}
    </UnauthenticatedLayout>
  );
};

const SignInButtonContainer = styled(Grid)({
  width: "100%",
  paddingTop: "24px",
});

const SignupButtonContainer = styled(Grid)({
  width: "100%",
  paddingTop: "10px",
});

interface LoginForm {
  email: string;
  password: string;
}

export default LoginPage;
