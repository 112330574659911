import { GetListResult, DataProvider, GetOneResult } from "react-admin";
import { FirebaseOptions } from "firebase/app";
import { InvoiceController } from "@swyft/domain";
import { StorageFileLocationType } from "@swyft/types";
import { getDownloadURL, ref } from "firebase/storage";
import { isBefore } from "date-fns";

import { firebaseStorage } from "~/services/firebase";
import { ViewInvoice, InvoiceStatus } from "../types";

/**
 * Data Provider for the "invoices" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): InvoiceDataProvider => {
  const invoiceController = new InvoiceController(firebaseConfig);

  return {
    getList: async (resource, params): Promise<GetListResult> => {
      const { data, total } = await invoiceController.getList(params);
      const viewInvoices = data.reduce<ViewInvoice[]>((invoices, invoice) => {
        const viewInvoice = {
          ...invoice,
          status: getStatus(invoice.balanceAmount, invoice.dueDate),
        };

        if (
          params.filter.status &&
          viewInvoice.status !== params.filter.status
        ) {
          return invoices;
        }

        invoices.push(viewInvoice);

        return invoices;
      }, []);

      return {
        data: viewInvoices,
        total,
      };
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const { data } = await invoiceController.getOne(params);
      const viewInvoice: ViewInvoice = {
        ...data,
        status: getStatus(data.balanceAmount, data.dueDate),
      };
      if (params.meta?.getInvoiceUrl) {
        const bucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

        if (
          viewInvoice.exportFileLocation.type ===
          StorageFileLocationType.FIREBASE_STORAGE
        ) {
          const fileStorageLocation = viewInvoice.exportFileLocation;
          if (fileStorageLocation.path) {
            const path = `${fileStorageLocation.bucketPrefix ?? bucket}/${
              fileStorageLocation.path
            }`;
            const fileRef = ref(firebaseStorage, path);
            const url = await getDownloadURL(fileRef);
            return {
              data: {
                ...viewInvoice,
                exportFileLocation: { type: StorageFileLocationType.URL, url },
                status: getStatus(
                  viewInvoice.balanceAmount,
                  viewInvoice.dueDate,
                ),
              },
            };
          }
        }
      }
      return {
        data: viewInvoice,
      };
    },
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    update: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    deleteMany: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
  };
};

export interface InvoiceDataProvider extends DataProvider {}

/**
 * Calculate the invoice status as Quickbooks does (QB does not provide the status through their API)
 * @param balanceAmount the invoice remaining unpaid balance (as defined in our Invoice model)
 * @param dueDate the invoice due date (as defined in our Invoice model)
 * @returns a valid status value from the InvoiceStatus enum
 */
const getStatus = (balanceAmount: number, dueDate: Date): InvoiceStatus => {
  if (balanceAmount <= 0) {
    return InvoiceStatus.PAID;
  }

  const isDueDateInFuture = isBefore(new Date(), dueDate);

  return isDueDateInFuture && balanceAmount > 0
    ? InvoiceStatus.INVOICED
    : InvoiceStatus.OVERDUE;
};
