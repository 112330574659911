import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  ThemeType,
  SxProps,
} from "@helo/ui";
import { useTranslate } from "react-admin";
import { useMerchantOnboardingContext } from "~/components/MerchantOnboardingContext";
import GracefulFallback from "~/components/feedback/GracefulFallback";

const OnboardingViewLayout: React.FunctionComponent<
  OnboardingViewLayoutProps
> = ({ children, sx }) => {
  const translate = useTranslate();
  const { currentStep } = useMerchantOnboardingContext();
  const isMobileLarge = useMediaQuery((theme: ThemeType) =>
    theme.breakpoints.down("md"),
  );

  if (isMobileLarge) {
    return (
      <GracefulFallback
        type="warning"
        title="shared.message.responsive.not_mobile_optimized_header"
        details="shared.message.responsive.not_mobile_optimized"
        rounded={false}
      />
    );
  }

  return (
    <Container sx={sx}>
      <HeaderContainer className={CLASSES.headerContainer}>
        <TitleContainer className={CLASSES.titleContainer}>
          <TitleWrapper>
            <Typography variant="h5" component="h1" fontWeight={900}>
              {translate(currentStep.title)}
            </Typography>
          </TitleWrapper>
          <Typography variant="subtitle1" component="h2" sx={{ mt: 1 }}>
            {translate(currentStep.subtitle)}
          </Typography>
        </TitleContainer>
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  padding:
    "var(--with-fallback-safe-area-inset-top) var(--with-fallback-safe-area-inset-right) var(--with-fallback-safe-area-inset-bottom) var(--with-fallback-safe-area-inset-left)",
  height: "100%",
  boxShadow: theme.shadows[2],
  [theme.breakpoints.up("sm")]: {
    padding: "24px 44px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "48px 88px",
  },
  [theme.breakpoints.up("xl")]: {
    boxShadow: "none",
  },
}));

const HeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "24px",
});

const TitleContainer = styled(Box)({
  flexGrow: 1,
});

const TitleWrapper = styled(Box)({
  display: "flex",
});

const ContentContainer = styled(Box)({
  minHeight: "100%",
});

const StepNavContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const CLASS_PREFIX = "onboarding-view-layout";
const CLASSES = {
  headerContainer: `${CLASS_PREFIX}__header-container`,
  titleContainer: `${CLASS_PREFIX}__title-container`,
};

interface OnboardingViewLayoutProps {
  sx?: SxProps<ThemeType>;
}

export default OnboardingViewLayout;
