import {
  List,
  TextField,
  EmailField,
  useTranslate,
  EditButton,
  FunctionField,
  useListContext,
} from "react-admin";
import {
  Breadcrumbs,
  Link,
  Button,
  AddOutlinedIcon,
  Stack,
  Skeleton,
  Card,
  Chip,
  Box,
} from "@helo/ui";
import { Datagrid, IfCanAccess } from "@react-admin/ra-rbac";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { useNavigate } from "react-router-dom";
import { User, OrganizationType } from "@swyft/types";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { StatusChip } from "./components/StatusChip";
import { Routes } from "~/config/Routes";
import ResourceEmptyState from "~/components/data/ResourceEmptyState";
import { AppViewLayout } from "~/layouts/app";
import { AppResource } from "~/config/resources";

const UserList = () => {
  useDefineAppLocation("settings.users");
  const translate = useTranslate();
  const navigate = useNavigate();
  const { merchant } = useAuthenticatedContext();

  const filter = {
    org: {
      id: merchant?.id,
      type: !!merchant
        ? OrganizationType.Merchant
        : OrganizationType.DeliveryServicePartner,
    },
    isDeleted: false,
  };

  return (
    <AppViewLayout
      breadcrumbs={
        <Breadcrumbs>
          <Link underline="none" color="inherit">
            {translate("menu.settings.settings")}
          </Link>
          <Link underline="none" color="text.primary">
            {translate("menu.settings.users")}
          </Link>
        </Breadcrumbs>
      }
      context={
        <IfCanAccess resource={AppResource.User} action="create">
          <Button
            type="button"
            variant="contained"
            size="large"
            startIcon={<AddOutlinedIcon sx={{ marginRight: 0 }} />}
            onClick={() => navigate(`${Routes.Users}/create`)}
            disableElevation
          >
            {translate("shared.content.create.title", {
              name: translate("resources.users.name", { smart_count: 1 }),
            })}
          </Button>
        </IfCanAccess>
      }
    >
      <List
        filter={filter}
        actions={false}
        empty={<ResourceEmptyState />}
        component="div"
      >
        <UserListTable />
      </List>
    </AppViewLayout>
  );
};

const UserListTable = () => {
  const translate = useTranslate();
  const { isLoading } = useListContext();
  const { identity } = useAuthenticatedContext();

  if (isLoading) {
    return (
      <Stack spacing={2}>
        {[...Array(5)].map((...[, idx]) => (
          <Skeleton variant="rectangular" key={`${idx}-skeleton`} />
        ))}
      </Stack>
    );
  }

  return (
    <Card sx={{ overflow: "visible" }}>
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
          },
        }}
      >
        <FunctionField
          source="firstName"
          render={(record: User) => (
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              {record.firstName}
              {identity?.id === record.id && (
                <Chip
                  sx={{ ml: 1 }}
                  label={translate("shared.content.you")}
                  variant="filled"
                  size="small"
                  color="neutral"
                />
              )}
            </Box>
          )}
        />
        <TextField source="lastName" />
        <EmailField sortable={false} source="email" />
        <FunctionField
          sortable={false}
          source="phone"
          label={translate("resources.users.fields.phone")}
          render={(record: User) =>
            `${record.countryCode ?? ""} ${record.phone || "-"}`
          }
        />
        <TextField sortable={false} source="role" />
        <FunctionField source="status" render={() => <StatusChip />} />
        <IfCanAccess action="edit">
          <EditButton />
        </IfCanAccess>
      </Datagrid>
    </Card>
  );
};

export default UserList;
