import { Autocomplete, Grid, Box, styled, TextField } from "@helo/ui";
import { useTranslate } from "react-admin";
import { Dispatch, SetStateAction, useState } from "react";

import { UserRoles, UserRolesKey } from "~/constants/form";
import DSPForm from "./DSPForm";
import MerchantForm from "./MerchantForm";
import { UserTypeOptions } from "~/common/consts";
import reverseEnumMapping from "~/common/reverseEnumMapping";
import { MerchantFormValues } from "~/components/forms/MerchantForm";

const BasicInfo = ({
  setUserType,
}: {
  setUserType: Dispatch<SetStateAction<UserRolesKey>>;
}) => {
  const translate = useTranslate();
  const defaultValue = UserTypeOptions[0];

  return (
    <FormContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            options={UserTypeOptions}
            isOptionEqualToValue={(option: any, value: any) => {
              return option.label === value?.label || value?.label === "";
            }}
            defaultValue={defaultValue}
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              selectedOption: {
                value: string;
                label: string;
              } | null,
            ) => {
              if (selectedOption?.value && selectedOption?.label) {
                setUserType(selectedOption.value as UserRolesKey);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label={translate("I am a ...")} />
            )}
          />
        </Grid>
      </Grid>
    </FormContainer>
  );
};

const DynamicForm = ({ userType, onSubmitMerchant }: DynamicFormProps) => {
  const merchantUserTypes = [
    UserRoles["3PL"],
    UserRoles.ECOMMERCE,
    UserRoles.RETAILER,
  ].map((value) => reverseEnumMapping(UserRoles, value));
  const dspUserTypes = [UserRoles.COURIER].map((value) =>
    reverseEnumMapping(UserRoles, value),
  );

  if (!userType || merchantUserTypes.includes(userType)) {
    return <MerchantForm onSubmit={onSubmitMerchant} />;
  } else if (dspUserTypes.includes(userType)) {
    return <DSPForm />;
  } else {
    return null;
  }
};

const FTUXForm = ({ onSubmitMerchant }: FTUXFormProps) => {
  const [userType, setUserType] = useState<UserRolesKey>(
    UserTypeOptions[0].value as UserRolesKey,
  );

  return (
    <>
      <BasicInfo setUserType={setUserType} />
      <DynamicForm userType={userType} onSubmitMerchant={onSubmitMerchant} />
    </>
  );
};

const FormContainer = styled(Box)(({ theme }) => ({
  marginBottom: "16px",
  [theme.breakpoints.up("md")]: {
    maxWidth: "1000px",
  },
}));

export default FTUXForm;

interface FTUXFormProps {
  onSubmitMerchant: (formValues: MerchantFormValues) => Promise<void>;
}

interface DynamicFormProps {
  userType: UserRolesKey;
  onSubmitMerchant: (formValues: MerchantFormValues) => Promise<void>;
}
