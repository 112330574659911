import { Typography, Button, Link, Box, Chip } from "@helo/ui";
import { ZoneStatus } from "@swyft/types";
import { MapZone } from "~/services/data/types";
import { deactivateZoneRequest } from "./ZonesMap";

//Types
interface ITranslatedContent {
  title: string;
  feature_title: string;
  features: string[];
  download_zips: string;
  download_otd: string;
  quote: string;
  deactive: string;
  manage_pickup: string;
  pending: string;
}

interface IPopUpProps {
  content: ITranslatedContent;
  marketZones: MapZone[];
  chipColor: string;
  zoneId: string;
  merchantId: string;
  otdUrl: string;
  zipUrl: string;
  canRequestQuote: boolean;
  status: ZoneStatus;
  deactivateZoneRequestHandler: ({
    merchantId,
    zoneId,
  }: deactivateZoneRequest) => Promise<void>;
  managePickupLocationsHandler: () => void;
  requestQuoteHandler: () => void;
}

const ZonePopup = ({
  content,
  marketZones,
  chipColor,
  zoneId,
  merchantId,
  otdUrl,
  zipUrl,
  canRequestQuote,
  status,
  deactivateZoneRequestHandler,
  managePickupLocationsHandler,
  requestQuoteHandler,
}: IPopUpProps) => {
  return (
    <Box
      sx={{
        minWidth: 400,
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        "& .zone-popup__body-text": {
          fontFamily: "Sofia Pro",
          fontSize: 16,
        },
        "& .zone-popup__title-text": {
          fontWeight: 900,
          marginBottom: 2,
          fontSize: 20,
          marginRight: 3,
        },
        "& .zone-popup__button": {
          fontFamily: "Sofia Pro",
          fontSize: 15,
          textTransform: "uppercase",
          fontWeight: "600",
          paddingTop: 1,
        },
      }}
    >
      <Typography
        variant="h6"
        className="zone-popup__body-text zone-popup__title-text"
      >
        {content.title}
      </Typography>
      <Typography
        variant="body2"
        className="zone-popup__body-text zone-popup__title-text"
      >
        <Chip
          label={status}
          sx={({ palette }) => {
            return {
              backgroundColor: chipColor,
              color: palette.primary.contrastText,
            };
          }}
        />
      </Typography>
      <Box>
        <Typography className="zone-popup__body-text">
          {content.feature_title}
        </Typography>
        <ul style={{ margin: 0 }}>
          {content.features.map((item, idx) => (
            <li key={`list-${idx}`}>
              <Typography className="zone-popup__body-text">{item}</Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        {
          //Link will also be hidden if no file could be retrieved
          zipUrl && (
            <Link id={`${zoneId}-zip`} href={zipUrl}>
              <Typography className="zone-popup__body-text">
                {content.download_zips}
              </Typography>
            </Link>
          )
        }

        {
          /** if an active merchant does not have merchant-specific 90 otd data available
           * do not display download link. Link will also be hidden if no file could be retrieved
           */
          otdUrl && (
            <Link id={`${zoneId}-otd`} href={otdUrl}>
              <Typography className="zone-popup__body-text">
                {content.download_otd}
              </Typography>
            </Link>
          )
        }
      </Box>
      <Box sx={{ alignSelf: "flex-end" }}>
        {status === ZoneStatus.INACTIVE && canRequestQuote && (
          <Button
            className="zone-popup__button"
            variant="contained"
            onClick={requestQuoteHandler}
          >
            {content.quote}
          </Button>
        )}
        {status === ZoneStatus.PENDING && canRequestQuote && (
          <Button className="zone-popup__button" variant="contained" disabled>
            {content.pending}
          </Button>
        )}
        {status === ZoneStatus.ACTIVE && canRequestQuote && (
          <Button
            onClick={() => {
              deactivateZoneRequestHandler({ merchantId, zoneId });
            }}
            className="zone-popup__button"
            variant="contained"
            color="inherit"
            sx={{ marginRight: 1 }}
          >
            {content.deactive}
          </Button>
        )}
        {status === ZoneStatus.ACTIVE && (
          <Button
            onClick={managePickupLocationsHandler}
            className="zone-popup__button"
            variant="contained"
          >
            {content.manage_pickup}
          </Button>
        )}
      </Box>
    </Box>
  );
};
export default ZonePopup;
