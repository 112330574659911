import { useDataProvider } from "react-admin";

import { AppResource } from "~/config/resources";
import { ActivityLogDataProvider } from "./label/activityLogDataProvider";
import { LabelDataProvider } from "./label/labelDataProvider";

export const useLabelActivityLogDataProvider = (): [
  AppResource,
  ActivityLogDataProvider,
] => [AppResource.Shipment, useDataProvider<ActivityLogDataProvider>()];

export const useLabelDataProvider = (): [AppResource, LabelDataProvider] => [
  AppResource.Shipment,
  useDataProvider<LabelDataProvider>(),
];
