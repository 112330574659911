import { Navigate, Outlet } from "react-router-dom";
import { Loading } from "react-admin";
import { UserRole } from "@swyft/types";
import { Routes } from "~/config/Routes";
import { useGetIdentity } from "~/services/auth/hooks";

const PrivateRoutes = ({ roles }: { roles: UserRole[] }) => {
  const { identity, isLoading } = useGetIdentity();

  if (isLoading) {
    return <Loading />;
  }

  return roles.includes(identity?.role as UserRole) ? (
    <Outlet />
  ) : (
    <Navigate to={Routes.Dashboard} />
  );
};
export default PrivateRoutes;
