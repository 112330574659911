import { GetListResult, DataProvider, GetOneResult } from "react-admin";
import { FirebaseOptions } from "firebase/app";
import { IntegrationsController } from "@swyft/domain";

/**
 * Data Provider for the "integrations" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): IntegrationsDataProvider => {
  const integrationsController = new IntegrationsController(firebaseConfig);

  return {
    getList: async (resource, params): Promise<GetListResult> => {
      const { data } = await integrationsController.getList(params);
      return {
        data,
        total: data.length,
      };
    },
    getOne: async (resource, params): Promise<GetOneResult> =>
      await integrationsController.getOne(params),
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    update: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
    deleteMany: async (resource, params) => {
      throw new Error("Function not implemented.");
    },
  };
};

export interface IntegrationsDataProvider extends DataProvider {}
