import { Translate } from "react-admin";
import { object, string, number } from "yup";
import { CountryISO2 } from "@swyft/types";
import { REGEX_PATTERNS } from "~/common/consts";
import { CA_PROVINCES, US_STATES } from "@swyft/types";

export const getLabelCreateSchema = (translate: Translate) =>
  object({
    pickupLocation: string()
      .nullable()
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shipments.content.create.form.pickup_location"),
        }),
      ),
    firstName: string()
      .min(1, translate("shared.validation.min"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 })),
    lastName: string()
      .min(1, translate("shared.validation.min"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 })),
    email: string()
      .email(
        translate("shared.validation.invalid_dynamic", {
          value: translate("shared.label.email"),
        }),
      )
      .max(120, translate("shared.validation.max_dynamic", { value: 120 }))
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shared.label.email"),
        }),
      ),
    countryCode: string().matches(/\+1/),
    phoneNumber: string()
      .matches(
        REGEX_PATTERNS.phone_number,
        translate("shared.validation.invalid_dynamic", {
          value: translate("shared.label.phone"),
        }),
      )
      .max(10, translate("shared.validation.max_dynamic", { value: "10" }))
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shared.label.phone"),
        }),
      ),
    addressLine1: string().min(2, translate("shared.validation.required")),
    addressLine2: string(),
    city: string().min(2, translate("shared.validation.required")),
    province: string()
      .oneOf(
        [...CA_PROVINCES, ...US_STATES],
        translate("shared.validation.required_select"),
      )
      .nullable()
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shared.label.address.state_province"),
        }),
      ),
    country: string()
      .oneOf(
        Object.values(CountryISO2),
        translate("shared.validation.required_select"),
      )
      .nullable()
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shared.label.country"),
        }),
      ),
    postalCode: string().min(2, translate("shared.validation.required")),
    notes: string()
      .min(0, translate("shared.validation.min"))
      .max(250, translate("shared.validation.max_dynamic", { value: 250 })),
    weight: number()
      .typeError(translate("shared.validation.number"))
      .moreThan(0, translate("shared.validation.min_number"))
      .nullable()
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shipments.content.create.form.weight.label"),
        }),
      ),
    length: number()
      .typeError(translate("shared.validation.number"))
      .moreThan(0, translate("shared.validation.min_number"))
      .nullable()
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shipments.content.create.form.length.label"),
        }),
      ),
    width: number()
      .typeError(translate("shared.validation.number"))
      .moreThan(0, translate("shared.validation.min_number"))
      .nullable()
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shipments.content.create.form.width.label"),
        }),
      ),
    height: number()
      .typeError(translate("shared.validation.number"))
      .moreThan(0, translate("shared.validation.min_number"))
      .nullable()
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shipments.content.create.form.height.label"),
        }),
      ),
  });
