import { Translate } from "react-admin";
import { object, string, bool } from "yup";
import { REGEX_PATTERNS } from "~/common/consts";

export const getRegisterFormSchema = (translate: Translate) =>
  object({
    firstName: string()
      .min(2, translate("shared.validation.min"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 }))
      .required(translate("shared.validation.required")),
    lastName: string()
      .min(2, translate("shared.validation.min"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 }))
      .required(translate("shared.validation.required")),
    phone: string()
      .matches(
        REGEX_PATTERNS.phone_number,
        translate("shared.validation.invalid"),
      )
      .max(10, translate("shared.validation.max_dynamic", { value: 10 }))
      .required(translate("shared.validation.required")),
    email: string()
      .email(translate("shared.validation.invalid"))
      .max(120, translate("shared.validation.max_dynamic", { value: 120 }))
      .required(translate("shared.validation.required")),
    password: string()
      .max(120, translate("shared.validation.max_dynamic", { value: 120 }))
      .required(translate("shared.validation.required"))
      .matches(
        REGEX_PATTERNS.password,
        translate("shared.validation.password"),
      ),
    countryCode: string().required(
      translate("shared.validation.required_select"),
    ),
    terms: bool().isTrue(translate("shared.validation.terms")),
  });
