import { string, mixed, object, InferType, number } from "yup";

export const InvoiceClaimRequestValidationSchema = object({
  fileURL: object({
    previewURL: string(),
    rawFile: mixed(),
    storagePreviewPath: string(),
    storagePreviewURL: string().url(),
  })
    .nullable()
    .test("invalid", "shared.message.file.upload_fail", (data) => {
      if (!data) {
        return true;
      }

      // empty files are invalid
      return !!data?.rawFile?.size;
    }),
  invoiceNumber: string().required("shared.validation.required"),
  invoiceDate: string().required("shared.validation.required"),
  amount: number().required("shared.validation.required"),
  description: string().required("shared.validation.required"),
});

export interface InvoiceClaimSchema
  extends InferType<typeof InvoiceClaimRequestValidationSchema> {}
