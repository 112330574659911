import { Translate } from "react-admin";
import { object, string, date } from "yup";
import { REGEX_PATTERNS } from "~/common/consts";

export const getLocationEditSchema = (translate: Translate) =>
  object({
    name: string().max(
      100,
      translate("shared.validation.max_dynamic", { value: 100 }),
    ),
    pickupNotes: string().max(
      250,
      translate("shared.validation.max_dynamic", { value: 250 }),
    ),
    cutoffTime: date().nullable().required("shared.validation.required"),
    pickupTime: date().nullable().defined("shared.validation.required"),
    firstName: string()
      .required(translate("shared.validation.required"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 })),
    lastName: string().max(
      100,
      translate("shared.validation.max_dynamic", { value: 100 }),
    ),
    jobTitle: string().max(
      100,
      translate("shared.validation.max_dynamic", { value: 100 }),
    ),
    email: string()
      .email(translate("shared.validation.invalid"))
      .max(120, translate("shared.validation.max_dynamic", { value: 120 }))
      .required(translate("shared.validation.required")),
    countryCode: string().matches(/\+1/),
    phone: string()
      .matches(
        REGEX_PATTERNS.phone_number,
        translate("shared.validation.invalid"),
      )
      .max(10, translate("shared.validation.max_dynamic", { value: 10 }))
      .required(translate("shared.validation.required")),
  });
