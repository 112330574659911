import {
  CreateResult,
  DeleteManyResult,
  GetListResult,
  GetOneResult,
  DeleteResult,
  DataProvider,
} from "react-admin";
import { FirebaseOptions } from "firebase/app";
import { ApiController } from "@swyft/domain";

/**
 * Data Provider for the "api" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): ApiDataProvider => {
  const apiController = new ApiController(firebaseConfig);

  return {
    getList: async (resource, params): Promise<GetListResult> => {
      const result: any = await apiController.getList(params);
      return {
        data: result.data,
        total: 2,
      };
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      throw new Error("Function not implemented.");
    },
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: async (resource, params): Promise<CreateResult> => {
      const { data } = await apiController.create(params);
      return {
        data: {
          id: data,
        },
      };
    },
    update: async (resource, params): Promise<CreateResult> => {
      throw new Error("Function not implemented.");
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      const { data } = await apiController.deleteOne({
        id: String(params.id),
        type: params?.meta?.isProductionToken,
      });
      return { data: { ...params.previousData, id: data } };
    },
    deleteMany: async (resource, params): Promise<DeleteManyResult> => {
      throw new Error("Function not implemented.");
    },
  };
};

export interface ApiDataProvider extends DataProvider {}
