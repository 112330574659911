import {
  Grid,
  LoadingButton,
  styled,
  TextField,
  Typography,
  Stack,
} from "@helo/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslate } from "react-admin";
import { getLocationCreateSchema } from "~/common/validators/locations/LocationCreateSchema";
import { useGetIdentity } from "~/services/auth/hooks";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import Loading from "~/components/feedback/Loading";
import { CountryOptions } from "~/common/consts";
import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import { CountryISO2, CA_PROVINCES, US_STATES } from "@swyft/types";

const LocationCreateForm = ({ onSubmit }: LocationCreateFormProps) => {
  const translate = useTranslate();
  const defaultValues: any = {};

  const { register, handleSubmit, formState, control, watch, resetField } =
    useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(getLocationCreateSchema(translate)),
      mode: "onChange",
    });
  const { isLoading: isIdentityLoading, error: isIdentityError } =
    useGetIdentity();

  const caProvinceOptions = CA_PROVINCES.map((province) => {
    return {
      value: province,
      label: translate(`shared.label.ca_provinces.${province}`),
    };
  });

  const usStateOptions = US_STATES.map((state) => {
    return {
      value: state,
      label: translate(`shared.label.us_states.${state}`),
    };
  });

  if (isIdentityLoading) {
    return <Loading />;
  }

  if (isIdentityError) {
    return <GracefulFallback type="error" />;
  }

  //if country changes after a state or provinces has been selected clear province field
  const countryValue = watch("country");

  useEffect(() => {
    resetField("province");
  }, [countryValue]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        error={Boolean(formState?.errors?.name)}
        helperText={formState?.errors?.name?.message}
        label={translate("resources.locations.fields.name")}
        {...register("name")}
      />
      <TextField
        multiline
        rows={4}
        error={Boolean(formState?.errors?.pickupNotes)}
        helperText={formState?.errors?.pickupNotes?.message}
        label={translate("resources.locations.fields.pickupNotes")}
        {...register("pickupNotes")}
      />
      <DualColumns>
        <Stack rowGap={2}>
          <Typography variant="subtitle1" fontWeight={600}>
            {translate("shared.content.header.addr_info")}
          </Typography>
          <TextField
            error={Boolean(formState?.errors?.addressLine1)}
            helperText={formState?.errors?.addressLine1?.message}
            label={translate("shared.label.address.line1")}
            {...register("addressLine1")}
          />
          <TextField
            label={translate("shared.label.address.line2")}
            error={Boolean(formState?.errors?.addressLine2)}
            helperText={formState?.errors?.addressLine2?.message}
            {...register("addressLine2")}
          />
          <DualColumns>
            <TextField
              label={translate("shared.label.address.city")}
              error={Boolean(formState?.errors?.city)}
              helperText={formState?.errors?.city?.message}
              {...register("city")}
            />
            <TextField
              label={translate("shared.label.address.zip_postal")}
              error={Boolean(formState?.errors?.postalCode)}
              helperText={formState?.errors?.postalCode?.message}
              {...register("postalCode")}
            />
          </DualColumns>
          <DualColumns
            sx={({ breakpoints }) => ({
              [breakpoints.up("lg")]: {
                gridTemplateColumns: `${
                  !!watch("country") ? "1fr 1fr" : "1fr"
                }`,
              },
            })}
          >
            {!!watch("country") && (
              <ControlledAutocomplete
                control={control}
                options={
                  watch("country") === CountryISO2.USA
                    ? usStateOptions
                    : caProvinceOptions
                }
                name="province"
                rules={{ required: true }}
                error={Boolean(formState?.errors?.province)}
                helperText={formState?.errors?.province?.message?.toString()}
                label={translate("shared.label.address.state_province")}
              />
            )}
            <ControlledAutocomplete
              control={control}
              options={CountryOptions}
              name="country"
              rules={{ required: true }}
              error={Boolean(formState?.errors?.country)}
              helperText={formState?.errors?.country?.message?.toString()}
              label={translate("shared.label.address.country")}
            />
          </DualColumns>
        </Stack>
        <Stack rowGap={2}>
          <Typography variant="subtitle1" fontWeight={600}>
            {translate("shared.content.header.contact_info")}
          </Typography>
          <DualColumns>
            <TextField
              error={Boolean(formState?.errors?.firstName)}
              helperText={formState?.errors?.firstName?.message}
              label={translate("resources.locations.fields.contact.firstName")}
              {...register("firstName")}
            />
            <TextField
              error={Boolean(formState?.errors?.lastName)}
              helperText={formState?.errors?.lastName?.message}
              label={translate("resources.locations.fields.contact.lastName")}
              {...register("lastName")}
            />
          </DualColumns>
          <TextField
            error={Boolean(formState?.errors?.jobTitle)}
            helperText={formState?.errors?.jobTitle?.message}
            label={translate("resources.locations.fields.contact.title")}
            {...register("jobTitle")}
          />
          <TextField
            error={Boolean(formState?.errors?.email)}
            helperText={formState?.errors?.email?.message}
            label={translate("resources.locations.fields.contact.email")}
            {...register("email")}
            type={"email"}
          />
          <PhoneContainer>
            <TextField
              error={Boolean(formState?.errors?.countryCode)}
              helperText={formState?.errors?.countryCode?.message}
              label={translate("shared.label.address.countryCode")}
              type="tel"
              name="countryCode"
              disabled
              defaultValue={"+1"}
            />
            <TextField
              error={Boolean(formState?.errors?.phone)}
              helperText={formState?.errors?.phone?.message}
              label={translate("resources.locations.fields.contact.phone")}
              type="tel"
              {...register("phone")}
            />
          </PhoneContainer>
        </Stack>
      </DualColumns>
      <ButtonContainer>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={formState?.isSubmitting}
          size="large"
          disableElevation
        >
          {translate("shared.action.submit")}
        </LoadingButton>
      </ButtonContainer>
    </Form>
  );
};

export default LocationCreateForm;

const Form = styled("form")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "80%",
  gap: 20,
  placeContent: "center",
  [theme.breakpoints.up("md")]: {
    width: "80%",
  },
}));

const DualColumns = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  columnGap: 16,
  rowGap: 16,
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "1fr 1fr",
  },
}));

const PhoneContainer = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  columnGap: 16,
});

const ButtonContainer = styled(Grid)({
  display: "flex",
});

interface LocationCreateFormProps {
  onSubmit: (formValues: any) => Promise<void>;
}
export interface CreatePickupLocationFormValues {
  name?: string;
  pickupNotes?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  province: string;
  country: CountryISO2;
  phone: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  email: string;
}
