import {
  Box,
  ErrorOutlinedIcon,
  WarningAmberOutlinedIcon,
  DangerousOutlinedIcon,
  AuthMaybeOutlinedIcon,
  AuthBadOutlinedIcon,
  styled,
  Typography,
  SxProps,
} from "@helo/ui";
import { red, amber, deepOrange } from "@mui/material/colors";
import { FunctionComponent, ReactElement } from "react";
import { useTranslate } from "react-admin";

const GracefulFallback: FunctionComponent<GracefulFallbackProps> = ({
  type = "error",
  title,
  details,
  action,
  rounded,
  sx = [],
}) => {
  const translate = useTranslate();
  const typeData = TYPE_MAPPING[type] ?? TYPE_MAPPING["error"];
  const getTypeColor = (type: GracefulFallbackType) => {
    switch (type) {
      case "warning":
      case "blocked-warning":
        return amber[200];
      case "blocked":
      case "danger":
        return red[200];
      default:
        return deepOrange[200];
    }
  };

  return (
    <Container
      sx={[
        {
          backgroundColor: getTypeColor(type),
          ...(!rounded && { borderRadius: 0 }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box sx={{ mb: 1 }}>{typeData.icon}</Box>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: "center", fontWeight: 600 }}
      >
        {title ? translate(title) : translate(typeData.title)}
      </Typography>
      <Typography variant="body2" sx={{ textAlign: "center", mb: 2 }}>
        {details && translate(details)}
      </Typography>
      {action ? action : null}
    </Container>
  );
};

const Container = styled(Box)({
  width: "100%",
  height: "100%",
  display: "grid",
  placeContent: "center",
  borderRadius: "10px",
  justifyItems: "center",
  padding: "24px",
});

const TYPE_MAPPING = {
  error: {
    icon: <ErrorOutlinedIcon fontSize="large" />,
    title: "shared.content.errors.graceful.error_title",
  },
  warning: {
    icon: <WarningAmberOutlinedIcon fontSize="large" />,
    title: "shared.content.errors.graceful.warning_title",
  },
  danger: {
    icon: <DangerousOutlinedIcon fontSize="large" />,
    title: "shared.content.errors.graceful.danger_title",
  },
  blocked: {
    icon: <AuthBadOutlinedIcon fontSize="large" />,
    title: "shared.content.errors.graceful.blocked_title",
  },
  "blocked-warning": {
    icon: <AuthMaybeOutlinedIcon fontSize="large" />,
    title: "shared.content.errors.graceful.blocked_invalid_title",
  },
};

type GracefulFallbackType =
  | "error"
  | "warning"
  | "danger"
  | "blocked"
  | "blocked-warning";

interface GracefulFallbackProps {
  type?: GracefulFallbackType;
  title?: string;
  details?: string;
  action?: ReactElement;
  rounded?: boolean;
  sx?: SxProps;
}

export default GracefulFallback;
