import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  message,
  Modal,
  Row,
  Tooltip,
  Typography,
  Divider,
  Table,
  Select,
  TablePaginationConfig,
} from "antd";
import { LoadingButton } from "@helo/ui";
import * as FirestoreService from "~/services/firestore";
import moment, { Moment } from "moment";
import { exportLabelsCSV, exportPDF } from "~/common/fileExportHelper";
import {
  PRINT_SELECTED_LABELS_COLUMNS,
  GENERIC_ERROR_MESSAGE,
  COMPANY_NAME,
  DATE_FORMATS,
} from "~/common/consts";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { grey } from "@ant-design/colors";
import { ILabelV2 } from "@swyft/swyft-common";
import { RangeValue } from "rc-picker/lib/interface";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { fulfillExternalOrders } from "~/common/labelHelpers";
import { OPERATIONS_EMAIL } from "~/common/consts";
import { useLabelDataProvider } from "~/services/data/shipment";
import { AppResource } from "~/config/resources";

const { RangePicker } = DatePicker;
const { Text } = Typography;

interface Props {
  selectedLabels: ILabelV2[];
  isModalVisible: boolean;
  hideModal: () => void;
  merchantId: string;
  isMerchantGroup?: boolean;
}

export const ExportLabelDataModal: React.FC<Props> = ({
  selectedLabels,
  isModalVisible,
  hideModal,
  merchantId,
  isMerchantGroup = false,
}: Props) => {
  const { merchant } = useAuthenticatedContext();

  const [isExportingSelectedLabels, setIsExportingSelectedLabels] =
    useState<boolean>(false);
  const [isExportingFromDateRange, setIsExportingFromDateRange] =
    useState<boolean>(false);
  const [isExportingAsCSV, setIsExportingAsCSV] = useState<boolean>(false);
  const [dateRangeStart, setDateRangeStart] = useState<Moment>(
    moment().subtract(1, "week"),
  );
  const [dateRangeEnd, setDateRangeEnd] = useState<Moment>(moment());
  const [labels, setLabels] = useState<ILabelV2[]>(selectedLabels);
  const [, dataProvider] = useLabelDataProvider();

  useEffect(() => {
    setLabels(selectedLabels);
  }, [selectedLabels]);

  /**
   * Handles bulk export of labels as a CSV from a date range.
   */
  const handleExportLabelsCSV = async () => {
    setIsExportingAsCSV(true);
    try {
      const labels = await FirestoreService.getLabelsForDateRange(
        merchantId,
        dateRangeStart,
        dateRangeEnd,
      );
      const formattedStartDate = moment(dateRangeStart).format(
        DATE_FORMATS.moment.export,
      );
      const formattedEndDate = moment(dateRangeEnd).format(
        DATE_FORMATS.moment.export,
      );
      const fileName = `${COMPANY_NAME.toLowerCase()}_labels_${formattedStartDate}_${formattedEndDate}.csv`;
      await exportLabelsCSV(fileName, labels);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
    setIsExportingAsCSV(false);
  };

  /**
   * Handles bulk export of labels as PDF from a date range.
   */
  const handleExportLabelsPDF = async () => {
    setIsExportingFromDateRange(true);
    try {
      const labels = await FirestoreService.getLabelsForDateRange(
        merchantId,
        dateRangeStart,
        dateRangeEnd,
      );
      if (labels.length > 0) {
        const formattedStartDate = moment(dateRangeStart).format(
          DATE_FORMATS.moment.export,
        );
        const formattedEndDate = moment(dateRangeEnd).format(
          DATE_FORMATS.moment.export,
        );
        const fileName = `${COMPANY_NAME.toLowerCase()}-labels--${formattedStartDate}-${formattedEndDate}.pdf`;

        const { data } = await dataProvider.getPrintPdfs(AppResource.Shipment, {
          labelIds: labels.map((label) => label.id),
        });
        await exportPDF(data, fileName);

        if (!isMerchantGroup && merchant) {
          fulfillExternalOrders(labels, merchant);
        }
      } else {
        message.error("No label found within the selected date range.");
      }
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
    setIsExportingFromDateRange(false);
  };
  /**
   * When the table data is sorted, update state csvFile.data
   */
  const handleTableDataChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<ILabelV2> | SorterResult<ILabelV2>[],
    extra: { currentDataSource: ILabelV2[] },
  ): void => {
    setLabels(extra.currentDataSource as any);
  };
  /**
   * Handles exporting only the labels corresponding to the selected rows.
   */
  const handleExportSelectedLabels = async () => {
    setIsExportingSelectedLabels(true);
    if (labels.length > 0) {
      try {
        const { shipDate, destination } = labels[0];
        const formattedDate = moment(shipDate).format(
          DATE_FORMATS.moment.export,
        );
        const fileName =
          labels.length > 1
            ? `${COMPANY_NAME.toLowerCase()}-labels--${formattedDate}`
            : `${COMPANY_NAME.toLowerCase()}-label--${formattedDate}-${
                destination.firstName
              }-${destination.lastName}`;
        const { data } = await dataProvider.getPrintPdfs(AppResource.Shipment, {
          labelIds: labels.map((label) => label.id),
        });
        await exportPDF(data, fileName);
        if (!isMerchantGroup && !!merchant) {
          fulfillExternalOrders(labels, merchant);
        }
      } catch (err) {
        message.error(GENERIC_ERROR_MESSAGE);
      }
    } else {
      message.error("No label selected.");
    }
    setIsExportingSelectedLabels(false);
  };

  const handleSetDateRange = (range: RangeValue<Moment>) => {
    try {
      if (range && range[0] && range[1]) {
        setDateRangeStart(range[0]);
        setDateRangeEnd(range[1]);
      }
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
  };

  return (
    <Modal
      title="Export Labels"
      visible={isModalVisible}
      width={"1000px"}
      onCancel={hideModal}
      footer={null}
    >
      <Row justify="center" align="middle">
        <Text>
          {selectedLabels.length
            ? `Print selected labels (${selectedLabels.length}) in the sorted order below`
            : "No Labels selected"}
        </Text>
      </Row>
      <Row justify="center" align="middle" style={{ padding: "1em" }}>
        <Table
          rowKey="id"
          dataSource={[...labels]}
          size="small"
          columns={PRINT_SELECTED_LABELS_COLUMNS}
          locale={{ emptyText: "No Labels Selected" }}
          scroll={{ y: 200 }}
          pagination={false}
          onChange={handleTableDataChange}
        />
      </Row>
      <Row justify="center" align="middle">
        <Col style={{ padding: ".6em" }}>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={handleExportSelectedLabels}
            loading={isExportingSelectedLabels}
          >
            Print Selected Labels{" "}
            {selectedLabels.length > 0 && `(${selectedLabels.length})`}
          </LoadingButton>
        </Col>
      </Row>
      <Divider>
        <h3>Or</h3>
      </Divider>
      <Row justify="center" align="middle">
        <Text> Print all labels within a given date range</Text>
      </Row>
      <Row justify="center" align="middle">
        <Col style={{ padding: ".6em 0" }}>
          <Row style={{ paddingBottom: ".2em" }} justify="start" align="middle">
            <Tooltip
              title={`Maximum date range is 31 days. For larger exports, please contact ${OPERATIONS_EMAIL}`}
            >
              <Text>
                Date Range <QuestionCircleOutlined style={{ color: grey[3] }} />
              </Text>
            </Tooltip>
          </Row>
          <Row justify="start" align="middle">
            <RangePicker
              style={{ width: "100%" }}
              value={[dateRangeStart, dateRangeEnd]}
              format={"MMM. Do, YYYY"}
              onCalendarChange={handleSetDateRange}
              disabledDate={(current) =>
                moment(dateRangeStart).isBefore(
                  moment(current).subtract(30, "days"),
                )
              }
            />
          </Row>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        {!isMerchantGroup && (
          <Col style={{ padding: ".6em" }}>
            <LoadingButton
              variant="contained"
              size="medium"
              onClick={handleExportLabelsCSV}
              loading={isExportingAsCSV}
            >
              Export raw CSV
            </LoadingButton>
          </Col>
        )}
        <Col style={{ padding: ".6em" }}>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={handleExportLabelsPDF}
            loading={isExportingFromDateRange}
          >
            Print as PDF
          </LoadingButton>
        </Col>
      </Row>
    </Modal>
  );
};
