import {
  useTranslate,
  useNotify,
  useAuthProvider,
  useCreate,
} from "react-admin";
import { MerchantCreateOneParams } from "@swyft/domain";
import { Merchant } from "@swyft/types";

import FTUXForm from "~/components/forms/FTUXForm";
import { MerchantFormValues } from "~/components/forms/MerchantForm";
import { useMerchantOnboardingContext } from "~/components/MerchantOnboardingContext";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { onUserUpdate } from "~/services/auth/hooks";
import { AppResource } from "~/config/resources";

const BusinessInfoStep = () => {
  const { goToNextStep, markStepAsComplete, currentStep } =
    useMerchantOnboardingContext();
  const [create] = useCreate();
  const { identity, refetchIdentity } = useAuthenticatedContext();
  const authProvider = useAuthProvider();
  const translate = useTranslate();
  const notify = useNotify();

  const onSubmitMerchant = async (formValues: MerchantFormValues) => {
    try {
      const merchantMap: MerchantCreateOneParams = {
        isActive: true,
        companyName: formValues.companyName,
        jobTitle: formValues.jobTitle,
        monthlyVolume: formValues.monthlyVolume,
        packageSize: formValues.packageSize,
        packageWeight: formValues.packageWeight,
        country: formValues.country,
      };

      const newMerchant = (await create(
        AppResource.Merchant,
        {
          data: merchantMap,
        },
        {
          returnPromise: true,
        },
      )) as Merchant;
      const updatedUser = await onUserUpdate(identity?.id ?? null);
      await authProvider.refreshPermissions();

      if (refetchIdentity) {
        await refetchIdentity();
      }

      notify("shared.message.submit.ok", {
        type: "success",
      });
      await markStepAsComplete(
        currentStep.id,
        updatedUser?.organizationId || newMerchant.id,
      );
      goToNextStep();
    } catch (err: any) {
      notify(
        `${translate("shared.message.submit.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning" },
      );
    }
  };

  return <FTUXForm onSubmitMerchant={onSubmitMerchant} />;
};

export default BusinessInfoStep;
