import {
  Typography,
  Box,
  Stack,
  styled,
  CircleIcon,
  CheckCircleIcon,
  ThemeType,
  SxProps,
} from "@helo/ui";
import { useTranslate } from "react-admin";
import { useMerchantOnboardingContext } from "~/components/MerchantOnboardingContext";

const Steps = ({ sx }: { sx?: SxProps<ThemeType> }) => {
  const translate = useTranslate();
  const { steps, currentStepIdx } = useMerchantOnboardingContext();

  return (
    <StepsContainer sx={sx}>
      {steps &&
        steps.map((step, idx) => (
          <StepContainer
            className={`${idx === currentStepIdx ? "isCurrent" : ""} ${
              step.isComplete ? "isComplete" : ""
            }`}
            key={`${step.title}-${idx}`}
          >
            {step.isComplete && idx !== currentStepIdx ? (
              <CheckCircleIcon
                sx={({ palette }) => ({
                  color: palette.success.main,
                  mr: 1,
                })}
              />
            ) : (
              <CircleIcon
                sx={({ palette }) => ({
                  color:
                    idx === currentStepIdx
                      ? palette.primary.main
                      : palette.neutralScale[700],
                  mr: 1,
                })}
              />
            )}
            <Typography variant="subtitle1" component="h3">
              {translate(step.label)}
            </Typography>
          </StepContainer>
        ))}
    </StepsContainer>
  );
};

const StepContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContents: "center",
  alignItems: "center",
  padding: "8px",
  color: theme.palette.neutralScale[400],
  [theme.breakpoints.up("md")]: {
    padding: "16px",
  },
}));

const StepsContainer = styled(Stack)(({ theme }) => ({
  "& .isCurrent": {
    backgroundColor: theme.palette.primaryScale[1000],
    color: theme.palette.primary.dark,
  },
}));

export default Steps;
