import { Box, Stack, Typography } from "@helo/ui";
import { useTranslate } from "react-admin";
import { Dims, WeightUnits } from "@swyft/types";

const Weight = ({ data }: WeightProps) => {
  const translate = useTranslate();
  const unit = WeightUnits.POUND;

  if (!data) {
    return (
      <Typography variant="body1">
        {translate("shared.content.not_available")}
      </Typography>
    );
  }

  return (
    <Typography variant="body1">
      <Typography variant="inherit" component="span">
        {data.weight}
      </Typography>
      <Typography
        variant="caption"
        component="span"
        sx={({ palette }) => ({
          color: palette.neutralScale[500],
          fontStyle: "italic",
          ml: 0.5,
        })}
      >
        {translate(`shared.label.unit.${unit}.long`, {
          smart_count: data.weight,
        })}
      </Typography>
    </Typography>
  );
};

interface WeightProps {
  data?: Dims;
}

export default Weight;
