import {
  useTranslate,
  useNotify,
  useRefresh,
  useCreate,
  useDelete,
  useUpdate,
} from "react-admin";
import { useState } from "react";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { AppResource } from "~/config/resources";
import { WebhookTopic, WebhookStatus, MerchantWebhook } from "@swyft/types";
import {
  Link,
  Typography,
  styled,
  LoadingButton,
  Switch,
  Table,
  TableBody,
  Alert,
  TableCell,
  TextField,
  TableHead,
  Stack,
  Skeleton,
  Breadcrumbs,
  TableRow,
} from "@helo/ui";
import { useForm, useWatch } from "react-hook-form";
import { isValidUrl } from "~/common/helpers";

const TOPIC = WebhookTopic.TRACK_LABEL_UPDATES;

export const Webhooks = () => {
  const translate = useTranslate();
  const { merchant } = useAuthenticatedContext();
  const [create, { isLoading: createContextIsLoading }] = useCreate();
  const [deleteOne, { isLoading: deleteContextIsLoading }] = useDelete();
  const [update, { isLoading: updateContextIsLoading }] = useUpdate();
  const [editWebhook, setEditWebhook] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const defaultValues: WebhookForm = {
    notificationUrl: merchant?.webhooks?.TRACK_LABEL_UPDATES?.notificationUrl,
    status: false,
    deleteWebhook: false,
    addWebhook: false,
  };

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    reset,
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (formValues: WebhookForm) => {
    const { notificationUrl, status, deleteWebhook, addWebhook } = formValues;
    const webhookStatus = status
      ? WebhookStatus.ACTIVE
      : WebhookStatus.INACTIVE;

    if (notificationUrl && !isValidUrl(notificationUrl)) {
      notify("webhooks.error", {
        type: "warning",
      });
    }

    if (merchant?.id && notificationUrl && isValidUrl(notificationUrl)) {
      try {
        if (addWebhook) {
          await create(
            AppResource.Webhook,
            {
              data: {
                merchantId: merchant?.id,
                topic: TOPIC,
                webhook: {
                  notificationUrl,
                  status: webhookStatus,
                  failureCount: 0,
                },
              },
            },
            {
              onSuccess: (data) => {
                notify("webhooks.success", {
                  type: "success",
                });
                reset();
                refresh();
                if (status) {
                  setValue("status", true);
                }
              },
            },
          );
        } else if (deleteWebhook) {
          await deleteOne(
            AppResource.Webhook,
            {
              id: merchant.id,
            },
            {
              onSuccess: (data) => {
                notify("webhooks.success_delete", {
                  type: "success",
                });
                refresh();
                reset();
              },
            },
          );
        } else {
          await update(
            AppResource.Webhook,
            {
              id: merchant?.id,
              data: {
                merchantId: merchant?.id,
                topic: TOPIC,
                webhook: {
                  notificationUrl,
                  status: webhookStatus,
                },
              },
            },
            {
              onSuccess: (data) => {
                notify("webhooks.success", {
                  type: "success",
                });
                setEditWebhook(false);
                refresh();
                reset();
              },
            },
          );
        }
      } catch (err) {
        console.error(err);
        notify("webhooks.error", {
          type: "warning",
        });
      }
    }
  };

  const WebhookForm = () => {
    const statusValue = useWatch({
      control,
      name: "status",
    });

    if (merchant?.webhooks) {
      const { notificationUrl, failureCount, failureMsg, status } = merchant
        .webhooks[TOPIC] as MerchantWebhook;

      const activeFailure = failureMsg !== undefined && failureMsg !== null;

      return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Table
            sx={(theme: any) => ({
              mt: 3,
              [theme.breakpoints.down("lg")]: {
                display: "flex",
                flexDirection: "row",
              },
            })}
            aria-label={translate("webhooks.name")}
          >
            <TableHead>
              <TableRow
                sx={(theme: any) => ({
                  [theme.breakpoints.down("lg")]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                })}
              >
                <TableCell
                  sx={(theme: any) => ({
                    [theme.breakpoints.down("lg")]: {
                      width: "150px",
                      borderBottom: "none",
                      paddingLeft: 0,
                    },
                  })}
                >
                  {translate("webhooks.status")}
                </TableCell>
                <TableCell
                  sx={(theme: any) => ({
                    [theme.breakpoints.down("lg")]: {
                      width: "150px",
                      borderBottom: "none",
                      paddingLeft: 0,
                    },
                  })}
                >
                  {translate("webhooks.url")}
                </TableCell>
                {activeFailure && !editWebhook && !formState.isSubmitting && (
                  <TableCell
                    sx={(theme: any) => ({
                      color: "error.main",
                      [theme.breakpoints.down("lg")]: {
                        width: "150px",
                        borderBottom: "none",
                        paddingLeft: 0,
                      },
                    })}
                  >
                    {translate("webhooks.error_alert")}
                  </TableCell>
                )}
                <TableCell
                  sx={(theme: any) => ({
                    [theme.breakpoints.down("lg")]: {
                      borderBottom: "none",
                    },
                  })}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={(theme: any) => ({
                  [theme.breakpoints.down("lg")]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                })}
              >
                {formState.isSubmitting ? (
                  <>
                    <TableCell
                      sx={(theme: any) => ({
                        width: "215px",
                        [theme.breakpoints.down("lg")]: {
                          borderBottom: "none",
                        },
                      })}
                      component="th"
                      scope="row"
                    >
                      <Stack>
                        <Skeleton variant="rectangular" />
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={(theme: any) => ({
                        "& div": {
                          width: "100%",
                        },
                        [theme.breakpoints.down("lg")]: {
                          borderBottom: "none",
                        },
                      })}
                    >
                      <Stack>
                        <Skeleton variant="rectangular" />
                      </Stack>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell
                      sx={(theme: any) => ({
                        width: "215px",
                        [theme.breakpoints.down("lg")]: {
                          borderBottom: "none",
                        },
                      })}
                      component="th"
                      scope="row"
                    >
                      {editWebhook ? (
                        <>
                          <Switch
                            aria-label={status}
                            {...register("status")}
                            name="status"
                            color="success"
                            checked={statusValue}
                            onChange={() => {
                              if (getValues("status") === true) {
                                setValue("status", false);
                              } else {
                                setValue("status", true);
                              }
                            }}
                          />
                          {statusValue
                            ? WebhookStatus.ACTIVE
                            : WebhookStatus.INACTIVE}
                        </>
                      ) : (
                        <StatusContainer>
                          <StatusDot
                            sx={({ palette }) => {
                              const color =
                                status === WebhookStatus.ACTIVE
                                  ? `${palette.success.main}`
                                  : `${palette.neutral.main}`;

                              return {
                                backgroundColor: color,
                              };
                            }}
                          />
                          {status === WebhookStatus.ACTIVE
                            ? WebhookStatus.ACTIVE
                            : WebhookStatus.INACTIVE}
                        </StatusContainer>
                      )}
                    </TableCell>
                    <TableCell
                      sx={(theme: any) => ({
                        "& div": {
                          width: "100%",
                        },
                        [theme.breakpoints.down("lg")]: {
                          borderBottom: "none",
                        },
                      })}
                    >
                      {editWebhook ? (
                        <TextField
                          sx={{ minWidth: "40%" }}
                          required
                          type="url"
                          label={translate("webhooks.url_edit")}
                          {...register("notificationUrl")}
                          name="notificationUrl"
                        />
                      ) : (
                        notificationUrl
                      )}
                    </TableCell>
                    {activeFailure && !editWebhook && (
                      <TableCell
                        sx={(theme: any) => ({
                          color: "error.main",
                          [theme.breakpoints.down("lg")]: {
                            borderBottom: "none",
                          },
                        })}
                      >
                        {failureMsg}
                      </TableCell>
                    )}
                  </>
                )}
                <TableCell
                  sx={(theme: any) => ({
                    [theme.breakpoints.down("lg")]: {
                      borderBottom: "none",
                    },
                  })}
                  align="right"
                >
                  {!deleteContextIsLoading && (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="medium"
                      loading={updateContextIsLoading}
                      onClick={() => {
                        if (status === WebhookStatus.ACTIVE && !editWebhook) {
                          setValue("status", true);
                        }
                        if (!editWebhook) setEditWebhook(true);
                      }}
                    >
                      {translate(
                        `webhooks.btn_${editWebhook ? "save" : "edit"}`,
                      )}
                    </LoadingButton>
                  )}

                  {!editWebhook && (
                    <LoadingButton
                      sx={{ marginLeft: 3 }}
                      type="submit"
                      variant="contained"
                      color="error"
                      size="medium"
                      loading={deleteContextIsLoading}
                      onClick={() => setValue("deleteWebhook", true)}
                    >
                      {translate(`webhooks.btn_delete`)}
                    </LoadingButton>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Form>
      );
    } else {
      return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Table
            sx={(theme: any) => ({
              mt: 3,
              [theme.breakpoints.down("lg")]: {
                display: "flex",
                flexDirection: "row",
              },
            })}
            aria-label={translate("webhooks.name")}
          >
            <TableHead>
              <TableRow
                sx={(theme: any) => ({
                  [theme.breakpoints.down("lg")]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                })}
              >
                <TableCell
                  sx={(theme: any) => ({
                    [theme.breakpoints.down("lg")]: {
                      borderBottom: "none",
                      paddingLeft: 0,
                      height: "70px",
                    },
                  })}
                >
                  {translate("webhooks.status")}
                </TableCell>
                <TableCell
                  sx={(theme: any) => ({
                    [theme.breakpoints.down("lg")]: {
                      borderBottom: "none",
                      paddingLeft: 0,
                      height: "70px",
                    },
                  })}
                >
                  {translate("webhooks.url")}
                </TableCell>
                <TableCell
                  sx={(theme: any) => ({
                    [theme.breakpoints.down("lg")]: {
                      borderBottom: "none",
                      paddingLeft: 0,
                      height: "70px",
                    },
                  })}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={(theme: any) => ({
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                },
              })}
            >
              <TableRow
                sx={(theme: any) => ({
                  [theme.breakpoints.down("lg")]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                })}
              >
                {formState.isSubmitting ? (
                  <>
                    <TableCell
                      sx={(theme: any) => ({
                        width: "215px",
                        [theme.breakpoints.down("lg")]: {
                          borderBottom: "none",
                        },
                      })}
                      component="th"
                      scope="row"
                    >
                      <Stack>
                        <Skeleton variant="rectangular" />
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={(theme: any) => ({
                        "& div": {
                          width: "100%",
                        },
                        [theme.breakpoints.down("lg")]: {
                          borderBottom: "none",
                        },
                      })}
                    >
                      <Stack>
                        <Skeleton variant="rectangular" />
                      </Stack>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell
                      sx={(theme: any) => ({
                        width: "215px",
                        [theme.breakpoints.down("lg")]: {
                          borderBottom: "none",
                        },
                      })}
                      component="th"
                      scope="row"
                    >
                      <Switch
                        {...register("status")}
                        name="status"
                        color="success"
                        checked={statusValue}
                        onChange={() => {
                          if (getValues("status") === true) {
                            setValue("status", false);
                          } else {
                            setValue("status", true);
                          }
                        }}
                      />
                      {statusValue
                        ? WebhookStatus.ACTIVE
                        : WebhookStatus.INACTIVE}
                    </TableCell>
                    <TableCell
                      sx={(theme: any) => ({
                        "& div": {
                          width: "100%",
                        },
                        [theme.breakpoints.down("lg")]: {
                          borderBottom: "none",
                        },
                      })}
                    >
                      <TextField
                        sx={{ minWidth: "40%" }}
                        required
                        type="url"
                        label={translate("webhooks.url_add")}
                        {...register("notificationUrl")}
                        name="notificationUrl"
                        placeholder={translate("webhooks.url_add")}
                      />
                    </TableCell>
                  </>
                )}
                <TableCell
                  align="right"
                  sx={(theme: any) => ({
                    [theme.breakpoints.down("lg")]: {
                      borderBottom: "none",
                    },
                  })}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    onClick={() => setValue("addWebhook", true)}
                    loading={createContextIsLoading}
                  >
                    {translate(`webhooks.btn_add`)}
                  </LoadingButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Form>
      );
    }
  };

  return (
    <>
      <Typography sx={{ mt: 10 }} variant="h5" fontWeight={900}>
        {translate("webhooks.name")}
      </Typography>
      <Breadcrumbs sx={{ mt: 2 }}>
        <Link underline="none" color="text.primary">
          {translate("webhooks.description")}
        </Link>
      </Breadcrumbs>
      <WebhookContainer>
        <WebhookForm />
      </WebhookContainer>
      <Alert severity="info">
        <Link
          href="https://api.useswyft.com/docs#tag/Webhooks"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate("webhooks.documentation_description")}
        </Link>
      </Alert>
    </>
  );
};

const WebhookContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "40px",
}));

const StatusDot = styled("div")({
  width: "20px",
  height: "20px",
  borderRadius: 10,
  marginRight: 16,
});

const StatusContainer = styled("div")({
  display: "flex",
});

const Form = styled("form")({
  width: "100%",
});

interface WebhookForm {
  notificationUrl?: string;
  status: boolean;
  deleteWebhook: boolean;
  addWebhook: boolean;
}
