// @ts-nocheck
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { withSearch } from "@elastic/react-search-ui";
import {
  CollectionReference,
  DocumentData,
  OrderByDirection,
  Query,
  Timestamp,
} from "@firebase/firestore-types";
import {
  Box,
  Button as ButtonMui,
  DeleteIcon,
  IconButton,
  InfoIcon,
  InfoOutlinedIcon,
  Link,
  LoadingButton,
  PrintIcon,
  Typography,
} from "@helo/ui";
import { ILabelV2, TrackingEventState } from "@swyft/swyft-common";
import { Currency } from "@swyft/types";
import {
  Button,
  DatePicker,
  Empty,
  message,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import { SortOrder } from "antd/lib/table/interface";
import {
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { isEmpty, isNil } from "lodash";
import { Moment } from "moment";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useLocaleState, useTranslate } from "react-admin";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { exportPDF } from "~/common/fileExportHelper";
import { fulfillExternalOrders, LabelStateTag } from "~/common/labelHelpers";

import {
  COMPANY_NAME,
  DATE_FORMATS,
  GENERIC_ERROR_MESSAGE,
} from "~/common/consts";
import { IElasticProps } from "~/common/elasticAppSearchTypes";
import { currencyFormatter } from "~/common/helpers";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useDomainContext } from "~/components/DomainContext";
import { AppResource } from "~/config/resources";
import { Routes } from "~/config/Routes";
import { useLabelDataProvider } from "~/services/data/shipment";
import {
  deleteLabel,
  getLabelByMerchantIdAndLabelId,
  getLabelsV2Collection,
  getLatestDeliveryAttemptForLabel,
} from "~/services/firestore";
import { isLabelDetailsEnabled } from "~/services/remote-config";
import { ExportLabelDataModal } from "./ExportLabelDataModal";
import { ISearchQuery, SearchQueryType } from "./LabelsContainer";
import { LabelStateModal } from "./LabelStateModal";

const PAGE_SIZE = 25;

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

interface PassedProps {
  searchQuery: ISearchQuery;
}
interface IStateWithFailureReason {
  state: TrackingEventState;
  failureReason: string;
}

interface ILabelWithFailureReason extends ILabelV2 {
  stateWithFailureReason: IStateWithFailureReason;
}

const isDeletable = (label: ILabelV2) => {
  return label.state === TrackingEventState.PENDING;
};

const useSortOrder = <T extends SortOrder>(
  stateArray: T[],
  defaultStateIndex: number,
  clearIndex: number = 2,
): [T, () => void, () => void] => {
  const [index, setIndex] = useState(defaultStateIndex);
  const toggleSortOrder = () => {
    setIndex((prev) => (prev + 1) % stateArray.length);
  };

  const clearSortOrder = () => {
    setIndex(clearIndex);
  };

  return [stateArray[index], toggleSortOrder, clearSortOrder];
};

enum DATE_RANGE_TYPE {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  CUSTOM = "CUSTOM",
  SEARCH = "SEARCH", // Tab is hidden
}

enum FAILURE_REASON_TYPE {
  NO_ACCESS_COMMERCIAL_ADDRESS = "No Access-Commercial Address",
  NO_ACCESS_RESIDENTIAL_ADDRESS = "No Access-Residential Address",
  UNABLE_TO_COLLECT_SIGNATURE = "Unable to Collect Signature/Verify ID",
  CUSTOMER_REFUSED_DELIVERY = "Customer Refuses Delivery",
  INCORRECT_CUSTOMER_ADDRESS = "Incorrect Customer Address",
  MISSING_PACKAGE = "Missing Package",
  DRIVER_ACCIDENT = "Driver Accident",
  ITEM_DAMAGED = "Item Damaged",
  PAST_DELIVERY_WINDOW = "Past Delivery Window",
  NO_SPACE_IN_CAR = "No Space In Car",
  OTHER = "Other",
  UNKNOWN = "unknown",
}

type Props = IElasticProps & PassedProps;

const LabelsTable = ({
  isLoading,
  results,
  current,
  resultsPerPage,
  totalResults,
  setSearchTerm,
  setCurrent,
  setResultsPerPage,
  resultSearchTerm,
  searchQuery,
}: Props) => {
  // @ts-ignore
  const { user, merchant } = useAuthenticatedContext();
  const { labelController } = useDomainContext();

  const [labels, setLabels] = useState<ILabelV2[]>([]);
  const [labelsWithFailureReason, setLabelsWithFailureReason] = useState<
    ILabelWithFailureReason[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMoreLabels, setHasMoreLabels] = useState<boolean>(true);
  const [selectedLabels, setSelectedLabels] = useState<ILabelV2[]>([]);
  const [isDeletingLabels, setIsDeletingLabels] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isExportModalVisible, setIsExportModalVisible] =
    useState<boolean>(false);
  const [isLabelStateModalVisible, setIsLabelStateModalVisible] =
    useState<boolean>(false);
  const [dateRangeType, setDateRangeType] = useState<DATE_RANGE_TYPE>(
    DATE_RANGE_TYPE.MONTH,
  );
  const [dateRangeStart, setDateRangeStart] = useState<Moment>(
    moment().startOf("month"),
  );
  const [dateRangeEnd, setDateRangeEnd] = useState<Moment>(
    moment().endOf("month"),
  );
  const [createdAtSortOrder, toggleCreatedAtSortOrder] = useSortOrder(
    ["descend", "ascend", null],
    0,
  );
  const [orderNumberSortOrder, clearOrderNumberSortOrder] = useSortOrder(
    ["descend", "ascend", null],
    2,
  );
  const translate = useTranslate();
  const [, dataProvider] = useLabelDataProvider();
  /**
   * Handle the FuzzySearchBar input (clicking on an autocomplete option or full search)
   * */
  useEffect(() => {
    if (!isEmpty(searchQuery)) {
      // Edge case where alternating between exact and full search of the same keyword
      if (searchQuery.keyword === resultSearchTerm) {
        handleSearchByLabelIds(results.map((label: any) => label.id.raw));
      }

      // Full search of a keyword from FuzzySearchBar
      if (searchQuery.type === SearchQueryType.KEYWORD && searchQuery.keyword) {
        setSearchTerm(searchQuery.keyword); // No-op if search term is the same
      }

      // Autocomplete option selected from FuzzySearchBar
      if (
        searchQuery.type === SearchQueryType.LABEL_ID &&
        searchQuery.labelId
      ) {
        handleSearchByLabelIds([searchQuery.labelId]);
      }
      setDateRangeType(DATE_RANGE_TYPE.SEARCH);
    } else {
      // Reset results to the default state
      setDateRangeType(DATE_RANGE_TYPE.MONTH);
    }
  }, [searchQuery]);

  /**
   * Handles displaying new labels when the ES results change.
   */
  useEffect(() => {
    handleSearchByLabelIds(results.map((label: any) => label.id.raw));
  }, [results]);

  /**
   * Handles displaying new labels when the date range changes
   */
  useEffect(() => {
    fetchLabels();
  }, [user, dateRangeEnd, createdAtSortOrder, orderNumberSortOrder]);

  useEffect(() => {
    switch (dateRangeType) {
      case DATE_RANGE_TYPE.DAY:
        setDateRangeStart(moment());
        setDateRangeEnd(moment());
        break;
      case DATE_RANGE_TYPE.WEEK:
        setDateRangeStart(moment().startOf("week"));
        setDateRangeEnd(moment().endOf("week"));
        break;
      case DATE_RANGE_TYPE.MONTH:
        setDateRangeStart(moment().startOf("month"));
        setDateRangeEnd(moment().endOf("month"));
        break;
      default:
    }
  }, [dateRangeType]);

  //TODO: Move this mapping to domain layer when label list is natively built in react-admin
  const addLabelRate = (labels: ILabelV2[]) => {
    return labels.map((label) => {
      const { total, currency } = labelController.getCostBreakdown({
        merchant,
        label,
        includeActuals: true,
      });
      return { ...label, rate: { total, currency } };
    });
  };

  const baseQuery = () => {
    let baseQuery: Query<DocumentData> | CollectionReference<DocumentData> =
      getLabelsV2Collection(user.merchantId);

    if (createdAtSortOrder != null) {
      baseQuery = query(
        baseQuery,
        where("createdAt", ">=", dateRangeStart.startOf("day").toDate()),
        where("createdAt", "<=", dateRangeEnd.endOf("day").toDate()),
        orderBy("createdAt", mapSortOrderToOrderDirection(createdAtSortOrder)),
      );
    }

    if (orderNumberSortOrder != null) {
      baseQuery = query(
        baseQuery,
        orderBy(
          "orderNumber",
          mapSortOrderToOrderDirection(orderNumberSortOrder),
        ),
      );
    }

    return query(baseQuery, orderBy("id"), limit(PAGE_SIZE));
  };

  const mapSortOrderToOrderDirection = (
    s: SortOrder,
  ): OrderByDirection | undefined => {
    if (s === "ascend") return "asc";
    return "desc";
  };

  const fetchLabels = async () => {
    if (!merchant?.isActive) {
      setLabels([]);
      return;
    }

    setIsTableLoading(true);
    const res = await getDocs(baseQuery());
    const labels = res.docs.map((docSnapshot) =>
      docSnapshot.data(),
    ) as ILabelV2[];

    //TODO: Move this mapping to domain layer when label list is natively built in react-admin
    const labelsWithRate = addLabelRate(labels);
    const labelsWithFailureReason = await patchFailureReasonToLabels(
      labelsWithRate,
    );

    setCurrentPage(0);
    setHasMoreLabels(labels.length === PAGE_SIZE);
    setLabels(labelsWithRate as any);
    setLabelsWithFailureReason(labelsWithFailureReason);
    setIsTableLoading(false);
  };

  /**
   * Patch failure reason to label if label state is FAILED
   * Otherwise, left failure reason as empty
   */
  const patchFailureReasonToLabels = async (
    labels: ILabelV2[],
  ): Promise<ILabelWithFailureReason[]> => {
    const labelsWithFailureReason: ILabelWithFailureReason[] = [];

    await Promise.all(
      labels.map(async (label) => {
        let failureReason = "";
        if (label.state === TrackingEventState.FAILED) {
          const deliveryAttempt = await getLatestDeliveryAttemptForLabel(
            label.id,
            label.merchantId,
          );

          if (!isNil(deliveryAttempt.errorCode)) {
            failureReason = FAILURE_REASON_TYPE[deliveryAttempt.errorCode];
          } else {
            failureReason = FAILURE_REASON_TYPE.UNKNOWN;
          }
        }

        labelsWithFailureReason.push({
          ...label,
          stateWithFailureReason: {
            state: label.state,
            failureReason: failureReason,
          },
        });
      }),
    );

    return Promise.resolve(labelsWithFailureReason);
  };

  const handleSearchByLabelIds = async (labelIds: string[]) => {
    setIsTableLoading(true);

    const labels = await Promise.all(
      labelIds.map((id) => getLabelByMerchantIdAndLabelId(user.merchantId, id)),
    );

    //TODO: Move this mapping to domain layer when label list is natively built in react-admin
    const labelsWithRate = addLabelRate(labels);

    const labelsWithFailureReason = await patchFailureReasonToLabels(
      labelsWithRate,
    );
    setLabels(labelsWithRate);
    setLabelsWithFailureReason(labelsWithFailureReason);
    setIsTableLoading(false);
  };

  const handleSetDay = (value: Moment | null, dateString: string) => {
    if (value) {
      setDateRangeStart(value.clone().startOf("day"));
      setDateRangeEnd(value.clone().endOf("day"));
    }
  };

  const handleSetWeek = (value: Moment | null, dateString: string) => {
    if (value) {
      setDateRangeStart(value.clone().startOf("isoWeek"));
      setDateRangeEnd(value.clone().endOf("isoWeek"));
    }
  };

  const handleSetMonth = (date: Moment | null) => {
    if (date) {
      setDateRangeStart(date.clone().startOf("month"));
      setDateRangeEnd(date.clone().endOf("month"));
    }
  };

  const handleSetCustomDateRange = (range: any) => {
    if (range[0] && range[1]) {
      setDateRangeStart(range[0]);
      setDateRangeEnd(range[1]);
    }
  };

  const handlePrevDay = () => {
    setDateRangeStart(dateRangeStart.clone().subtract(1, "day"));
    setDateRangeEnd(dateRangeEnd.clone().subtract(1, "day"));
  };

  const handleNextDay = () => {
    setDateRangeStart(dateRangeStart.clone().add(1, "day"));
    setDateRangeEnd(dateRangeEnd.clone().add(1, "day"));
  };

  const handlePrevWeek = () => {
    setDateRangeStart(dateRangeStart.clone().subtract(1, "week"));
    setDateRangeEnd(dateRangeEnd.clone().subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setDateRangeStart(dateRangeStart.clone().add(1, "week"));
    setDateRangeEnd(dateRangeEnd.clone().add(1, "week"));
  };

  const handlePrevMonth = () => {
    setDateRangeStart(dateRangeStart.clone().subtract(1, "month"));
    setDateRangeEnd(dateRangeEnd.clone().subtract(1, "month").endOf("month"));
  };

  const handleNextMonth = () => {
    setDateRangeStart(dateRangeStart.clone().add(1, "month"));
    setDateRangeEnd(dateRangeEnd.clone().add(1, "month").endOf("month"));
  };

  const handleSetDateRangeType = (type: string) => {
    setDateRangeType(type as DATE_RANGE_TYPE);
    setHasMoreLabels(true);
  };

  const handleNextPage = async () => {
    const lastLabel = labels[labels.length - 1] as ILabelV2;
    const res = await getDocs(
      query(baseQuery(), startAfter(lastLabel.createdAt, lastLabel.id)),
    );
    const fetchedLabels = res.docs.map((docSnapshot) =>
      docSnapshot.data(),
    ) as ILabelV2[];

    //TODO: Move this mapping to domain layer when label list is natively built in react-admin
    const labelsWithRates = addLabelRate(fetchedLabels);
    const labelsWithFailureReason = await patchFailureReasonToLabels(
      labelsWithRates,
    );

    if (fetchedLabels.length != 0) {
      setLabels(labelsWithRates as any);
      setLabelsWithFailureReason(labelsWithFailureReason);
      setCurrentPage(currentPage + 1);
    }

    if (fetchedLabels.length < PAGE_SIZE) {
      setHasMoreLabels(false);
    }
  };

  const handlePrevPage = async () => {
    const firstLabel = labels[0] as ILabelV2;
    const res = await getDocs(
      query(
        baseQuery(),
        endBefore(firstLabel.createdAt, firstLabel.id),
        limitToLast(PAGE_SIZE),
      ),
    );
    const newLabels = res.docs.map((docSnapshot) =>
      docSnapshot.data(),
    ) as ILabelV2[];

    //TODO: Move this mapping to domain layer when label list is natively built in react-admin
    const labelsWithRates = addLabelRate(newLabels);

    const labelsWithFailureReason = await patchFailureReasonToLabels(
      labelsWithRates,
    );

    setLabels(labelsWithRates as any);
    setLabelsWithFailureReason(labelsWithFailureReason);
    setHasMoreLabels(true);
    setCurrentPage(currentPage - 1);
  };

  const handleDeleteLabels = async (
    labels: ILabelV2[],
    isTableAction: boolean = false,
  ) => {
    if (isTableAction) {
      setIsTableLoading(true);
    } else {
      setIsDeletingLabels(true);
    }

    try {
      await Promise.all(labels.map((label) => deleteLabel(label.id)));

      message.success("Cancelled label(s)");
    } catch (error) {
      message.error(`Error happened while cancelling labels:  Error: ${error}`);
      console.error(error);
    }
    setSelectedLabels([]);
    if (isTableAction) {
      setIsTableLoading(false);
    } else {
      setIsDeletingLabels(false);
    }
  };

  const handleExportLabels = async (labels: ILabelV2[]) => {
    setIsTableLoading(true);
    try {
      const { shipDate, destination } = labels[0];
      const formattedDate = moment(shipDate).format(DATE_FORMATS.moment.export);
      const fileName =
        labels.length > 1
          ? `${COMPANY_NAME.toLowerCase()}-labels--${formattedDate}`
          : `${COMPANY_NAME.toLowerCase()}-label--${formattedDate}-${
              destination.firstName
            }-${destination.lastName}`;
      const { data } = await dataProvider.getPrintPdfs(AppResource.Shipment, {
        labelIds: labels.map((label) => label.id),
      });
      await exportPDF(data, fileName);
      fulfillExternalOrders(labels, merchant);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
    setIsTableLoading(false);
  };

  const showExportModal = () => {
    setIsExportModalVisible(true);
  };

  const hideExportModal = () => {
    setIsExportModalVisible(false);
  };

  const hideLabelStateModal = () => {
    setIsLabelStateModalVisible(false);
  };
  const showLabelStateModal = () => {
    setIsLabelStateModalVisible(true);
  };
  const navigate = useNavigate();
  const labelColumns = () => {
    const [locale] = useLocaleState();
    const columnsTemplate = [
      {
        title: translate("resources.shipments.fields.trackingNumber"),
        dataIndex: "trackingNumber",
        align: "center" as any,
        render: (trackingNumber: string) => (
          <Typography variant="body2">{trackingNumber}</Typography>
        ),
      },
      {
        title: translate("resources.shipments.fields.createdAt"),
        dataIndex: "createdAt",
        render: (createdAt: Timestamp) => (
          <p>{moment(createdAt.toDate()).format("MMM. D, [at] h:mm a")}</p>
        ),
        sortOrder: createdAtSortOrder,
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              clearOrderNumberSortOrder();
              toggleCreatedAtSortOrder();
            },
          };
        },
      },
      {
        title: translate("resources.shipments.fields.shipDate"),
        dataIndex: "shipDate",
        render: (shipDate: string) => (
          <p>{moment(shipDate).format("MMM. DD")}</p>
        ),
      },
      {
        title: translate("shipments.content.list.table.customerDetails"),
        dataIndex: "destination",
        render: (destination: any) => (
          <>
            <Typography variant="body2" component="p">
              {`${destination.firstName} ${destination.lastName}`}
            </Typography>
            <Typography variant="body2" component="p">
              {`${destination.address.line1} ${
                destination.address.line2 ?? ""
              }, ${destination.address.city}, ${destination.address.province} ${
                destination.address.postalCode
              }`}
            </Typography>
            <Typography variant="body2" component="p">
              {destination.phone}
            </Typography>
            <Typography variant="body2" component="p">
              {destination.email}
            </Typography>
          </>
        ),
      },
      {
        title: translate("shipments.content.list.table.packageType"),
        dataIndex: "dims",
        render: (dims: any) =>
          dims && (
            <Typography variant="body2" component="p">
              {`${dims.length}" x ${dims.width}" x ${dims.height}",  ${dims.weight}lbs`}
            </Typography>
          ),
      },
      {
        title: translate("resources.shipments.fields.serviceType"),
        align: "center" as any,
        dataIndex: "serviceType",
        render: (serviceType: string) => (
          <Typography variant="body2" component="p">
            {translate(`shipments.content.details.serviceType.${serviceType}`)}
          </Typography>
        ),
      },
      {
        title: translate("shipments.content.list.table.rate"),
        align: "center" as any,
        dataIndex: "rate",
        render: (rate: { rate: { total: number; currency: Currency } }) => (
          <Typography variant="body2" component="p">
            {currencyFormatter(locale, rate.total, rate.currency)}
          </Typography>
        ),
      },
      {
        title: (
          <Space>
            <Typography variant="body2">
              {translate("resources.shipments.fields.state")}
            </Typography>
            <InfoOutlinedIcon
              color="primary"
              sx={{
                cursor: "pointer",
                mt: 1,
              }}
              onClick={showLabelStateModal}
            />
          </Space>
        ),
        dataIndex: "stateWithFailureReason",
        align: "center" as any,
        render: (stateWithFailureReason: IStateWithFailureReason) => (
          <div style={{ textAlign: "center" }}>
            <LabelStateTag
              state={stateWithFailureReason.state}
              failureReason={stateWithFailureReason.failureReason}
            />
          </div>
        ),
      },
      {
        title: translate("shared.action.title"),
        align: "center" as any,
        render: (_text: string, label: ILabelV2) => (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {isLabelDetailsEnabled() && (
              <IconButton
                variant="text"
                size="medium"
                color="primary"
                sx={{ m: 1 }}
                onClick={() => navigate(`${Routes.Shipments}/${label.id}`)}
              >
                <InfoIcon />
              </IconButton>
            )}
            <IconButton
              variant="text"
              size="medium"
              color="primary"
              onClick={() => handleExportLabels([label])}
              sx={{ m: 1 }}
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              variant="text"
              color="error"
              size="medium"
              sx={{ m: 1 }}
              onClick={() => handleDeleteLabels([label], true)}
              disabled={!isDeletable(label)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ];

    if (!isNil(merchant.shopifyConfig)) {
      const shopifyOrderNumberColumn = {
        title: "Shopify Order #",
        align: "center" as any,
        dataIndex: "shopifyOrderNumber",
      };
      // @ts-ignore
      columnsTemplate.splice(7, 0, shopifyOrderNumberColumn);
    }

    return columnsTemplate;
  };

  const tableHeader = () => {
    return (
      <Row justify="space-between" style={{ paddingBottom: "10px" }}>
        <Tabs activeKey={dateRangeType} onChange={handleSetDateRangeType}>
          <TabPane tab="Day" key={DATE_RANGE_TYPE.DAY}>
            <Row>
              <Button icon={<LeftOutlined />} onClick={handlePrevDay} />
              <DatePicker
                style={{ flex: 1 }}
                value={dateRangeStart}
                format={"MMMM Do, YYYY"}
                onChange={handleSetDay}
                picker="date"
                allowClear={false}
              />
              <Button icon={<RightOutlined />} onClick={handleNextDay} />
            </Row>
          </TabPane>
          <TabPane tab="Week" key={DATE_RANGE_TYPE.WEEK}>
            <Row>
              <Button icon={<LeftOutlined />} onClick={handlePrevWeek} />
              <DatePicker
                style={{ flex: 1 }}
                value={dateRangeStart}
                onChange={handleSetWeek}
                picker="week"
                format={"MMMM Do, YYYY"}
                allowClear={false}
              />
              <Button icon={<RightOutlined />} onClick={handleNextWeek} />
            </Row>
          </TabPane>
          <TabPane tab="Month" key={DATE_RANGE_TYPE.MONTH}>
            <Row>
              <Button icon={<LeftOutlined />} onClick={handlePrevMonth} />
              <DatePicker
                style={{ flex: 1 }}
                value={dateRangeStart}
                format={"MMMM, YYYY"}
                onChange={handleSetMonth}
                picker="month"
                allowClear={false}
              />
              <Button icon={<RightOutlined />} onClick={handleNextMonth} />
            </Row>
          </TabPane>
          <TabPane tab="Range" key={DATE_RANGE_TYPE.CUSTOM}>
            <RangePicker
              value={[dateRangeStart, dateRangeEnd]}
              format={"MMM. Do, YYYY"}
              onCalendarChange={handleSetCustomDateRange}
              allowClear={false}
            />
          </TabPane>
        </Tabs>

        <Space direction="horizontal">
          <ButtonMui
            variant="contained"
            size="medium"
            onClick={showExportModal}
          >
            Export or Print Selected&nbsp;
            {selectedLabels.length > 0 && `(${selectedLabels.length})`}
          </ButtonMui>
          <Tooltip
            placement="bottomRight"
            title="Only labels in state PENDING can be cancelled."
          >
            <LoadingButton
              variant="contained"
              size="medium"
              color="error"
              loading={isDeletingLabels}
              disabled={selectedLabels.filter(isDeletable).length < 1}
              onClick={() =>
                handleDeleteLabels(selectedLabels.filter(isDeletable))
              }
            >
              Cancel Shipment&nbsp;
              {selectedLabels.filter(isDeletable).length > 0 &&
                `(${selectedLabels.filter(isDeletable).length})`}
            </LoadingButton>
          </Tooltip>
        </Space>
        <ExportLabelDataModal
          selectedLabels={selectedLabels}
          isModalVisible={isExportModalVisible}
          hideModal={hideExportModal}
          merchantId={user.merchantId}
        />
      </Row>
    );
  };

  // Pagination for Day, Week, Month, Custom date ranges
  const tableFooter = () => {
    if (dateRangeType !== DATE_RANGE_TYPE.SEARCH) {
      return (
        <Row justify="center">
          <Button
            icon={<LeftOutlined />}
            disabled={currentPage === 0}
            onClick={handlePrevPage}
          ></Button>
          <Button
            icon={<RightOutlined />}
            disabled={hasMoreLabels === false || labels.length === 0}
            onClick={handleNextPage}
          />
        </Row>
      );
    }
  };

  // Pagination for Search
  const paginationConfiguration =
    dateRangeType === DATE_RANGE_TYPE.SEARCH
      ? {
          onChange: (page: number, pageSize?: number) => {
            setResultsPerPage(pageSize);
            setCurrent(page);
          },
          total: totalResults,
          current,
          pageSize: resultsPerPage,
        }
      : false;

  const onSelectTableRow = (
    _selectedRowKeys: React.Key[],
    selectedRows: any[],
  ) => {
    setSelectedLabels(selectedRows);
  };

  const labelColumInstances = labelColumns();

  return (
    <>
      <Table
        rowKey="id"
        style={{ height: "100%", width: "100%" }}
        dataSource={labelsWithFailureReason}
        columns={labelColumInstances}
        size="small"
        loading={isTableLoading || isLoading}
        title={tableHeader}
        footer={tableFooter}
        pagination={paginationConfiguration}
        scroll={{ x: true }}
        rowSelection={{
          type: "checkbox",
          onChange: onSelectTableRow,
        }}
        onRow={(label) => ({
          ...(isLabelDetailsEnabled() && {
            onClick: () => navigate(`${Routes.Shipments}/${label.id}`),
          }),
        })}
        locale={{
          emptyText: (
            <Empty
              style={{ margin: "6em", color: "grey" }}
              description="No labels for date range"
            >
              <ButtonMui variant="contained" size="medium">
                <Link
                  component={RouterLink}
                  underline="none"
                  to={`${Routes.Shipments}/create`}
                  sx={({ palette }) => ({
                    color: `${palette.primary.contrastText}`,
                  })}
                >
                  {translate("shipments.content.list.table.create")}
                </Link>
              </ButtonMui>
            </Empty>
          ),
        }}
      />
      <LabelStateModal
        hideModal={hideLabelStateModal}
        isVisible={isLabelStateModalVisible}
      />
    </>
  );
};

// withSearch is an HOC for accessing the headless core directly.
export default withSearch(
  ({
    isLoading,
    results,
    current,
    resultsPerPage,
    totalResults,
    setSearchTerm,
    setCurrent,
    setResultsPerPage,
    resultSearchTerm,
    searchQuery,
  }: Props) => ({
    isLoading,
    results,
    current,
    resultsPerPage,
    totalResults,
    setSearchTerm,
    setCurrent,
    setResultsPerPage,
    resultSearchTerm,
    searchQuery,
  }),
)(LabelsTable);
