import { Alert, AlertProps, AlertTitle, Button } from "@helo/ui";
import { SxProps, Theme } from "@mui/material/styles";

const ActionAlert: React.FunctionComponent<ActionAlertProps> = ({
  actionHandler,
  actionText,
  alertMessage,
  alertSeverity = "info",
  sx = [],
}) => {
  return (
    <Alert
      severity={alertSeverity}
      sx={[
        { px: 3, py: 1, display: "flex", alignItems: "center" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      action={
        actionText && actionHandler ? (
          <Button color="inherit" size="small" onClick={actionHandler}>
            {actionText}
          </Button>
        ) : null
      }
    >
      <AlertTitle sx={{ marginBottom: 0, marginTop: 0, fontWeight: 400 }}>
        {alertMessage}
      </AlertTitle>
    </Alert>
  );
};

interface ActionAlertProps {
  actionHandler?: () => void;
  actionText?: string;
  alertMessage: string;
  alertSeverity?: AlertProps["severity"];
  sx?: SxProps<Theme>;
}

export default ActionAlert;
