"use strict";
exports.__esModule = true;
exports.ShopifyDiscountType = void 0;
/**
 * Specifies the type of discount to apply if enabled.
 * cartValueFlatDiscount (FLAT) or cartValueDiscountPercent (PERCENTAGE)
 * */
var ShopifyDiscountType;
(function (ShopifyDiscountType) {
    ShopifyDiscountType["FLAT"] = "FLAT";
    ShopifyDiscountType["PERCENTAGE"] = "PERCENTAGE";
})(ShopifyDiscountType = exports.ShopifyDiscountType || (exports.ShopifyDiscountType = {}));
