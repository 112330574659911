"use strict";
exports.__esModule = true;
exports.getRatesRequestSchema = void 0;
/* eslint-disable import/prefer-default-export */
var zod_1 = require("zod");
var types_1 = require("../../types");
var common_1 = require("../common");
exports.getRatesRequestSchema = zod_1.z.object({
    merchant: zod_1.z
        .object({
        id: zod_1.z.string()
    })
        .optional(),
    origin: common_1.addressSchema,
    destination: common_1.addressSchema,
    dimensions: common_1.dimensionsSchema,
    mode: zod_1.z
        .nativeEnum(types_1.ApiMode)
        .optional()
        .describe("Environment to get the rates.")
});
