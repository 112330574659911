"use strict";
exports.__esModule = true;
exports.isCloudFunctionUrl = exports.config = void 0;
var config = require("./config.json");
exports.config = config;
var isCloudFunctionUrl = function (configuration, functionName) {
    return configuration.cloudFunctions[functionName] &&
        configuration.cloudFunctions[functionName].includes("http");
};
exports.isCloudFunctionUrl = isCloudFunctionUrl;
