"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.getList = exports.get = exports.LabelOrgNotSpecified = exports.LabelNotFoundError = exports.ScanEventNotFoundError = exports.ScanEventsNotFoundError = void 0;
var firestore_1 = require("firebase/firestore");
var scanEventsConverter = {
    fromFirestore: function (snapshot) {
        var data = snapshot.data();
        return __assign(__assign({}, data), { timestamp: data.timestamp instanceof firestore_1.Timestamp
                ? data.timestamp.toDate()
                : data.timestamp });
    },
    toFirestore: function (model) {
        return model; // TODO: map appropriate values
    }
};
var getScanEventsCollection = function (db, merchantId, labelId) {
    return (0, firestore_1.collection)(db, "merchants/".concat(merchantId, "/labelsV2/").concat(labelId, "/scan-events")).withConverter(scanEventsConverter);
};
var ScanEventsNotFoundError = /** @class */ (function (_super) {
    __extends(ScanEventsNotFoundError, _super);
    function ScanEventsNotFoundError(merchantId, labelId) {
        return _super.call(this, "No scan events found for label ".concat(labelId, " with merchant ").concat(merchantId)) || this;
    }
    return ScanEventsNotFoundError;
}(Error));
exports.ScanEventsNotFoundError = ScanEventsNotFoundError;
var ScanEventNotFoundError = /** @class */ (function (_super) {
    __extends(ScanEventNotFoundError, _super);
    function ScanEventNotFoundError(merchantId, labelId, eventId) {
        return _super.call(this, "No scan event ".concat(eventId, " found for label ").concat(labelId, " with merchant ").concat(merchantId)) || this;
    }
    return ScanEventNotFoundError;
}(Error));
exports.ScanEventNotFoundError = ScanEventNotFoundError;
var LabelNotFoundError = /** @class */ (function (_super) {
    __extends(LabelNotFoundError, _super);
    function LabelNotFoundError(merchantId, labelId) {
        return _super.call(this, "No label id ".concat(labelId, " found for mecrchant id ").concat(merchantId)) || this;
    }
    return LabelNotFoundError;
}(Error));
exports.LabelNotFoundError = LabelNotFoundError;
var LabelOrgNotSpecified = /** @class */ (function (_super) {
    __extends(LabelOrgNotSpecified, _super);
    function LabelOrgNotSpecified() {
        return _super.call(this, "An organization needs to be specified to get a label") || this;
    }
    return LabelOrgNotSpecified;
}(Error));
exports.LabelOrgNotSpecified = LabelOrgNotSpecified;
var get = function (db, params) { return __awaiter(void 0, void 0, void 0, function () {
    var docRef, docsSnapshot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!params.org.id) {
                    throw new LabelOrgNotSpecified();
                }
                docRef = (0, firestore_1.doc)(getScanEventsCollection(db, params.org.id, params.label.id), params.id);
                return [4 /*yield*/, (0, firestore_1.getDoc)(docRef)];
            case 1:
                docsSnapshot = _a.sent();
                if (!docsSnapshot.exists()) {
                    throw new ScanEventNotFoundError(params.org.id, params.label.id, params.id);
                }
                return [2 /*return*/, docsSnapshot.data()];
        }
    });
}); };
exports.get = get;
var getList = function (db, params) { return __awaiter(void 0, void 0, void 0, function () {
    var queryConstraints, collectionRef, docsSnapshot;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!params.org.id) {
                    throw new LabelOrgNotSpecified();
                }
                queryConstraints = [];
                collectionRef = getScanEventsCollection(db, params.org.id, params.label.id);
                if (params.sort) {
                    queryConstraints.push((0, firestore_1.orderBy)((_a = params.sort) === null || _a === void 0 ? void 0 : _a.field, (_b = params.sort) === null || _b === void 0 ? void 0 : _b.order));
                }
                return [4 /*yield*/, (0, firestore_1.getDocs)(firestore_1.query.apply(void 0, __spreadArray([collectionRef], queryConstraints, false)))];
            case 1:
                docsSnapshot = _c.sent();
                if (docsSnapshot.empty) {
                    throw new ScanEventsNotFoundError(params.org.id, params.label.id);
                }
                return [2 /*return*/, docsSnapshot.docs.map(function (d) { return d.data(); })];
        }
    });
}); };
exports.getList = getList;
