"use strict";
exports.__esModule = true;
exports.SupportRequestType = exports.Country = void 0;
var Country;
(function (Country) {
    Country["CA"] = "Canada";
    Country["US"] = "United States";
})(Country = exports.Country || (exports.Country = {}));
var SupportRequestType;
(function (SupportRequestType) {
    SupportRequestType["LABEL_SUPPORT"] = "LABEL_SUPPORT";
    SupportRequestType["LABEL_CLAIM"] = "LABEL_CLAIM";
    SupportRequestType["INVOICE_SUPPORT"] = "INVOICE_SUPPORT";
    SupportRequestType["INVOICE_CLAIM"] = "INVOICE_CLAIM";
})(SupportRequestType = exports.SupportRequestType || (exports.SupportRequestType = {}));
