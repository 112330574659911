import { styled } from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import AppViewLayout from "~/layouts/app/AppViewLayout";

const FinacialSummary = () => {
  const { merchant } = useAuthenticatedContext();

  return (
    <AppViewLayout
      title="pages.financial_summary.title"
      context="pages.financial_summary.disclaimer"
    >
      {merchant?.id && (
        <FinacialSummaryIframe
          src={`https://datastudio.google.com/embed/u/1/reporting/e1ae06a9-5163-4c84-97c5-98e4014a602c/page/p_stqfnutfsc?params=%7B%22df34%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${merchant.id}%22%7D`}
          frameBorder="0"
          allowFullScreen
          id={`financial-summary-${merchant.name}-embed`}
        />
      )}
    </AppViewLayout>
  );
};

export default FinacialSummary;

const FinacialSummaryIframe = styled("iframe")(({ theme }) => ({
  height: "900px",
  width: "100%",
  margin: "0 auto",
  maxWidth: theme.breakpoints.values.xl,
  alignSelf: "center",
}));
