import { CustomError, ErrorType } from "~/layouts/app";

const UnhandledError = () => {
  const error = {
    type: ErrorType.Generic,
    title: "shared.content.errors.generic_title",
    desc: "shared.content.errors.unhandled_request_desc",
  };

  return <CustomError error={error} />;
};

export default UnhandledError;
