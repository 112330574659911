"use strict";
exports.__esModule = true;
exports.UserType = exports.UserStatus = exports.UserRole = void 0;
var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "admin";
    UserRole["MEMBER"] = "member";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["ACTIVE"] = "ACTIVE";
    UserStatus["INACTIVE"] = "INACTIVE";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
// REVIEW: legacy. To be deprecated.
var UserType;
(function (UserType) {
    UserType["MERCHANT"] = "MERCHANT";
    UserType["MERCHANT_GROUP"] = "MERCHANT_GROUP";
    UserType["WAREHOUSE"] = "WAREHOUSE";
    UserType["GATSBY"] = "GATSBY";
    UserType["INTERNAL"] = "INTERNAL";
    UserType["DRIVER"] = "DRIVER";
    UserType["DRIVER_UNMANAGED"] = "DRIVER_UNMANAGED";
    UserType["WAREHOUSE_UNMANAGED"] = "WAREHOUSE_UNMANAGED";
})(UserType = exports.UserType || (exports.UserType = {}));
