"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.getList = exports.get = exports.MerchantNotFoundError = exports.IntegrationIdNotFoundError = exports.IntegrationNotFoundError = void 0;
/* eslint-disable max-classes-per-file */
var firestore_1 = require("firebase/firestore");
var integrationsConverter = {
    fromFirestore: function (snapshot) {
        return snapshot.data();
    },
    toFirestore: function (model) {
        return model;
    }
};
var getIntegrationsCollection = function (db) {
    return (0, firestore_1.collection)(db, "integrations").withConverter(integrationsConverter);
};
var IntegrationNotFoundError = /** @class */ (function (_super) {
    __extends(IntegrationNotFoundError, _super);
    function IntegrationNotFoundError(type) {
        return _super.call(this, "No integrations found for type ".concat(type)) || this;
    }
    return IntegrationNotFoundError;
}(Error));
exports.IntegrationNotFoundError = IntegrationNotFoundError;
var IntegrationIdNotFoundError = /** @class */ (function (_super) {
    __extends(IntegrationIdNotFoundError, _super);
    function IntegrationIdNotFoundError(id) {
        return _super.call(this, "No integrations found for id ".concat(id)) || this;
    }
    return IntegrationIdNotFoundError;
}(Error));
exports.IntegrationIdNotFoundError = IntegrationIdNotFoundError;
var MerchantNotFoundError = /** @class */ (function (_super) {
    __extends(MerchantNotFoundError, _super);
    function MerchantNotFoundError() {
        return _super.call(this, "No merchant id has been given") || this;
    }
    return MerchantNotFoundError;
}(Error));
exports.MerchantNotFoundError = MerchantNotFoundError;
var get = function (db, params) { return __awaiter(void 0, void 0, void 0, function () {
    var queryConstraints, queryWithConstraints, querySnapshot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                queryConstraints = [];
                queryConstraints.push((0, firestore_1.where)("id", "==", params.id), (0, firestore_1.where)("active", "==", true));
                queryWithConstraints = firestore_1.query.apply(void 0, __spreadArray([getIntegrationsCollection(db)], queryConstraints, false));
                return [4 /*yield*/, (0, firestore_1.getDocs)(queryWithConstraints)];
            case 1:
                querySnapshot = _a.sent();
                if (querySnapshot) {
                    return [2 /*return*/, querySnapshot.docs[0].data()];
                }
                throw new IntegrationIdNotFoundError(params.id);
        }
    });
}); };
exports.get = get;
var getList = function (db, params) { return __awaiter(void 0, void 0, void 0, function () {
    var merchantId, type, queryConstraints, queryWithConstraints, integrationsDocsSnapshot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                merchantId = params.filter.org.id;
                type = params.filter.integrationType;
                if (!merchantId) {
                    throw new MerchantNotFoundError();
                }
                queryConstraints = [];
                if (merchantId) {
                    queryConstraints.push((0, firestore_1.where)("type", "==", type), (0, firestore_1.where)("active", "==", true));
                }
                queryWithConstraints = firestore_1.query.apply(void 0, __spreadArray([getIntegrationsCollection(db)], queryConstraints, false));
                return [4 /*yield*/, (0, firestore_1.getDocs)(queryWithConstraints)];
            case 1:
                integrationsDocsSnapshot = _a.sent();
                if (integrationsDocsSnapshot.size === 0) {
                    throw new IntegrationNotFoundError(type);
                }
                else {
                    return [2 /*return*/, integrationsDocsSnapshot.docs.map(function (querySnapshot) {
                            return querySnapshot.data();
                        })];
                }
                return [2 /*return*/];
        }
    });
}); };
exports.getList = getList;
