"use strict";
exports.__esModule = true;
exports.omit = void 0;
/**
 * Create a new object with certain specified keys eliminated from the source object
 * Only works with source object one-level deep. (like an enum)
 * @param obj the source object to omit from
 * @param keys the keys in the source object to eliminate
 * @returns a new object from the source object with the specified keys eliminated
 */
// eslint-disable-next-line import/prefer-default-export
var omit = function (obj, keys) {
    return Object.fromEntries(Object.entries(obj).filter(function (_a) {
        var key = _a[0];
        return !keys.includes(key);
    }));
};
exports.omit = omit;
