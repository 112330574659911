export enum Routes {
  Root = "/",
  Dashboard = "/",
  Onboarding = "/onboarding",
  Register = "/register",
  ResetPassword = "/passwords/reset",
  ForgotPassword = "/passwords/forgot",
  VerifyEmail = "/verify-email",
  UnverifiedEmail = "/unverified-email",
  Users = "/users",
  Login = "/login",
  Rates = "/rates",
  Locations = "/locations",
  Shipments = "/shipments",
  Zones = "/zones",
  Reports = "/reports", //TODO: remove once reports have been shipped to production and feature flag has been deactivated
  Api = "/api",
  Stores = "/stores",
  Tms = "/tms",
  Carriers = "/carriers",
  Invoices = "/invoices",
  Performance = "/reports/performance",
  FailureBreakdown = "/reports/failure-breakdown",
  DensityMap = "/reports/density-map",
  FinancialSummary = "/reports/financial-summary",
}
