import { useRef, useEffect } from "react";
import { render } from "react-dom";
// @ts-ignore
// eslint-disable-next-line
import mapboxgl, { Map } from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { LngLatLike, Marker } from "mapbox-gl";
import {
  styled,
  Card,
  PlaceTwoTone,
  FiberManualRecordTwoToneIcon,
  SxProps,
  defaultTheme,
  Alert,
} from "@helo/ui";
import { Coordinate } from "@swyft/types";
import distance from "@turf/distance";
import midpoint from "@turf/midpoint";
import bearing from "@turf/bearing";
import rhumbDestination from "@turf/rhumb-destination";
import lineArc from "@turf/line-arc";
import rhumbBearing from "@turf/rhumb-bearing";
import rhumbDistance from "@turf/rhumb-distance";
import { Feature, LineString } from "@turf/helpers";
import { useTranslate } from "react-admin";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

//consts
const initialLng = -98.5795;
const initialLat = 39.828175;
const initialZoom = 3.4;

interface LabelMapProps {
  origin: Coordinate | undefined;
  destination: Coordinate | undefined;
  sx?: SxProps;
}

const LabelMap = ({ origin, destination, sx }: LabelMapProps) => {
  //Mapbox setup
  const mapContainer = useRef<any>();
  const map = useRef<Map>();
  const destinationMarker = useRef<Marker>();
  const originMarker = useRef<Marker>();
  const translate = useTranslate();

  useEffect(() => {
    if (origin?.latitude && destination?.latitude && mapContainer.current) {
      // initialize map only once
      if (!map.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/light-v10",
          center: [initialLng, initialLat],
          zoom: initialZoom,
          bounds: [
            [origin.longitude, origin.latitude],
            [destination.longitude, destination.latitude],
          ],
          fitBoundsOptions: {
            padding: 100,
          },
          scrollZoom: false,
        });

        //add controls
        map.current.addControl(
          new mapboxgl.NavigationControl({
            showZoom: true,
            showCompass: false,
          }),
          "top-right",
        );
      }

      // A simple line from origin to destination.
      const route: Feature<LineString> = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [
            [origin.longitude, origin.latitude],
            [destination.longitude, destination.latitude],
          ],
        },
      };

      // rhumb functions to create arc building blocks
      const radius = rhumbDistance(
        [origin.longitude, origin.latitude],
        [destination.longitude, destination.latitude],
      );
      const lineMidpoint = midpoint(
        [origin.longitude, origin.latitude],
        [destination.longitude, destination.latitude],
      );
      const lineBearing =
        rhumbBearing(
          [origin.longitude, origin.latitude],
          [destination.longitude, destination.latitude],
        ) - 89;
      const lineOrigin = rhumbDestination(lineMidpoint, radius, lineBearing);

      // Arc creation
      const arc = lineArc(
        lineOrigin,
        distance(lineOrigin, [origin.longitude, origin.latitude]),
        bearing(lineOrigin, [destination.longitude, destination.latitude]),
        bearing(lineOrigin, [origin.longitude, origin.latitude]),
        { steps: 200 },
      );

      // Update the route with calculated arc coordinates
      route.geometry.coordinates = arc.geometry.coordinates;

      map.current.on("load", () => {
        map.current.addSource("route", {
          type: "geojson",
          lineMetrics: true,
          data: route,
        });

        map.current.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": `${defaultTheme.palette.primary.dark}`,
            "line-width": 2,
            "line-offset": -4,
            "line-dasharray": [1, 2],
            "line-opacity": 0.8,
          },
        });
      });

      if (!destinationMarker.current) {
        //destination marker
        const destinationLatLong = arc.geometry.coordinates[0] as LngLatLike;
        //custom map pin
        const markerDestnationNode = document.createElement("div");
        const markerDestinationIcon = (
          <PlaceTwoTone
            color="primary"
            fontSize="large"
            className="label-map-destination-icon"
          />
        );
        render(markerDestinationIcon, markerDestnationNode);

        destinationMarker.current = new Marker(markerDestnationNode)
          .setLngLat(destinationLatLong)
          .addTo(map.current);
      }

      if (!originMarker.current) {
        //origin marker
        const originLatLong = arc.geometry.coordinates[
          arc.geometry.coordinates.length - 1
        ] as LngLatLike;
        const markerOriginNode = document.createElement("div");
        const markerOriginIcon = (
          <FiberManualRecordTwoToneIcon
            color="primary"
            fontSize="large"
            className="label-map-origin-icon"
          />
        );
        render(markerOriginIcon, markerOriginNode);

        originMarker.current = new Marker(markerOriginNode)
          .setLngLat(originLatLong)
          .addTo(map.current);
      }
    }
  }, [origin]);

  return origin?.latitude && destination?.latitude ? (
    <Card sx={sx}>
      <MapContainer
        ref={mapContainer}
        sx={{
          "& .label-map-destination-icon path:first-of-type, .label-map-origin-icon path:first-of-type":
            {
              fillOpacity: 1,
              opacity: 1,
              fill: defaultTheme.palette.primaryScale[800],
            },
        }}
      />
    </Card>
  ) : (
    <Alert sx={{ mb: 2 }} severity="warning">
      {translate("shipments.content.details.map.fail")}
    </Alert>
  );
};

export default LabelMap;

//styled containers
const MapContainer = styled("div")({
  height: "500px",
  width: "100%",
});
