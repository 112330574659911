"use strict";
exports.__esModule = true;
exports.ApiMode = exports.DATE_FORMAT = exports.StorageFileLocationType = exports.ValidationEngine = exports.ISmartyStreetsPrecision = exports.US_STATES = exports.CA_PROVINCES = exports.LANGUAGE = exports.Currency = exports.CountryISO2 = void 0;
var CountryISO2;
(function (CountryISO2) {
    CountryISO2["USA"] = "US";
    CountryISO2["CANADA"] = "CA";
})(CountryISO2 = exports.CountryISO2 || (exports.CountryISO2 = {}));
var Currency;
(function (Currency) {
    Currency["CAD"] = "CAD";
    Currency["USD"] = "USD";
})(Currency = exports.Currency || (exports.Currency = {}));
var LANGUAGE;
(function (LANGUAGE) {
    LANGUAGE["ENGLISH"] = "ENG";
    LANGUAGE["FRENCH"] = "FRE";
})(LANGUAGE = exports.LANGUAGE || (exports.LANGUAGE = {}));
exports.CA_PROVINCES = [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NT",
    "NS",
    "NU",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT",
];
exports.US_STATES = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
];
/**
 * Smarty Streets precision options
 * Metric from SS to estimate the accuracy of either the geocode lookup or the address validation.
 * These are ordered by accuracy, for example, "Manual" and "DeliveryPoint" are the most accurate and "None" is least accurate.
 * See https://smartystreets.com/docs/cloud/international-street-api for more details.
 * Option "Manual" is for manual geocodes from the dashboard. These are assumed to be highest accuracy because
 * we expect ops to fill in the correct geocode here.
 */
var ISmartyStreetsPrecision;
(function (ISmartyStreetsPrecision) {
    ISmartyStreetsPrecision["MANUAL"] = "Manual";
    ISmartyStreetsPrecision["DELIVERY_POINT"] = "DeliveryPoint";
    ISmartyStreetsPrecision["PREMISE"] = "Premise";
    ISmartyStreetsPrecision["THOROUGHFARE"] = "Thoroughfare";
    ISmartyStreetsPrecision["LOCALITY"] = "Locality";
    ISmartyStreetsPrecision["ADMINISTRATIVE_AREA"] = "AdministrativeArea";
    ISmartyStreetsPrecision["NONE"] = "None";
})(ISmartyStreetsPrecision = exports.ISmartyStreetsPrecision || (exports.ISmartyStreetsPrecision = {}));
/**
 * We have multiple validation engine options. This enum is collection of them.
 */
var ValidationEngine;
(function (ValidationEngine) {
    ValidationEngine["SMARTY"] = "SMARTY";
    ValidationEngine["GOOGLE"] = "GOOGLE";
})(ValidationEngine = exports.ValidationEngine || (exports.ValidationEngine = {}));
var StorageFileLocationType;
(function (StorageFileLocationType) {
    StorageFileLocationType["URL"] = "URL";
    StorageFileLocationType["FIREBASE_STORAGE"] = "FIREBASE_STORAGE";
})(StorageFileLocationType = exports.StorageFileLocationType || (exports.StorageFileLocationType = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
var DATE_FORMAT;
(function (DATE_FORMAT) {
    /** Formatted date example: 2020-12-31 */
    DATE_FORMAT["SHIP_DATE"] = "YYYY-MM-DD";
    /** Formatted date example: Apr 21, 2021 */
    DATE_FORMAT["READABLE_SHIP_DATE"] = "MMM DD, YYYY";
    /** Formatted date example: 09:30 AM */
    DATE_FORMAT["CUT_OFF_TIME"] = "hh:mm A";
    /** Formatted date example: April 21st 2021 */
    DATE_FORMAT["LABEL_PDF"] = "MMMM Do YYYY";
    /** Formatted date example: 9:30 AM, 22 Apr 2021 */
    DATE_FORMAT["READABLE_TIMESTAMP"] = "h:mm A, DD MMM YYYY";
    /** Formatted date example: 09:30 AM */
    DATE_FORMAT["ROUTING_ENGINE"] = "hh:mm A";
    /** Date and time with timezone  */
    DATE_FORMAT["TIMESTAMP_TZ"] = "yyyy-MM-dd'T'HH:mm:ssxxx";
})(DATE_FORMAT = exports.DATE_FORMAT || (exports.DATE_FORMAT = {}));
var ApiMode;
(function (ApiMode) {
    ApiMode["TEST"] = "test";
    ApiMode["LIVE"] = "live";
})(ApiMode = exports.ApiMode || (exports.ApiMode = {}));
