"use strict";
exports.__esModule = true;
exports.getPaginationSlice = exports.DEFAULT_PAGINATION_CONFIG = exports.DEFAULT_PAGE = exports.DEFAULT_PER_PAGE = void 0;
/* eslint-disable import/prefer-default-export */
exports.DEFAULT_PER_PAGE = 25; // number of results per page
exports.DEFAULT_PAGE = 1; // the page
exports.DEFAULT_PAGINATION_CONFIG = {
    page: exports.DEFAULT_PAGE,
    perPage: exports.DEFAULT_PER_PAGE
};
/**
 * Get the start index and the end index to slice a list of items, given a page number (index-1 based), and the number of items per page.
 * @param pagination the pagination payload from a inbound query to a controller
 * @returns the start index to slice at (inclusive) and the end index to end the slicing at (exclusive)
 */
var getPaginationSlice = function (pagination) {
    var actualPagination = Object.assign(exports.DEFAULT_PAGINATION_CONFIG, pagination !== null && pagination !== void 0 ? pagination : {});
    var offset = (actualPagination.page - 1) * actualPagination.perPage;
    return {
        startIndex: offset,
        endIndex: offset + actualPagination.perPage
    };
};
exports.getPaginationSlice = getPaginationSlice;
