import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@helo/ui";
import { ServiceType, RateCardV2 } from "@swyft/types";
import { useLocaleState, useTranslate } from "react-admin";
import { currencyFormatter } from "~/common/helpers";

interface RateCardV1ServiceLevelsProps {
  title: string | undefined;
  rateCard: RateCardV2;
  serviceType: ServiceType;
}

const RateCardV2ServiceLevels = ({
  title,
  rateCard,
  serviceType,
}: RateCardV1ServiceLevelsProps) => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const ratePlaceholder = "-";

  const getRates = () => {
    return rateCard.entries
      ?.filter((item) => item.rate > 0 && item.serviceType === serviceType)
      .sort((a, b) => a.minWeight - b.minWeight)
      .map((entry) => ({
        rate: currencyFormatter(locale, entry.rate, rateCard.currency),
        minWeight: entry.minWeight,
        maxWeight: entry.maxWeight,
        redeliveryRate: entry.redeliveryRate
          ? currencyFormatter(locale, entry.rate, rateCard.currency)
          : ratePlaceholder,
      }));
  };
  const rates = getRates() || [];
  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        {translate(`rates.service_level.title.${title}`)}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate("rates.service_level.table.head.minWeight")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.maxWeight")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.rate")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.redeliveryRate")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rates.map((rate) => (
              <TableRow>
                <TableCell>{rate.minWeight}</TableCell>
                <TableCell>{rate.maxWeight}</TableCell>
                <TableCell>{rate.rate}</TableCell>
                <TableCell>{rate.redeliveryRate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RateCardV2ServiceLevels;
