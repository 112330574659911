import { styled } from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import AppViewLayout from "~/layouts/app/AppViewLayout";

const FailureBreakdown = () => {
  const { merchant } = useAuthenticatedContext();

  return (
    <AppViewLayout title="pages.failure_breakdown.title">
      {merchant?.id && (
        <FailureBreakdownIframe
          src={`https://datastudio.google.com/embed/u/1/reporting/62bd32b0-17c8-452f-a117-ececb62be34e/page/p_6n1kexlizc?params={"df17":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${merchant.id}"}`}
          frameBorder="0"
          allowFullScreen
          id={`failure-breakdown-${merchant.name}-embed`}
        />
      )}
    </AppViewLayout>
  );
};

export default FailureBreakdown;

const FailureBreakdownIframe = styled("iframe")(({ theme }) => ({
  height: "900px",
  width: "100%",
  margin: "0 auto",
  maxWidth: theme.breakpoints.values.xl,
  alignSelf: "center",
}));
