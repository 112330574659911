import { useEditContext, useNotify, useTranslate } from "react-admin";
import { useState } from "react";
import { useLabelDataProvider } from "~/services/data/shipment";
import { exportPDF } from "~/common/fileExportHelper";

import {
  LoadingButton,
  PrintIcon,
  Box,
  Typography,
  Button,
  HelpIcon,
} from "@helo/ui";
import LabelSupport from "./LabelSupport";

import { COMPANY_NAME, DATE_FORMATS } from "~/common/consts";
import moment from "moment";
import { AppResource } from "~/config/resources";

const LabelActions = () => {
  const { record } = useEditContext();
  const [, dataProvider] = useLabelDataProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const [isPrintingLabels, setIsPrintingLabels] = useState<boolean>(false);
  const [isLabelSupportOpen, setIsLabelSupportOpen] = useState(false);

  const printLabel = async () => {
    setIsPrintingLabels(true);
    const {
      destination: { firstName, lastName },
      shipDate,
      id,
    } = record;
    const formattedDate = moment(shipDate).format(DATE_FORMATS.moment.export);
    const fileName = `${COMPANY_NAME.toLowerCase()}-label--${formattedDate}-${firstName}-${lastName}.pdf`;
    try {
      const { data } = await dataProvider.getPrintPdfs(AppResource.Shipment, {
        labelIds: [id],
      });
      await exportPDF(data, fileName);
      notify("shipments.content.details.actions.message.print_success", {
        type: "success",
        messageArgs: {
          name: translate("resources.labels.name", {
            smart_count: 1,
          }),
          smart_count: 1,
        },
      });
    } catch (err) {
      notify("shipments.content.details.actions.message.print_failure", {
        type: "error",
        messageArgs: {
          name: translate("resources.labels.name", {
            smart_count: 1,
          }),
          error: err,
          smart_count: 1,
        },
      });
    }
    setIsPrintingLabels(false);
  };
  return (
    <Box sx={{ ml: "auto" }}>
      <LoadingButton
        variant="contained"
        onClick={printLabel}
        loading={isPrintingLabels}
      >
        <PrintIcon
          sx={({ breakpoints }) => ({
            mr: 0,
            [breakpoints.up("lg")]: {
              mr: 1,
            },
          })}
        ></PrintIcon>
        <Typography
          variant="inherit"
          sx={({ breakpoints }) => ({
            display: "none",
            [breakpoints.up("lg")]: {
              display: "inline-block",
            },
          })}
        >
          {translate("shipments.content.details.actions.print")}
        </Typography>
      </LoadingButton>
      <Button
        variant="contained"
        onClick={() => {
          setIsLabelSupportOpen(true);
        }}
        sx={{ ml: 2 }}
      >
        <HelpIcon
          sx={({ breakpoints }) => ({
            mr: 0,
            [breakpoints.up("lg")]: {
              mr: 1,
            },
          })}
        ></HelpIcon>
        <Typography
          variant="inherit"
          sx={({ breakpoints }) => ({
            display: "none",
            [breakpoints.up("lg")]: {
              display: "inline-block",
            },
          })}
        >
          {translate("menu.help")}
        </Typography>
      </Button>
      <LabelSupport
        isOpen={isLabelSupportOpen}
        setIsOpen={setIsLabelSupportOpen}
      />
    </Box>
  );
};

export default LabelActions;
