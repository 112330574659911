import { useTranslate, useNotify } from "react-admin";
import { useForm, SubmitHandler } from "react-hook-form";
import { ContactSalesParams } from "@swyft/domain";
import {
  Dialog,
  Typography,
  IconButton,
  CloseIcon,
  TextField,
  LoadingButton,
  Grid,
  styled,
} from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useGlobalComponentContext } from "~/components/GlobalComponentContext";
import { useNotifyDialog } from "~/components/feedback/GlobalAlerts";
import { useMerchantDataProvider } from "~/services/data/merchant";

interface ContactSalesFormValues {
  message: string;
}

const ContactSalesDialog = () => {
  const [resource, dataProvider] = useMerchantDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const { merchant } = useAuthenticatedContext();
  const merchantId = merchant?.id;
  const notifyDialog = useNotifyDialog();
  const { isContactSalesOpen, setIsContactSalesOpen } =
    useGlobalComponentContext();
  const { register, handleSubmit, formState, reset } =
    useForm<ContactSalesFormValues>();

  //resets and closes form
  const onClose = () => {
    reset();
    setIsContactSalesOpen(false);
  };

  //Send message to sales
  const onSubmit: SubmitHandler<ContactSalesFormValues> = async (
    formvalues,
  ) => {
    if (merchantId) {
      const params: ContactSalesParams = {
        merchantId: merchantId,
        message: formvalues.message,
      };
      //on successful close and reset form and trigger success modal
      try {
        await dataProvider.contactSales(resource, params);
        onClose();
        notifyDialog({
          title: translate("shared.message.contact.success"),
          content: translate("shared.message.contact.description"),
          isOpen: true,
        });
      } catch (err: unknown) {
        notify(
          translate(`${translate("shared.message.submit.fail")} (${err})`),
          {
            type: "warning",
          },
        );
      }
    }
  };

  return (
    <Dialog onClose={onClose} open={isContactSalesOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2} sx={{ p: 2 }} justifyContent="flex-end">
          <CloseIconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </CloseIconButton>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 900, textTransform: "capitalize" }}
            >
              {translate("shared.action.contact", {
                name: translate("shared.teams.sales"),
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={translate("shared.label.message")}
              placeholder={translate("shared.form.placeholder.message")}
              {...register("message")}
              multiline
              rows={6}
              fullWidth
              required
            />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              size="medium"
              variant="contained"
              disableElevation
              loading={formState?.isSubmitting}
            >
              {translate("shared.action.send")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: 16,
  height: 24,
  width: 24,
  position: "absolute",
  right: 16,
  top: 16,
  backgroundColor: theme.palette.neutralScale[500],
  color: theme.palette.primary.contrastText,
  ":hover": { color: theme.palette.neutralScale[400] },
}));

export default ContactSalesDialog;
