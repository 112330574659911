import { NumericFormat } from "react-number-format";
import { TextField, InputAdornment } from "@helo/ui";
import { Control, useController } from "react-hook-form";
import { useRef } from "react";

export enum ValueType {
  formattedValue = "formattedValue",
  floatValue = "floatValue",
}

interface CurrencyInputProps {
  label: string;
  prefix?: string;
  placeholder?: string;
  control: Control;
  name: string;
  valueType: ValueType;
}

/**
 * Customizable currency input field
 * @param {string}  label - label for input field
 * @param {string} prefix - an optional param that sets the currency symbol of your choice as a prefix
 * @param {string} placeholder - an optional placeholder that defaults to 0.00
 * @param {Control} control - connects input to the context of the react-hook form it lives in
 * @param {string} name - string name of field corresponding to react-hook-form formvalues
 * @param {ValueType} valueType - an enum that determines the value type of the input
 * @returns a TextField with formatted currency
 */
const CurrencyInput = ({
  label,
  prefix,
  placeholder,
  control,
  name,
  valueType,
}: CurrencyInputProps) => {
  const { field } = useController({ name, control });
  const { onChange, ...rest } = field;
  const ref = useRef();

  return (
    <NumericFormat
      {...rest}
      fullWidth
      label={label}
      allowNegative={false}
      thousandSeparator=","
      decimalScale={2}
      placeholder={placeholder || "0.00"}
      required
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{prefix || ""}</InputAdornment>
        ),
      }}
      customInput={TextField}
      onValueChange={(value) => {
        onChange(value[valueType]);
      }}
      getInputRef={ref}
    />
  );
};

export default CurrencyInput;
