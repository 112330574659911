import { themeOptions } from "@helo/ui/theme";
import { defaultTheme } from "react-admin";

const theme = {
  ...defaultTheme,
  ...themeOptions,
  sidebar: {
    width: 256,
    closedWidth: 64,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          lineHeight: "140%",
          letterSpacing: "0px",
          fontSize: "13px",
          fontFamily: '"Sofia Pro", sans-serif',
        },
      },
    },
  },
};

export default theme;
