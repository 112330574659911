import {
  Box,
  Button,
  Card,
  DollarIcon,
  FiberManualRecordTwoToneIcon,
  Grid,
  InventoryIcon,
  Link,
  LocalShippingIcon,
  PlaceTwoTone,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@helo/ui";
import { IfCanAccess } from "@react-admin/ra-rbac";
import {
  CostBreakdownLineItem,
  CostBreakdownSource,
  CostBreakdownType,
} from "@swyft/domain";
import { Currency as CyrrencyEnum, Label } from "@swyft/types";
import { ReactNode, useState } from "react";
import {
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate,
} from "react-admin";

import { getTrackingPageUrl } from "~/common/consts";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import Address, { EditAddressForm } from "~/components/data/Address";
import Currency from "~/components/data/Currency";
import Dimensions from "~/components/data/Dimensions";
import Weight from "~/components/data/Weight";
import { useDomainContext } from "~/components/DomainContext";
import { AppResource } from "~/config/resources";
import ClaimDialog from "./ClaimDialog";

const LabelShipmentDetails = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const [claimProps, setClaimProps] = useState<{
    isOpen: boolean;
    currency: CyrrencyEnum | "";
  }>({ isOpen: false, currency: "" });
  const record = useRecordContext<Label>();
  const { merchant } = useAuthenticatedContext();
  const referenceNums = [record.reference, record.reference2].filter(
    (num) => !!num,
  );
  const { labelController } = useDomainContext();
  const {
    breakdown: costBreakdown,
    total: costTotal,
    currency: costCurrency,
  } = labelController.getCostBreakdown({
    merchant,
    label: record,
    includeActuals: true,
  });
  const [update] = useUpdate<Label>(AppResource.Shipment);
  const handleDestinationEdit = async (formValues: EditAddressForm) => {
    try {
      await update(
        AppResource.Shipment,
        {
          id: record.id,
          data: {
            destination: {
              ...record.destination,
              address: {
                ...record.destination.address,
                line2: formValues.line2,
              },
              phone: formValues.phone ?? "",
            },
            notes: formValues.notes,
          },
          previousData: record,
          meta: {
            org: {
              id: record.merchantId,
            },
          },
        },
        {
          mutationMode: "optimistic",
        },
      );

      notify("shared.message.update.ok", {
        type: "success",
        messageArgs: {
          name: translate("shared.label.address.all"),
          smart_count: 1,
        },
      });
    } catch (err) {
      notify("shared.message.update.fail", {
        type: "error",
        messageArgs: {
          name: translate("shared.label.address.all"),
          smart_count: 1,
        },
      });
      console.error(err);
    }
  };

  return (
    <Card sx={{ mb: 3 }}>
      <Box>
        <Grid container>
          <CategoryCard item xs={12} xl>
            <CategoryTitle
              title={translate("shipments.content.details.logistics.title")}
              icon={<LocalShippingIcon sx={{ mb: "-3px" }} />}
            />
            <Grid container spacing={2}>
              <Grid item xs>
                <DetailItem
                  title={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FiberManualRecordTwoToneIcon
                        sx={({ palette }) => ({
                          mr: 0.5,
                          color: palette.primary.dark,
                        })}
                      />
                      <Typography variant="inherit">
                        {translate("resources.shipments.fields.origin")}
                      </Typography>
                    </Box>
                  }
                >
                  <Address data={record.origin} />
                </DetailItem>
              </Grid>
              <Grid item xs>
                <DetailItem
                  title={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PlaceTwoTone
                        sx={({ palette }) => ({
                          mr: 0.5,
                          color: palette.primary.dark,
                        })}
                      />
                      <Typography variant="inherit">
                        {translate("resources.shipments.fields.destination")}
                      </Typography>
                    </Box>
                  }
                >
                  <IfCanAccess action="edit">
                    <Address
                      data={record.destination}
                      showContact
                      enableEdit
                      editDefaultValues={{
                        notes: record.notes,
                      }}
                      onEditSubmit={handleDestinationEdit}
                      editDescription={translate(
                        "shipments.content.details.logistics.address.edit_dialog.desc",
                      )}
                    />
                  </IfCanAccess>
                </DetailItem>
                <DetailItem
                  title="resources.shipments.fields.notes"
                  sx={{ mt: 1 }}
                >
                  {record.notes || translate("shared.content.not_available")}
                </DetailItem>
              </Grid>
            </Grid>
          </CategoryCard>
          <CategoryCard item xs={12} xl>
            <CategoryTitle
              title={translate("shipments.content.details.package.title")}
              icon={<InventoryIcon />}
            />
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} md>
                <DetailItem title="resources.shipments.fields.trackingNumber">
                  <Link
                    href={getTrackingPageUrl(record.trackingNumber)}
                    target="_blank"
                  >
                    {record.trackingNumber}
                  </Link>
                </DetailItem>
              </Grid>
              <Grid item xs={12} md>
                <DetailItem title="resources.shipments.fields.orderNumber">
                  {record.orderNumber ||
                    translate("shared.content.not_available")}
                </DetailItem>
              </Grid>
              <Grid item xs={12} md>
                <DetailItem title="resources.shipments.fields.referenceNumbers">
                  {referenceNums.join(", ") ||
                    translate("shared.content.not_available")}
                </DetailItem>
              </Grid>
            </Grid>
            {/* NOTE: temporarily hidden */}
            <DetailItem
              title="resources.shipments.fields.carrier"
              sx={{ mb: 2, display: "none" }}
            >
              {record.dspId
                ? "Swyft"
                : translate("shared.content.not_available")}
            </DetailItem>
            <DetailItem
              title="resources.shipments.fields.serviceType"
              sx={{ mb: 2 }}
            >
              {record.serviceType
                ? translate(
                    `shipments.content.details.serviceType.${record.serviceType}`,
                  )
                : translate("shared.content.not_available")}
            </DetailItem>
            {/* NOTE: temporarily hidden */}
            <DetailItem
              title="resources.shipments.fields.packageType"
              sx={{ mb: 2, display: "none" }}
            >
              {translate(
                `shipments.content.details.packageType.${record.packageType}`,
              ) || translate("shared.content.not_available")}
            </DetailItem>
            <DetailItem title="resources.shipments.fields.dims" sx={{ mb: 2 }}>
              <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <Dimensions data={record.dims} />
                ,&nbsp;
                <Weight data={record.dims} />
              </Box>
            </DetailItem>
          </CategoryCard>
          <CategoryCard item xs={12} xl sx={{ borderRight: "none" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <CategoryTitle
                title={translate("shipments.content.details.cost.title")}
                icon={<DollarIcon sx={{ mb: "-1px" }} />}
              />
              {merchant && costCurrency && record.received && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setClaimProps({ isOpen: true, currency: costCurrency });
                  }}
                  color="secondary"
                >
                  <Typography
                    variant="inherit"
                    sx={({ breakpoints }) => ({
                      display: "none",
                      [breakpoints.up("lg")]: {
                        display: "inline-block",
                      },
                    })}
                  >
                    {translate("shipments.content.details.cost.claim")}
                  </Typography>
                </Button>
              )}
            </Box>
            <DetailItem title="shipments.content.details.cost.breakdown.label">
              {merchant && costBreakdown && (
                <CostBreakdown
                  breakdown={costBreakdown}
                  total={costTotal}
                  currency={costCurrency}
                />
              )}
            </DetailItem>
          </CategoryCard>
        </Grid>
      </Box>
      <ClaimDialog claimProps={claimProps} setClaimProps={setClaimProps} />
    </Card>
  );
};

const CategoryTitle = ({ title, icon, sx = [] }: CategoryTitleProps) => (
  <Box
    sx={[
      { display: "flex", alignItems: "center", mb: 3 },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {icon}
    <Typography variant="h5" component="h3" sx={{ ml: 1 }}>
      {title}
    </Typography>
  </Box>
);

const DetailItem = ({ children, title, subtitle, sx }: DetailItemProps) => {
  const translate = useTranslate();

  return (
    <Box sx={sx}>
      <Typography
        variant="subtitle1"
        sx={({ palette }) => ({ color: palette.neutralScale[500] })}
      >
        {typeof title === "string" ? translate(title) : title}
      </Typography>
      {subtitle && (
        <Typography
          variant="subtitle2"
          sx={({ palette }) => ({
            color: palette.neutralScale[600],
            fontStyle: "italic",
            mb: 0.5,
          })}
        >
          {typeof subtitle === "string" ? translate(subtitle) : subtitle}
        </Typography>
      )}
      {typeof children === "string" ? (
        <Typography variant="body1">{children}</Typography>
      ) : (
        children
      )}
    </Box>
  );
};

const CostBreakdown = ({ breakdown, total, currency }: CostBreakdownProps) => {
  const translate = useTranslate();
  const { merchant } = useAuthenticatedContext();
  const record = useRecordContext<Label>();
  const baseRateLineItem = breakdown.find(
    (b) => b.type === CostBreakdownType.BASE_RATE,
  );

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              {translate("shipments.content.details.cost.breakdown.item")}
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              {translate("shipments.content.details.cost.breakdown.amount")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {breakdown.map((b) => {
            let details = null;

            if (b.type === CostBreakdownType.ADDON_FEE) {
              details = translate(
                `shipments.content.details.cost.addon.${b.subType}`,
              );
            } else if (b.type === CostBreakdownType.REDELIVERY_FEE) {
              details =
                b.source === CostBreakdownSource.RATE_CARD_V1
                  ? translate(
                      `shipments.content.details.cost.redelivery.${b.source}`,
                      {
                        base_rate: baseRateLineItem?.amount,
                        flat_fee: merchant?.rateCard.redeliveryRate,
                        attempts: record.redeliveryAttempts,
                      },
                    )
                  : translate(
                      `shipments.content.details.cost.redelivery.${b.source}`,
                      {
                        base_rate: baseRateLineItem?.amount,
                        attempts: record.redeliveryAttempts,
                      },
                    );
            }

            return (
              <TableRow key={`${b.type}-${b.amount}-${b.subType ?? ""}`}>
                <TableCell component="th" scope="row">
                  {translate(`shipments.content.details.cost.${b.type}`)}{" "}
                  {details ? `(${details})` : null}
                </TableCell>
                <TableCell align="right">
                  <Currency amount={b.amount} currency={currency} />
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
              {translate("shipments.content.details.cost.breakdown.total")}*
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 600 }}>
              <Currency amount={total} currency={currency} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography
        variant="caption"
        component="div"
        sx={{ fontStyle: "italic", mt: 1 }}
      >
        *&nbsp;
        {translate("shipments.content.details.cost.breakdown.disclaimer")}
      </Typography>
    </>
  );
};

const CategoryCard = styled(Grid)(({ theme: { palette, breakpoints } }) => ({
  borderRight: "none",
  borderBottom: "1px solid",
  borderBottomColor: palette.neutralScale[700],
  padding: "24px",
  [breakpoints.up("lg")]: {
    borderRight: "1px solid",
    borderRightColor: palette.neutralScale[700],
  },
}));

interface CategoryTitleProps {
  title: string;
  icon: ReactNode;
  sx?: SxProps;
}

interface DetailItemProps {
  children: ReactNode;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  sx?: SxProps;
}

interface CostBreakdownProps {
  breakdown: CostBreakdownLineItem[];
  total: number;
  currency: CyrrencyEnum;
}

export default LabelShipmentDetails;
